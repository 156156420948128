<ng-container *ngIf="supported === null">
  <app-loading width="25" height="25" inverted="true"></app-loading>
</ng-container>
<ng-container *ngIf="supported === true">
  <iframe id="external-tracker-frame" width="100%" height="100%" [attr.src]="urlDisplay | safe"
    scrolling="no" marginheight="0" marginwidth="0" frameborder="0">
  </iframe>
</ng-container>
<ng-container *ngIf="supported === false">
  <app-third-party-cookies-instructions [url]="urlDisplay"></app-third-party-cookies-instructions>
</ng-container>
