import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, } from '@angular/core'
import { NgSelectComponent } from '@ng-select/ng-select'
import { EMPTY, Observable } from 'rxjs'
import { map } from 'rxjs/operators'

const OTHER_VALUE = () => $localize`Other`

export interface Selection {
  value: string,
  other: boolean,
}

@Component({
  selector: 'app-select-or-text',
  templateUrl: './select-or-text.component.html',
  styleUrls: ['./select-or-text.component.scss'],
})
export class SelectOrTextComponent {
  private itemsValue$: Observable<string[]> = EMPTY

  @ViewChild('otherInput') otherInput: ElementRef;
  @ViewChild('selectOrText', { static: false }) selectOrText: NgSelectComponent

  @Input()
  get items$(): Observable<string[]> {
    return this.itemsValue$
  }

  set items$(value) {
    this.itemsValue$ = value
    this.internalItems$ = this.items$.pipe(
      map((items) => [...items, OTHER_VALUE()])
    )
  }

  @Input() fieldName: string
  @Input() maxLength = 250
  @Input() dropdownPosition = 'auto'
  @Output() selectionMade: EventEmitter<Selection> = new EventEmitter<Selection>()
  @Input() keyupText: (event: any) => string
  typeLabel = () => $localize`Type`
  seeOtherLabel = () => $localize`See other`

  internalItems$: Observable<string[]> = EMPTY
  otherSelected = false
  otherValue = ''

  handleChange(value) {
    this.otherSelected = value === OTHER_VALUE()

    if (this.otherSelected) {
      setTimeout(() => {
        this.otherInput.nativeElement.focus()
      }, 300)
    }

    this.selectionMade.emit({
      value: this.otherSelected ? '' : value,
      other: this.otherSelected
    })
  }

  handleKeyUpText(event) {
    if (this.keyupText) {
      this.otherValue = this.keyupText(event)
      this.selectionMade.emit({
        value: this.otherValue.trim(),
        other: true,
      })
    }
  }
}
