import { Component, Input, ViewEncapsulation } from '@angular/core'
import { FormControlState } from 'ngrx-forms'

@Component({
  selector: 'app-form-helper',
  templateUrl: './form-helper.component.html',
  styleUrls: ['./form-helper.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormHelperComponent {

  @Input() type:  'required' | 'pattern' | 'minLength' | 'maxLength'
  @Input() name: string
  @Input() control: FormControlState<any>
  @Input() level: 'danger' | 'warning' = 'danger'

}
