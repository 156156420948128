import { createFeatureSelector, createSelector } from '@ngrx/store'
import { Passengers, SubmitState, UserContactInfo } from './submit.reducer'
import { Option } from '../ui/ui.types'
import { selectIsTowLocationValid } from '../location/tow-location/tow-location.selectors'
import { FormGroupState, unbox } from 'ngrx-forms'

const stateKey = 'submit'

export const selectSubmitState = createFeatureSelector<SubmitState>(stateKey)

export const selectContactForm = createSelector(
  selectSubmitState,
  (state: SubmitState): FormGroupState<UserContactInfo> => state.contactInfo
)

export const selectCommentSummary = createSelector(
  selectSubmitState,
  (state: SubmitState): any => state.contactInfo.controls.additionalDetails.value
)

export const selectUserContactInfo = createSelector(
  selectContactForm,
  (form: FormGroupState<UserContactInfo>): UserContactInfo => form.value
)

export const selectPassengersForm = createSelector(
  selectSubmitState,
  (state: SubmitState) => state.passengers
)

export const selectPassengers = createSelector(
  selectPassengersForm,
  (form: FormGroupState<Passengers>): Passengers => unbox(form.value)
)

export const selectPassengersQuantity = createSelector(
  selectPassengers,
  (state: Passengers): Option => state.quantity
)

export const selectNumberOfPassengers = createSelector(
  selectPassengers,
  (state: Passengers): number => (
    state.quantity?.label ?
      parseInt(state.quantity.label.replace(/\D/g, ''), 10) : null
  )
)

export const selectIsSubmitValid = createSelector(
  selectPassengersQuantity,
  selectUserContactInfo,
  selectIsTowLocationValid,
  (
    passengers: Option,
    contactInfo: UserContactInfo,
    isTowLocationValid
  ): boolean =>
    Boolean(passengers?.label) &&
    Boolean(contactInfo.contactNumber) &&
    Boolean(contactInfo.firstName) &&
    Boolean(contactInfo.lastName) &&
    isTowLocationValid
)

export const selectMergedPassengersComments = createSelector(
  selectPassengers,
  (passengers: Passengers): string => {
    if (!passengers) {
      return ''
    }

    const result = []
    if (passengers.quantity) {
      result.push(`PASSENGERS: ${passengers.quantity.label}`)
    }
    if (passengers.notes && passengers.notes.trim()) {
      result.push(passengers.notes)
    }
    return result.join(' ')
  }
)

export const selectPassengersNotes = createSelector(
  selectPassengers,
  (passengers: Passengers): string => passengers.notes
)
