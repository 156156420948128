import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { AAAStore } from '../../../store/root-reducer'
import { isMobileBrowser } from '../../../shared/utils/browser-detect'
import { CHARACTER_LIMIT_VEHICLE_COLOR } from '../../location/location.constants'
import { Color, COLORS } from '../vehicle.utils';
import { AdobeEventTypes } from '../../tagging/tagging.types'
import events from '../../tagging/events'
import { AdobeEventService } from '../../tagging/adobe/event-adobe.service'
import { AbstractComponent } from '../../../shared/abstract.component'
import { selectEditOrAddVehicleDesc } from '../vehicle.selectors'

const SELECT_VEHICLE_COLOR_LABEL = () => $localize`Select vehicle color`
const COLORS_AND_OTHER = (): Color[] => [
  ...COLORS(),
  {
    label: $localize`other`,
    code: 'other',
  },
]

const arrowWidth = 18
const scrollTime = 300

@Component({
  selector: 'app-color',
  templateUrl: './color.component.html',
  styleUrls: ['./color.component.scss'],
})
export class ColorComponent extends AbstractComponent implements OnInit {
  otherColor = 'other'
  selectVehicleColorLabel = SELECT_VEHICLE_COLOR_LABEL()
  @Input() selectedColor: string
  @Output() selectedColorChange: EventEmitter<string> =
    new EventEmitter<string>()

  colorRows = [COLORS_AND_OTHER().slice(0, 7), COLORS_AND_OTHER().slice(7)]
  maxTabIndex = COLORS_AND_OTHER.length + 1;

  @ViewChild('colorsContainer') colorsContainer: ElementRef

  isScrollAtBegin = true
  isScrollAtEnd = false

  maxChars = CHARACTER_LIMIT_VEHICLE_COLOR

  editOrAddVehicleDesc$ = this.store$.pipe(select(selectEditOrAddVehicleDesc))
  editOrAddVehicleDesc: string

  constructor(
    private store$: Store<AAAStore>,
    private adobeEventService: AdobeEventService
  ) {
    super()
  }

  ngOnInit() {
    this.subscriptions.push(
      this.editOrAddVehicleDesc$.subscribe(
        editOrAdd => this.editOrAddVehicleDesc = editOrAdd
      )
    )
  }

  ngAfterViewInit() {
    this.focusFirstElement();
  }

  selectColor(color: string) {
    this.selectedColor = color
    this.selectedColorChange.emit(color)

    if (!COLORS_AND_OTHER().find((match) => match.label === color)) {
      this.otherColor = color
    }

    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: `${this.editOrAddVehicleDesc} ${events.vehicle.VEHICLE_SELECT_COLOR}`
    })
  }

  scrollLeft() {
    const scrollableArea = this.colorsContainer?.nativeElement
    if (scrollableArea) {
      scrollableArea.scrollLeft -= scrollableArea.clientWidth
      setTimeout(() => {
        if (scrollableArea.scrollLeft <= arrowWidth) {
          this.isScrollAtBegin = true
        }
        this.isScrollAtEnd = false
      }, scrollTime)
    }
  }

  scrollRight() {
    const scrollableArea = this.colorsContainer?.nativeElement
    if (scrollableArea) {
      scrollableArea.scrollLeft += scrollableArea.clientWidth
      setTimeout(() => {
        const lastColor = scrollableArea.lastElementChild.lastElementChild
        if (
          scrollableArea.clientWidth >=
          lastColor.getBoundingClientRect().x - arrowWidth
        ) {
          this.isScrollAtEnd = true
        }
        this.isScrollAtBegin = false
      }, scrollTime)
    }
  }

  isMobile() {
    return isMobileBrowser()
  }

  private focusFirstElement() {
    const container = this.colorsContainer.nativeElement
    const childs = container.getElementsByClassName('color_tile')

    if(childs && childs.length) {
      childs[0].focus();
    }
  }

}
