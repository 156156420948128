import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { from, of, pipe } from 'rxjs'
import { catchError, debounceTime, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators'
import { ErrorReportingService } from '../../../shared/services/error-reporting.service'
import { FSA, PayloadedAction } from '../../../shared/types'
import { concatWith, googleLocationToAAALocation } from '../../../shared/utils'
import { AAAStore } from '../../../store/root-reducer'
import { openMessageDialog, openPromptDialog } from '../../ui/ui.actions';
import { MessageDialogTypes, PromptDialogTypes, StepTypes, TowSections } from '../../ui/ui.types';
import { selectMemberActiveVehicle, selectMemberData, selectMemberShipLevel } from '../../member/member.selectors'
import { GoogleGeocodeService } from '../google-geocode/google-geocode.service'
import {
  ADDRESS_LOOKUP,
  CHECK_TOW_DISTANCE,
  checkTowDistance,
  completeAddressLookup,
  completeCoordsLookup,
  completeHomeAddressLookup,
  completeSetTowDestination,
  CONFIRM_TOW_DISTANCE,
  confirmTowDistance,
  COORDS_LOOKUP,
  GET_TOW_DESTINATION_COORDINATES,
  HOME_ADDRESS_LOOKUP,
  NON_AAR_TOWING_NAMES,
  notifyAddressLookupFailure,
  notifyCoordsLookupFailure,
  notifyHomeAddressLookupFailure,
  notifySetTowDestinationFailure,
  requestSetTowDestination,
  SET_AAR_ADDRESS,
  SET_TOW_DESTINATION,
  SET_TOWING_STEP,
  setAARAddress,
  setTowDestinationMarker,
} from './tow-location.actions'
import { TaggingService } from '../../tagging/tagging.service'
import {
  selectIsTowLocationPreviewValid,
  selectIsTowLocationValid,
  selectTowLocationPreview,
  selectTowLocationPreviewMarker
} from './tow-location.selectors'
import { Params, Router } from '@angular/router'
import {
  AddressWithLandmark,
  GenericCoordinates,
  GoogleLocation,
  GoogleLocationMarker,
  TOW_DISTANCE,
  TOWING_ALERT_TYPES,
  TowLocation
} from '../location.types'
import { GoogleCoordinates } from '../google-geocode/types'
import { haversine } from '../../../shared/haversine'
import { calculateTowingDistance } from './tow-location.utils'
import {
  selectClubTowSettingByMemberLevel,
  selectHasTowClubSettings,
  selectTowAlertRV
} from '../../servicing-club/servicing-club.selectors';
import { addPartialCallRequest } from '../../dashboard/calls.actions';
import { selectBreakdownLocationCoordinates } from '../location.selectors';
import events from '../../tagging/events'
import { RapService } from '../../rap/rap.service'
import { selectRoutesDistance } from '../../route-distance/route-distance.selector'
import { selectMaxTowMileLimit, selectModeConfiguration } from '../../auth/auth.selectors'
import { selectIsEVstation, selectShownAARs } from '../aar/aar.selectors'
import { setTowDestinationCoordinates } from '../../dashboard/calls-statuses/call-status.actions'
import { RAP_EV_TOW_LIMITATION_FLEX, RAP_TOW_LIMITATION_FLEX } from '../../../shared/utils/messages'
import { LocationUtils } from '../location.utils'
import { requestSearchArea } from '../aar/aar.actions';
import { ROUTER_NAVIGATED } from '@ngrx/router-store'
import { resetPassengers } from '../../submit/submit.actions'
import { setTowingStep } from './tow-location.actions'
import { selectActiveCallStatus } from '../../dashboard/calls-statuses/call-status.selectors'
import { MembershipLevelsSettings, Vehicle } from '../../member/member.types'
import { ServiceConfigurationSetting } from '../../servicing-club/servicing-club.types'
import { GENERIC_MAKES } from '../../vehicle/vehicle.types'
import { buildTitle } from '../../../shared/utils/title'
import { Title } from '@angular/platform-browser'
import { selectNeedsTow } from '../../issue/issue.selectors';
import { selectUIState } from '../../ui/ui.selectors';
import { TITLE_SUBMIT } from '../../constants/shared.constants';
import { AdobeEventService } from '../../tagging/adobe/event-adobe.service'
import { AdobeEventTypes } from '../../tagging/tagging.types'

const DRR_TOW_DISTANCE_LIMIT_DEFAULT = (limit: number) => $localize`The tow location you have selected is more than ${limit} miles away. Please verify your towing destination. If the address is accurate and you\'d like to proceed with this destination, please give us a call.`

const handleTowDestination = (destination: TowLocation) =>
  !Boolean(destination.streetName)
    ? openMessageDialog({
      payload: { type: MessageDialogTypes.TOW_LOCATION_ADDRESS }
    })
    : completeSetTowDestination({
      payload: {
        name: destination.name || NON_AAR_TOWING_NAMES.CUSTOM,
        serviceProviderCode: destination.serviceProviderCode,
        address: destination.address,
        landmark: destination.landmark,
        streetName: destination.streetName,
        streetNumber: String(destination.streetNumber),
        city: destination.city,
        state: destination.state,
        postalCode: destination.postalCode,
        accuracy: destination.accuracy,
        latitude: String(destination.latitude),
        longitude: String(destination.longitude),
        isAar: destination.isAar,
        ...( destination.country ? { country: destination.country } : {} ),
        ...( destination.emailAddress ? { emailAddress: destination.emailAddress } : {} ),
        ...( destination.id ? { aarId: destination.id, id: destination.id } : {} )
      }
    })

@Injectable()
export class TowLocationEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AAAStore>,
    private _geocodeService: GoogleGeocodeService,
    private taggingService: TaggingService,
    private adobeEventService: AdobeEventService,
    private errorReportingService: ErrorReportingService,
    private router: Router,
    private rapService: RapService,
    private locationUtils: LocationUtils,
    private titleService: Title,
  ) { }

  handleNavigation = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      map(
        (action: PayloadedAction) => action.payload.routerState.root.queryParams
      ),
      filter((params: Params) => params.step === StepTypes.TOWING),
      withLatestFrom(
        this.store$.pipe(select(selectNeedsTow)),
        this.store$.pipe(select(selectUIState)),
      ),
      map(([params, needsTow, uiState]) => {
        // reset passengers
        if (!needsTow) {
          this.store$.dispatch(resetPassengers())
        }
        const leavingPassengersPage = uiState.currentUrl.indexOf(TowSections.PASSENGERS) > -1
        const isPassengersSection = params.section === TowSections.PASSENGERS
        if (isPassengersSection) {
          this.taggingService.setPageLoadEvent({ pageType: events.passengers.PAGE_TYPE, pageName: events.passengers.PAGE_NAME_TOW_RIDERS })
        } else if(leavingPassengersPage) {
          this.taggingService.setPageLoadEvent({ pageType: events.towTo.PAGE_TYPE, pageName: events.towTo.PAGE_NAME_CONFIRM_TOW_LOCATION })
        }
        const step =
          (isPassengersSection && !needsTow
            ? StepTypes.SUBMIT
            : params.section) || ''
        return setTowingStep({ payload: { step } })
      }),
    )
  )

  handleSetTowDestination = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SET_TOW_DESTINATION.SUCCESS),
        withLatestFrom(this.store$.select(selectActiveCallStatus)),
        filter(([_, activeCallStatus]) => activeCallStatus === null),
        map(() =>
          this.router.navigate([], {
            queryParams: {
              step: StepTypes.TOWING,
              section: TowSections.PASSENGERS,
            },
          })
        )
      ),
    { dispatch: false }
  )

  setMarkerAsTowDestination = createEffect(() => this.actions$.pipe(
    ofType(SET_TOW_DESTINATION.REQUEST),
    filter((action: ReturnType<typeof requestSetTowDestination>) =>
      action.payload.hasOwnProperty('lat')
      && action.payload.hasOwnProperty('lng')
      && action.payload.hasOwnProperty('address')
    ),
    switchMap((action: PayloadedAction<TowLocation | GoogleLocationMarker>) =>
      from(this._geocodeService.getLocationFromAddress(action.payload.address)).pipe(
        map((addresses) => {
          const destination = googleLocationToAAALocation({ address: action.payload.address, location: addresses[0] })

          return handleTowDestination({
            address: action.payload.address,
            name: action.payload.name,
            isAar: action.payload.isAar,
            serviceProviderCode: action.payload.serviceProviderCode,
            ...destination
          })
        }),
        catchError((error) => this.errorReportingService.notifyErrorObservable(error, notifySetTowDestinationFailure))
      )
    ),
  ))

  setLocationAsTowingDestination = createEffect(() => this.actions$.pipe(
    ofType(SET_TOW_DESTINATION.REQUEST),
    filter((action: PayloadedAction<TowLocation>) =>
      action.payload.hasOwnProperty('streetName')
      && action.payload.hasOwnProperty('streetNumber')
      && action.payload.hasOwnProperty('city')
      && action.payload.hasOwnProperty('state')
      && action.payload.hasOwnProperty('postalCode')
    ),
    map((action: PayloadedAction<TowLocation>) => handleTowDestination(action.payload))
  ))

  lookupCoords = createEffect(() => this.actions$.pipe(
    ofType(COORDS_LOOKUP.REQUEST),
    filter((action: FSA<GoogleCoordinates>) =>
      action.payload.hasOwnProperty('lat')
      && action.payload.hasOwnProperty('lng')
    ),
    withLatestFrom(
      this.store$.select(selectBreakdownLocationCoordinates),
      this.store$.select(selectTowLocationPreviewMarker),
    ),
    switchMap(([action, breakdownLocationCoords, previousMarker]: [FSA<GoogleCoordinates>, GenericCoordinates, GoogleLocationMarker]) =>
      from(this._geocodeService.guessLocationFromCoords(action.payload, action.meta)).pipe(
        switchMap((geoLocation) => {
          const location = googleLocationToAAALocation({ address: geoLocation.formatted_address, location: geoLocation })
          return this
            .resolveLocationRequestActions(action, location, breakdownLocationCoords, geoLocation.formatted_address, previousMarker)
        }),
        catchError((error) =>
          this.errorReportingService.notifyErrorObservable(error, notifyCoordsLookupFailure)
        )
      )
    )
  ))

  lookupAddress = createEffect(() => this.actions$.pipe(
    ofType(ADDRESS_LOOKUP.REQUEST),
    filter((action: PayloadedAction<AddressWithLandmark>) => !!action.payload.address),
    withLatestFrom(
      this.store$.select(selectBreakdownLocationCoordinates),
    ),
    switchMap(([action, breakdownLocationCoords]: [PayloadedAction<AddressWithLandmark>, GenericCoordinates]) =>
      from(this._geocodeService.getLocationFromAddress(action.payload.address)).pipe(
        switchMap((geoLocation) => {
          const location = googleLocationToAAALocation({
            address: action.payload.address,
            location: geoLocation[0],
            ...(action.payload.landmark ? {landmark: action.payload.landmark} : {})
          })
          return this.resolveLocationRequestActions(action, location, breakdownLocationCoords)
        }),
        catchError((error) => this.errorReportingService.notifyErrorObservable(error, notifyAddressLookupFailure))
      )
    ),
  ))

  lookupHomeAddress = createEffect(() => this.actions$.pipe(
    ofType(HOME_ADDRESS_LOOKUP.REQUEST),
    withLatestFrom(
      this.store$.pipe(select(selectMemberData)),
      this.store$.select(selectBreakdownLocationCoordinates),
    ),
    switchMap(([action, memberInfo, breakdownLocationCoords]) => {
      const memberAddress = pipe(
        concatWith(memberInfo.city, ', '),
        concatWith(memberInfo.stateProvince, ', ')
      )(memberInfo.basicAddress)

      return from(this._geocodeService.getLocationFromAddress(
        memberAddress
      )).pipe(
        switchMap((addresses: GoogleLocation[]) => {
          const location = googleLocationToAAALocation({ address: memberAddress, location: addresses[0] })
          return this.resolveLocationRequestActions(action, location, breakdownLocationCoords, memberAddress)
        }),
        catchError((error) => this.errorReportingService.notifyErrorObservable(error, notifyHomeAddressLookupFailure))
      )
    })
  ))

  lookupSuccess = createEffect(() => this.actions$.pipe(
    ofType(
      COORDS_LOOKUP.SUCCESS,
      ADDRESS_LOOKUP.SUCCESS,
    ),
    map(() => {
      this.taggingService.setClickEvent(
        events.towTo.DESTINATION_MANUAL_INPUT,
        events.towTo.DESTINATION_PAGE_TYPE,
        { params: { itemName: null, itemId: null } }
      )
      this.adobeEventService.sendEvent({
        eventName: AdobeEventTypes.CTA,
        eventValue: events.towTo.DESTINATION_MANUAL_INPUT
      })
    })
  ), { dispatch: false })

  lookupFinalize = createEffect(() => this.actions$.pipe(
    ofType(CONFIRM_TOW_DISTANCE),
    withLatestFrom(
      this.store$.pipe(select(selectTowLocationPreview)),
    ),
    map(([_, towPreview]) => {
      this.taggingService.setClickEvent(
        events.towTo.DESTINATION_NEXT_CLICK,
        events.towTo.DESTINATION_PAGE_TYPE,
        { params: { itemName: null, itemId: null } }
      )
      this.adobeEventService.sendEvent({
        eventName: AdobeEventTypes.CTA,
        eventValue: events.towTo.DESTINATION_NEXT_CLICK
      }, null, {
        location_name: towPreview.location.name,
        location_code: towPreview.location.id
      })

      return addPartialCallRequest()
    })
  ))

  setAAR = createEffect(() => this.actions$.pipe(
    ofType(SET_AAR_ADDRESS),
    map((action: ReturnType<typeof setAARAddress>) => {
      this.taggingService.setClickEvent(
        events.towTo.DESTINATION_AAR_SELECT,
        events.towTo.DESTINATION_PAGE_TYPE,
        {
          params: {
            itemName: action.payload.location.name,
            itemId: action.payload.location.id
          }
        }
      )
      this.adobeEventService.sendEvent({
        eventName: AdobeEventTypes.CTA,
        eventValue: events.towTo.DESTINATION_AAR_SELECT,
      }, null, {
        location_name: action.payload.location.name,
        location_code: action.payload.location.id
      })
    }),
    debounceTime(500),
    tap(() => (window as any).aaa_gtm_prod?.push({itemName: null})), // set itemName to null after making the setAAR event call
    tap(() => window.scroll({ top: 0, left: 0, behavior: 'smooth' }))
  ), { dispatch: false })

  updateTowDestination = createEffect(() => this.actions$.pipe(
    ofType(CONFIRM_TOW_DISTANCE),
    withLatestFrom(
      this.store$.pipe(select(selectIsTowLocationPreviewValid)),
    ),
    filter(([_, isPreviewValid]) => isPreviewValid),
    withLatestFrom(
      this.store$.pipe(select(selectTowLocationPreview)),
    ),
    map(([_, preview]) => requestSetTowDestination({
      payload: preview.location
    }))
  ))

  skipTowDestination$ = createEffect(() => this.actions$.pipe(
    ofType(CONFIRM_TOW_DISTANCE),
    withLatestFrom(
      this.store$.pipe(select(selectIsTowLocationPreviewValid)),
      this.store$.pipe(select(selectIsTowLocationValid)),
    ),
    filter(([_, isPreviewValid, isValid]) => !isPreviewValid && isValid),
    map(() => this.router.navigate([], {
      queryParams: {
        step: StepTypes.TOWING,
        section: TowSections.PASSENGERS
      }
    })
    )
  ), { dispatch: false })

  handleTowDestinationCoordinates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GET_TOW_DESTINATION_COORDINATES),
      switchMap((action: PayloadedAction) => from(this._geocodeService.getLocationFromAddress(action.payload)).pipe(
          map((geo) => setTowDestinationCoordinates({
            payload: {
              lat: geo[0].geometry.location.lat(),
              lng: geo[0].geometry.location.lng(),
            },
          }))
        ))
    )
  )

  checkTowingDistanceAndBenefits$ = createEffect(() => this.actions$.pipe(
    ofType<ReturnType<typeof checkTowDistance>>(CHECK_TOW_DISTANCE),
    withLatestFrom(
      this.store$.pipe(select(selectHasTowClubSettings)),
      this.store$.pipe(select(selectClubTowSettingByMemberLevel)),
      this.store$.pipe(select(selectRoutesDistance)),
      this.store$.pipe(select(selectModeConfiguration)),
      this.store$.pipe(select(selectMaxTowMileLimit)),
      this.store$.pipe(select(selectShownAARs)),
      this.store$.pipe(select(selectIsEVstation)),
    ),
    switchMap((params) => of(params).pipe(
      mergeMap(([action, hasTowSettings, memberLevel, routesDistance, modeConfiguration, maxTowMileLimit, shownAars, isEvStation]) => {
        const stream = []
        const { breakdownCoordinates, towLocation, destinationId } = action.payload;

        const distance = calculateTowingDistance(breakdownCoordinates, towLocation)
        const boundaryMileage = hasTowSettings && memberLevel?.distance ? memberLevel.distance : null

        const type = this.rapService.isRapUser() ?
          TOWING_ALERT_TYPES.RAP
          : (hasTowSettings && memberLevel ? TOWING_ALERT_TYPES.CUSTOM : TOWING_ALERT_TYPES.DEFAULT)

        switch (type) {
          case TOWING_ALERT_TYPES.RAP:
            let towDestinationDistance = this.locationUtils.getDistance(routesDistance, breakdownCoordinates, {
              latitude: towLocation.lat,
              longitude: towLocation.lng
            })

            const towDistanceFinal = towDestinationDistance ? towDestinationDistance : boundaryMileage;
            const isOverLimit = towDistanceFinal > maxTowMileLimit;
            const destinationAvailableWithinLimit = Boolean(shownAars.find(aar => aar.id !== destinationId && aar.distanceTo <= maxTowMileLimit));
            const manualInput = !destinationId
            const manualAddressAllowed = !isOverLimit || !destinationAvailableWithinLimit
            const notAllowedAtAll = towDistanceFinal > modeConfiguration.callCenterTowMileage || (manualInput && !manualAddressAllowed)

            if(notAllowedAtAll) {
              stream.push(openMessageDialog({
                payload: {
                  type: MessageDialogTypes.RAP_TOW_MILEAGE_MESSAGE,
                }
              }))
            } else if(isOverLimit && destinationAvailableWithinLimit) {
              stream.push(openMessageDialog({
                payload: {
                  type: MessageDialogTypes.TOW_BOUNDARY_MESSAGE,
                  params: {
                    message: isEvStation ? RAP_EV_TOW_LIMITATION_FLEX() : RAP_TOW_LIMITATION_FLEX()
                  }
                }
              }))
            } else {
              stream.push(confirmTowDistance())
            }
            break
          case TOWING_ALERT_TYPES.CUSTOM:
            stream.push(boundaryMileage < distance
              ? openMessageDialog({
                payload: {
                  type: MessageDialogTypes.TOW_BOUNDARY_MESSAGE,
                  params: {
                    message: memberLevel.message || DRR_TOW_DISTANCE_LIMIT_DEFAULT(boundaryMileage)
                  }
                }
              })
              : confirmTowDistance()
            )
            break
          case TOWING_ALERT_TYPES.DEFAULT:
            stream.push(this.checkDistanceTowing(distance))
            break
        }

        return stream
      }),
      catchError((error) => this.errorReportingService.notifyErrorObservable(error, notifySetTowDestinationFailure))
    ))
  ))

  handleSetTowingStep$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SET_TOWING_STEP),
        withLatestFrom(
          this.store$.pipe(select(selectMemberShipLevel)),
          this.store$.pipe(select(selectMemberActiveVehicle)),
          this.store$.pipe(select(selectNeedsTow)),
          this.store$.pipe(select(selectTowAlertRV))
        ),
        filter(
          ([action, membershipLevel, activeVehicle, needsTow, towAlertRV]: [PayloadedAction, MembershipLevelsSettings, Vehicle, boolean, ServiceConfigurationSetting]) =>
            needsTow && Boolean(towAlertRV?.value) &&
            action.payload.step === 'summary' &&
            activeVehicle.make.toLowerCase() === GENERIC_MAKES.MOTORHOME_RV &&
            ([
              MembershipLevelsSettings.RV.toLowerCase(),
              MembershipLevelsSettings.PREMIERRV.toLowerCase(),
            ].includes(
              membershipLevel?.toLowerCase()?.replace(' ', '')
            ))
        ),
        tap(() => {
          // enforcing title to prevent concurrency issues in the analytic events
          this.titleService.setTitle(buildTitle(TITLE_SUBMIT(), this.rapService.isRapUser()))
          this.taggingService.setAutomatedEvent('RV Tow Alert Message displayed', events.submit.SUMMARY_PAGE_TYPE)
        }),
        map(([_, __, ___, ____, towAlertRV]) => openMessageDialog({
          payload: {
            type: MessageDialogTypes.CUSTOM,
            title: $localize`Alert`,
            content: towAlertRV.value,
            closeLabel: $localize`Continue`
          },
        }))
      ),
  )

  /**
   * Check distance to get the proper dialog to be displayed
   *
   * @param distanceValue
   */
  private checkDistanceTowing(distanceValue) {
    let action = {}
    if (TOW_DISTANCE.WARNING < distanceValue && TOW_DISTANCE.LIMIT > distanceValue) {
      action = openPromptDialog({
        payload: {
          type: PromptDialogTypes.TOWING_DISTANCE_WARN,
          title: $localize`Alert`,
          content: $localize`The tow location you have entered is greater than ${TOW_DISTANCE.WARNING} miles away. Do you wish to proceed?`
        }
      })
      this.taggingService.setAutomatedEvent(
        events.towTo.DESTINATION_DISTANCE_WARNING,
        events.towTo.DESTINATION_PAGE_TYPE
      )
    } else if (TOW_DISTANCE.LIMIT < distanceValue) {
      action = openMessageDialog({
        payload: {
          type: MessageDialogTypes.TOWING_DISTANCE_LIMIT,
          title: $localize`Alert`,
          content: DRR_TOW_DISTANCE_LIMIT_DEFAULT(TOW_DISTANCE.LIMIT)
        }
      })
      this.taggingService.setAutomatedEvent(
        events.towTo.DESTINATION_DISTANCE_ALERT,
        events.towTo.DESTINATION_PAGE_TYPE
      )
    } else {
      action = confirmTowDistance()
    }

    return action;
  }

  private resolveLocationRequestActions(action, location, breakdownLocationCoords, markerAddress?, previousMarker?) {
    const actions = []
    // consider as the same location when the BL is less than a tenth mile from the TL
    const sameLocationFromBreakdown = haversine(location, breakdownLocationCoords, {
      threshold: 0.1,
      unit: 'mile'
    })
    if (sameLocationFromBreakdown) {
      actions.push(openMessageDialog({
        payload: {
          type: MessageDialogTypes.SAME_ADDRESS_TOW_BL
        }
      }))
      // rollback the marker when its dropped at the same location from BL
      if (previousMarker) {
        actions.push(setTowDestinationMarker({
          payload: {
            ...action.payload,
            name: NON_AAR_TOWING_NAMES.CUSTOM,
            isAar: false,
            address: markerAddress
          }
        }))
        actions.push(setTowDestinationMarker({
          payload: previousMarker
        }))
      }
    } else {
      const locationPayload = (name) => ({
        ...location,
        name,
        isAar: false
      })
      const markerPayload = (name, address, lat, lng) => ({
        name,
        isAar: false,
        address,
        lat,
        lng
      })
      if (action.type === COORDS_LOOKUP.REQUEST) {
        actions.push(completeCoordsLookup({
          payload: {
            location: locationPayload(NON_AAR_TOWING_NAMES.CUSTOM),
            marker: markerPayload(
              NON_AAR_TOWING_NAMES.CUSTOM,
              markerAddress,
              action.payload.lat,
              action.payload.lng)
          }
        }))
      } else if (action.type === ADDRESS_LOOKUP.REQUEST) {
        actions.push(completeAddressLookup({
          payload: {
            location: locationPayload(NON_AAR_TOWING_NAMES.CUSTOM),
            marker: markerPayload(
              NON_AAR_TOWING_NAMES.CUSTOM,
              action.payload.address,
              Number(location.latitude),
              Number(location.longitude))
          }
        }))
      } else if (action.type === HOME_ADDRESS_LOOKUP.REQUEST) {
        actions.push(completeHomeAddressLookup({
          payload: {
            location: locationPayload(NON_AAR_TOWING_NAMES.HOME),
            marker: markerPayload(
              NON_AAR_TOWING_NAMES.HOME,
              markerAddress,
              Number(location.latitude),
              Number(location.longitude))
          }
        }))
      }

    }
actions.push(requestSearchArea({
      payload: {
        center: {
          latitude: location.latitude,
          longitude: location.longitude,
        }
      }
    }))
    return actions
  }
}

export const __TEST__ = {
  handleTowDestination
}
