import { Action, ActionReducer } from '@ngrx/store'
import { AAAStore } from '../root-reducer'
import { AUTH, AUTH_OEM, AUTH_RENTAL, AUTH_VAS } from '../../modules/auth/auth.actions'
import { ErrorDialogTypes } from '../../modules/ui/ui.types'
import { getActionErrorMessage } from './errors.messages'
import { SET_HIGHWAY_EXITS, SET_LOCATION_CLUB, } from '../../modules/location/location.actions'
import { BATTERY_QUOTES } from '../../modules/quotes/quotes.action'
import { SET_SERVICING_CLUB_CONFIGS } from '../../modules/servicing-club/servicing-club.actions'
import { ACTIVE_CALL_STATUS, CALL_STATUS } from '../../modules/dashboard/calls-statuses/call-status.actions';
import { ADD_PARTIAL_CALL, CALL_CANCEL } from '../../modules/dashboard/calls.actions'
import { RapAuthFailure } from '../../modules/auth/auth.types'
import { MEMBERS_SEARCH } from '../../modules/member/member.actions'
import { VEHICLE_YEARS } from '../../modules/vehicle/vehicle.actions'

const OEM_GENERIC_ERROR = () => $localize`We are unable to validate your information at this time, please contact us at`
const RESTRICT_MODEL_ERROR = () => $localize`To continue your request for Roadside Assistance, please contact us at`

const ignoreList = [
  ADD_PARTIAL_CALL.FAILURE,
  SET_HIGHWAY_EXITS.FAILURE,
  SET_LOCATION_CLUB.FAILURE,
  SET_SERVICING_CLUB_CONFIGS.FAILURE,
  BATTERY_QUOTES.FAILURE,
  CALL_STATUS.FAILURE,
  ACTIVE_CALL_STATUS.FAILURE,
  MEMBERS_SEARCH.FAILURE,
  AUTH.FAILURE,
  VEHICLE_YEARS.FAILURE
]

export function errorMiddleware(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return function newReducer(state: AAAStore, action: Action & { payload: RapAuthFailure }) {
    const isDialogActive = state && Object.keys(state.ui.dialog).length > 0
    const isRapTokenAccess = Boolean(state?.auth.isRapTokenAccess)
    const isAuthNoRouting = Boolean(state?.auth.noRouting)

    if (
      !isDialogActive &&
      action.type.includes('FAILURE') &&
      !ignoreList.includes(action.type) &&
      !isAuthNoRouting
    ) {
      const failureContent = getActionErrorMessage(action)

      switch (action.type) {
        case AUTH_OEM.FAILURE:
          let dialogComponent
          let content
          switch(action.payload) {
            case RapAuthFailure.OEM_SERVER_ERROR:
              dialogComponent = ErrorDialogTypes.GENERIC
              content = OEM_GENERIC_ERROR()
              break
            case RapAuthFailure.RESTRICT_MODEL:
              dialogComponent = ErrorDialogTypes.GENERIC
              content = RESTRICT_MODEL_ERROR()
              break
            default:
              dialogComponent = ErrorDialogTypes.AUTH_OEM
          }

          state = {
            ...state,
            ui: {
              ...state.ui,
              dialog: {
                ...state.ui.dialog,
                [dialogComponent]: {
                  type: dialogComponent,
                  disableClose: isRapTokenAccess,
                  params: {
                    hideCloseButton: isRapTokenAccess
                  },
                  content,
                },
              },
            },
          }
          break
        case AUTH_VAS.FAILURE:
          state = {
            ...state,
            ui: {
              ...state.ui,
              dialog: {
                ...state.ui.dialog,
                [ErrorDialogTypes.AUTH_VAS]: { type: ErrorDialogTypes.AUTH_VAS },
              },
            },
          }
          break
        case AUTH_RENTAL.FAILURE:
          state = {
            ...state,
            ui: {
              ...state.ui,
              dialog: {
                ...state.ui.dialog,
                [ErrorDialogTypes.AUTH_RENTAL]: { type: ErrorDialogTypes.AUTH_RENTAL },
              },
            },
          }
          break
        case AUTH.FAILURE:
          state = {
            ...state,
            ui: {
              ...state.ui,
              dialog: {
                ...state.ui.dialog,
                [ErrorDialogTypes.AUTH]: { type: ErrorDialogTypes.AUTH },
              },
            },
          }
          break
        case CALL_CANCEL.FAILURE:
          state = {
            ...state,
            ui: {
              ...state.ui,
              dialog: {
                ...state.ui.dialog,
                [ErrorDialogTypes.CALL_CANCEL]: { type: ErrorDialogTypes.CALL_CANCEL },
              },
            },
          }
          break
        default:
          state = {
            ...state,
            ui: {
              ...state.ui,
              dialog: {
                ...state.ui.dialog,
                [ErrorDialogTypes.GENERIC]: {
                  type: ErrorDialogTypes.GENERIC,
                  content: failureContent,
                },
              },
            },
          }
      }
    }

    return reducer(state, action)
  }
}
