<app-color [(selectedColor)]="color"></app-color>
<div class="divider"></div>
<span
  class="form-error text-error"
  [ngClass]="{ hidden: isPristine || !!color }"
  [attr.aria-hidden]="isPristine || !!color" aria-live="polite"
>
  Please, select a color.
</span>
<app-button i18n
  class="next"
  [inverted]="true"
  (buttonClick)="onNext()"
  classNames="base-btn-size">Next</app-button>
