import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { AbstractComponent } from '../../../shared/abstract.component'
import { AAAStore } from '../../../store/root-reducer'

@Component({
  selector: 'app-description-note',
  templateUrl: './description-note.component.html',
  styleUrls: ['./description-note.component.scss'],
})
export class DescriptionNoteComponent extends AbstractComponent {
  @ViewChild('noteInput', { static: true }) noteInput: ElementRef

  @Input() placeholder = 'Add notes (Optional)...'
  @Input() note: string
  @Input() noteInstructions: string
  @Input() fieldId: string
  @Input() tabIndex = 0
  @Input() showToggle = true
  @Input() showExpanded = false
  @Output() noteAdded: EventEmitter<any> = new EventEmitter<any>()

  constructor(private store$: Store<AAAStore>) {
    super()
  }

  notesChanged(value) {
    this.noteAdded.next(value || '')
  }
}
