import { Component } from '@angular/core';
import { MembershipNumberComponent } from './membership-number.component';

@Component({
  selector: 'app-member-number',
  templateUrl: './member-number.component.html',
  styleUrls: ['../member-credentials/member-credentials.component.scss']
})
export class MemberNumberComponent extends MembershipNumberComponent {

  constructor() {
    super()
  }
}
