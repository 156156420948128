import { Component } from '@angular/core'
import { Store, select } from '@ngrx/store'
import { AAAStore } from '../../store/root-reducer'
import { MemberInfo } from '../../modules/member/member.types'
import { Observable } from 'rxjs'
import { selectMemberData } from '../../modules/member/member.selectors'
import { PlusIndicatorValues } from '../types'
import { getMemberGreetings } from '../utils/entitlement.utils';

@Component({
  selector: 'app-entitlement-info',
  templateUrl: './entitlement-info.component.html',
  styleUrls: ['./entitlement-info.component.scss'],
})
export class EntitlementInfoComponent {
  memberData$: Observable<MemberInfo> = this.store$.pipe(
    select(selectMemberData)
  )

  constructor(private store$: Store<AAAStore>) {}

  getPlusIndicatorName(plusIndicator: string) {
    return PlusIndicatorValues[plusIndicator] || plusIndicator
  }

  _getMemberGreetings(joinYearDate) {
    return getMemberGreetings(joinYearDate)
  }
}
