import { haversine } from '../../../shared/haversine'
import { GenericCoordinates, GoogleLocationMarker } from '../location.types'

export function calculateTowingDistance(
  breakdownLocation: GenericCoordinates,
  towLocation: GoogleLocationMarker,
): number {
  const validCoordinates = breakdownLocation?.latitude && breakdownLocation?.longitude && towLocation?.lat && towLocation?.lng;
  if (!validCoordinates) {
    throw new Error('Invalid coordinates')
  }

  const start = {
    latitude: Number(breakdownLocation.latitude),
    longitude: Number(breakdownLocation.longitude),
  }
  const end = {
    latitude: towLocation.lat,
    longitude: towLocation.lng,
  }
  return haversine(start, end, { unit: 'mile' })
}

