import { Component } from '@angular/core';
import { MemberNameComponent } from './member-name.component';

@Component({
  selector: 'app-login-lite',
  templateUrl: './login-lite.component.html',
  styleUrls: ['../minimal-credentials/minimal-credentials.component.scss'],
})
export class LoginLiteComponent extends MemberNameComponent {

  constructor() {
    super()
  }
}
