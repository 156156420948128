<ng-container *ngIf="(isActiveBatteryCall$ | async) && !isHidden">
  <ng-container *ngIf="(hasBreakdownLocationPostalCode$ | async) && (hasBatteryQuotes || (!hasAttemptedRequest || (isLoading$ | async))); else noQuotes">
    <app-banner
      animated="true"
      (click)="showBatteryQuotes()"
      [classNames]="classNames"
    >
      <div class="battery-quotes-banner__description">
        <app-img
          src="assets/drrweb-lib/images/aaabattery.JPG"
          class="battery-quotes-banner__icon"
          alt="Battery Quotes"
          i18n-alt
        ></app-img>
        <span class="battery-quotes-banner__title" i18n>
          AAA Battery Quotes
        </span>
      </div>
      <app-button
        [loading]="(isLoading$ | async)"
        size="condensed"
        i18n
      >
        Get Quotes
      </app-button>
    </app-banner>
  </ng-container>
  <ng-template #noQuotes>
    <app-banner
      (click)="hide()"
      [classNames]="classNames"
    >
      <div class="battery-quotes-banner__description">
        <app-img
          src="assets/drrweb-lib/images/aaabattery.JPG"
          class="battery-quotes-banner__icon"
          alt="No battery quotes"
          i18n-alt
        ></app-img>
        <span class="battery-quotes-banner__title" i18n>
          No quotes available for your vehicle
        </span>
      </div>
      <app-button
        inverted="true"
        size="condensed"
        i18n
      >
        Close
      </app-button>
    </app-banner>
  </ng-template>
</ng-container>
