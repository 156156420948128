import { Component } from '@angular/core'
import { AAAStore } from '../../../store/root-reducer'
import { select, Store } from '@ngrx/store'
import { combineLatest, Observable, timer } from 'rxjs'
import {
  selectActiveCallStatus,
  selectCallsStatusesData,
  selectCallStatusMessage,
  selectEta,
  selectIsDisplayTime,
} from '../calls-statuses/call-status.selectors'
import { filter, map } from 'rxjs/operators'
import { AAACallStatus, CALL_STATUS_CODES } from '../calls.types'
import { setActiveCallStatus } from '../calls-statuses/call-status.actions'
import { generateCallId } from '../calls.utils'

const ETA_UPDATE_INTERVAL = 60_000

@Component({
  selector: 'app-arrival-time-summary',
  templateUrl: './arrival-time-summary.component.html',
  styleUrls: ['./arrival-time-summary.component.scss'],
})
export class ArrivalTimeSummaryComponent {
  etaTimer = timer(0, ETA_UPDATE_INTERVAL)

  constructor(private store$: Store<AAAStore>) {}

  call$: Observable<AAACallStatus> = this.store$.pipe(
    select(selectActiveCallStatus),
    filter((status) => !!status)
  )

  requests$: Observable<AAACallStatus[]> = this.store$.pipe(
    select(selectCallsStatusesData),
    map((data) => Object.keys(data).map((key) => data[key]))
  )

  statusMessage$: Observable<string> = this.store$.pipe(select(selectCallStatusMessage))

  displayTime$: Observable<boolean> = this.store$.pipe(select(selectIsDisplayTime))

  displayArrival$: Observable<boolean> = this.call$.pipe(
    map(({ callStatus }) => this.shouldDisplayTime(callStatus))
  )

  minutesRemaining$: Observable<number | string> = combineLatest([
    this.store$.pipe(select(selectEta)),
    this.etaTimer,
  ]).pipe(
    map(([eta, _]: [Date, never]) => {
      if (!eta) {
        return null
      }

      const now = new Date()
      const differenceMinutes = (+eta - +now) / 60000
      return parseInt(differenceMinutes.toString(), 10)
    })
  )

  showEtaInfo$: Observable<boolean> = combineLatest([
    this.displayArrival$,
    this.minutesRemaining$,
    this.call$,
  ]).pipe(
    map(([displayEta, minutesRemaining, call]: [boolean, number | string, AAACallStatus]) => displayEta && minutesRemaining && call.callStatus !== CALL_STATUS_CODES.ER)
  )

  shouldDisplayTime(callStatus) {
    switch (callStatus) {
      case CALL_STATUS_CODES.OL:
      case CALL_STATUS_CODES.OS:
      case CALL_STATUS_CODES.UT:
      case CALL_STATUS_CODES.TW:
        return false
      default:
        return true
    }
  }

  requestSelected(request: AAACallStatus) {
    if (request) {
      this.store$.dispatch(
        setActiveCallStatus({
          payload: { id: generateCallId(request.callId, request.callDate) },
        })
      )
    }
  }
}
