<fieldset>
  <legend> Membership </legend>

<section class="minimal-credentials no-shadow">
  <div class="minimal-credentials_membership-line"
       role="navigation"
       aria-labelledby="membership-name">
    <label i18n
           for="firstName"
           id="membership-name">First Name</label>
    <aria-link-button i18n
                      elementId="use-member-number"
                      class="minimal-credentials_no-membership color-black  link-use-member-number"
                      (clickLink)="setUseMembershipNumber($event)">
      Use Member Number
    </aria-link-button>
  </div>
  <input i18n-placeholder
         name="firstName"
         id="firstName"
         type="text"
         [ngrxFormControlState]="controls.firstName"
         placeholder="Enter Your First Name"
         class="first-focusable-element"
         aria-required="true"
         autocomplete="given-name"
         #firstNameInput
         required/>

  <app-form-error i18n
                  [control]="controls?.firstName"
                  name='firstName'
                  type='required'> First Name is required.
  </app-form-error>

  <label i18n
         id="last-name-label"
         for="lastName">Last Name</label>
  <input i18n-placeholder
         name="lastName"
         id="lastName"
         type="text"
         [ngrxFormControlState]="controls.lastName"
         placeholder="Enter Your Last Name"
         aria-required="true"
         autocomplete="family-name"
         required/>

  <app-form-error i18n
                  [control]="controls?.lastName"
                  name='lastName'
                  type='required'> Last Name is required.
  </app-form-error>

  <div class="minimal-credentials_postal-code-line">
    <label i18n for="zipCode">Home Postal Code</label>
    <app-input-zip-code id="zipCode" [controls]="controls" [focusInputHelper]="firstNameInputElem"></app-input-zip-code>
  </div>
</section>
</fieldset>
