<!-- <div class="large-title">
  Select Your Car's Year
</div> -->

<div class="years_common">
  <a
    *ngFor="let year of recentYears; let index = index"
    role="button"
    [attr.aria-label]="vehicleYearLabel + ' ' + year"
    class="years_tile no-padding"
    [title]="vehicleYearLabel + ' ' + year"
    (click)="yearChange(year)"
    [tabindex]="tabIndexPosition + index"
    [ngClass]="{
      'first-focusable-element': index === 0
    }"
    triggerable-element
  >
    {{year}}
</a>
</div>

<div class="selection" aria-live="polite">
  <app-select
    i18n-placeholder
    placeholder="See other years"
    [items$]="notListedYears$"
    [searchable]="false"
    [dropdownPosition]="'top'"
    [tabindex]="tabIndexPosition + (recentYears.length + 1)"
    (selectionMade)="yearChange($event)">
  </app-select>

  <ng-container *ngIf="isLoading$ | async">
    <app-loading
      width="25"
      height="25"
      class="typeahead_icon-begin">
    </app-loading>
  </ng-container>
</div>
