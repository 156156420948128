import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs'
import { MessageStatus } from '../calls-statuses/call-status.types'

@Component({
  selector: 'app-call-status-tracker',
  templateUrl: './call-status-tracker.component.html',
  styleUrls: ['./call-status-tracker.component.scss']
})
export class CallStatusTrackerComponent {

  @Input() statuses: Observable<MessageStatus[]>

  constructor() {}

}
