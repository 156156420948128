import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core'
import { AARData } from '../aar/aar.types'
import { AAAStore } from '../../../store/root-reducer'
import { Store } from '@ngrx/store'
import { openPromptDialog } from '../../ui/ui.actions';
import { PromptDialogTypes } from '../../ui/ui.types';
import { AbstractResponsiveComponent } from '../../../shared/abstract-responsive.component'
import { DestinationType } from '../../../shared/types';

@Component({
  selector: 'app-location-list-item',
  templateUrl: './tow-location-list-item.component.html',
  styleUrls: ['./tow-location-list-item.component.scss'],
})
export class TowLocationListItemComponent extends AbstractResponsiveComponent implements OnInit {
  @Input() location: AARData
  @Output() summaryClick: EventEmitter<AARData> = new EventEmitter<AARData>()
  @Output() onShopDetailsClose: EventEmitter<void> = new EventEmitter()
  @Input() tabIndex = 0
  showAaaDiscount = false
  aarStrType = DestinationType[DestinationType.AAR]

  constructor(private store$: Store<AAAStore>) {
    super()
  }

  ngOnInit() {
    this.showAaaDiscount = this.location.type?.toUpperCase() === this.aarStrType.toUpperCase()
  }

  dialogShopDetails = {
    type: PromptDialogTypes.SHOP_DETAILS,
    close: () => this.onShopDetailsClose.emit(),
  }
  handleSummaryClick($event) {
    this.summaryClick.emit(this.location)
    this.store$.dispatch(
      openPromptDialog({
        payload: {
          ...this.dialogShopDetails,
          params: { id: this.location.id },
        }
      })
    )
  }

}
