import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getCookie } from '../../../shared/utils/cookies';
import { AAAStore } from '../../../store/root-reducer';
import { selectIsCaptchaRequired } from '../../ui/ui.selectors';
import { selectIsRapTokenAccess, selectIsSecure } from '../auth.selectors';
import { AppWhiteLabelId } from '../auth.types';



@Component({
  selector: 'app-credential-entry',
  templateUrl: './credential-entry.component.html',
  styleUrls: ['./credential-entry.component.scss'],
})
export class CredentialEntryComponent {
  isSecure$: Observable<boolean> = this.store$.pipe(select(selectIsSecure));
  isWhiteLabel = false;
  appId: string;
  isCaptchaRequired$: Observable<boolean> = this.store$.pipe(
    select(selectIsCaptchaRequired)
  )
  isRapTokenAccess$: Observable<boolean> = this.store$.pipe(
    select(selectIsRapTokenAccess)
  )

  constructor(protected store$: Store<AAAStore>) {
    this.appId = getCookie('AAA_AppId')
    if (this.appId) {
      this.isWhiteLabel = Object.keys(AppWhiteLabelId).map(key => AppWhiteLabelId[key]).includes(this.appId.toUpperCase())
    }
  }

}
