<div i18n role="heading" aria-level="3" *ngIf="(workingVehicle$ | async).id; else newVehicle">Edit Vehicle</div>
<ng-template #newVehicle>
  <div class="plate-to-vin-header">
    <ng-container *ngIf="isPlateToVinSection">
      <app-vehicle-header-back></app-vehicle-header-back>
    </ng-container>
    <div i18n role="heading" aria-level="3" class="header-title">Add a vehicle</div>
  </div>
</ng-template>
<app-vehicle-edit-options
  *ngIf="!isPlateToVinSection"
  [isActive]="true"
  [vehicle]="workingVehicle$ | async"
  invertedCancel="true">
</app-vehicle-edit-options>
