import { Injectable } from '@angular/core'
import { WindowObject } from '../window-object';
import { ConfigService } from '../../../modules/config/config.service'

const IFRAME_TEST_ID = 'thirdPartyId'

@Injectable({
  providedIn: 'root',
})
export class ThirdPartyCookies {

  constructor(
    private configService: ConfigService,
    private windowObject: WindowObject,
  ) {}

  isEnabled = (): Promise<boolean> => {
    let that = this;
    return new Promise(function(resolve) {
      let frame = document.createElement('iframe')
      frame.id = IFRAME_TEST_ID
      frame.src = that.configService.getConfig().thirdPartyCookiesUrl
      frame.style.display = 'none'
      frame.style.position = 'fixed'
      document.body.appendChild(frame)

      const timeout = setTimeout(function() {
        that.removeIframe()
        resolve(false)
      }, 1e3)

      that.windowObject.getWindow().addEventListener(
        'message',
        (event) => {
          if (event.data === '3pc.supported' || event.data === '3pc.unsupported') {
            clearTimeout(timeout)
            that.removeIframe()
            resolve(event.data === '3pc.supported')
          }
        },
        false
      )
    })
  }

  private removeIframe = () => {
    document.getElementById(IFRAME_TEST_ID).remove()
  }

}
