<app-split-panel class="situation-editor" [showSecondaryOnMobile]="true">
  <div primary class="situation-editor_primary">
    <div class="situation-editor_primary-container">

      <ng-template
        [ngTemplateOutlet]="legacyBackButton"
        [ngTemplateOutletContext]="{
            className: 'primary condensed overlaid show-desktop',
            colorName: 'white'
          }">
      </ng-template>

      <div #primaryContainer>
        <ng-content></ng-content>
      </div>
      <div *ngIf="isEmpty(primaryContainer)" id="panel-default">
        <app-situation-details
          [options]="template.detailGroups[(activeStep$ | async) - 1]"
          [activeSelections]="activeSelections$ | async"
          [activeSituation]="activeSituation$ | async"
          [groupIndex]="(activeStep$ | async) - 1"
          [containingForm]="containingForm"
          [tabIndexPosition]="103"
        >
        </app-situation-details>
        <div
          *ngIf="
            template.allowNotes ||
            template.detailGroups[(activeStep$ | async) - 1]?.allowNotes
          "
        >
          <app-description-note
            [showToggle]="false"
            [showExpanded]="true"
            [note]="notes$ | async"
            [noteInstructions]="template.detailGroups[(activeStep$ | async) - 1]?.noteInstructions"
            (noteAdded)="setNotes($event)"
            fieldId="description-note"
            [tabIndex]="104 + listItemsCount"
          >
          </app-description-note>
        </div>
      </div>
      <app-button
        i18n
        *ngIf="containingForm"
        [inverted]="true"
        [disabled]="!containingForm.form.valid"
        classNames="base-btn-size first-focusable-element"
        type="submit"
        [tabIndex]="105 + listItemsCount">Next</app-button>
    </div>
  </div>

  <div secondary class="situation-editor_status show-mobile">
    <ng-template
      [ngTemplateOutlet]="legacyBackButton"
      [ngTemplateOutletContext]="{ className: 'contrast condensed', colorName: 'red' }">
    </ng-template>

    <app-situation-summary [details]="details$ | async" [isRapUser]="isRapUser$ | async"></app-situation-summary>
  </div>
</app-split-panel>

<ng-template #legacyBackButton let-className="className" let-colorName="colorName">
  <app-banner
    [classNames]="className"
    (click)="back()"
    [tabIndex]="102">
    <aria-link-button i18n-ariaLabel ariaLabel="navigate back" role="link" (clickLink)="back()">
      <icon-arrow
        [colorName]="colorName"
        direction="left">
      </icon-arrow>
      <span i18n class="banner-text">Back</span>
    </aria-link-button>
  </app-banner>
</ng-template>
