import { Component, EventEmitter, Input, Output } from '@angular/core'
import { defer } from 'lodash'
import { PaceSetterCode } from '../issue.types'

@Component({
  selector: 'app-problem-checklist',
  templateUrl: './problem-checklist.component.html',
  styleUrls: ['./problem-checklist.component.scss'],
})
export class ProblemChecklistComponent {
  @Input() heading: string
  @Input() name: string
  @Input() problems: PaceSetterCode[]
  @Input() multiSelect = false
  @Input() allowCollapse = true
  @Input() required = false
  @Input() selectedItems: PaceSetterCode[] = []
  @Input() open = true
  @Input() tabIndexPosition = 0

  @Output() selectionChanged: EventEmitter<PaceSetterCode[]> = new EventEmitter()

  expandedClass = ''

  plusClass = ''

  constructor() {}

  ngAfterViewInit() {
    defer(() => this.bootstrapSelection())
  }

  bootstrapSelection() {
    if (!this.selectedItems || !this.selectedItems.length) {
      return
    }

    // if multi select check if is selected
    if (this.multiSelect) {
      this.selectedItems.forEach((_problem) => {
        const _selected = this.isSelected(_problem)
        this.problemChange(_problem, _selected)
      })
      return
    }

    // if not multi - only take first element
    const [problem] = this.selectedItems
    const selected = this.isSelected(problem)
    this.problemChange(problem, selected)
  }

  toggleExpanded($event) {
    if (!this.allowCollapse) {
      return
    }

    this.open = !this.open
    $event.preventDefault()
  }

  isSelected(problem) {
    return !!this.selectedItems.find((item) => item.name === problem.name)
  }

  problemChange(
    problem: PaceSetterCode,
    checked: boolean,
  ) {
    if (this.multiSelect) {
      this.problemChangeMultiSelect(problem, checked)
    } else {
      this.problemChangeSingleSelect(problem, checked)
    }
  }

  private problemChangeSingleSelect(
    problem: PaceSetterCode,
    checked: boolean,
  ) {
    const _emitValue = checked ? [problem] : []
    this.selectionChanged.emit(_emitValue)
  }

  private problemChangeMultiSelect(
    problem: PaceSetterCode,
    checked: boolean,
  ) {
    let currentItems

    currentItems = this.selectedItems.filter(
      (item) => item.name !== problem.name
    )

    if (checked) {
      currentItems = [...this.selectedItems, problem]
    }

    this.selectionChanged.emit(currentItems)
  }
}
