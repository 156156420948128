<section
  id="credentials-entry"
  class="minimal-credentials"
  [ngClass]="{
    entry: !(isSecure$ | async),
    placeholder: isSecure$ | async
  }"
>

  <form id="signon-form"
    (ngSubmit)="formState.isValid && handleSubmit()"
    class="minimal-credentials_form"
    [ngClass]="{'error': isErrorBoxDisplayed }"
    novalidate
    [ngrxFormState]="formState">
    <ng-container *ngIf="!(isSecure$ | async)">
      <ng-container *ngIf="allowNameAuth; else membershipLines">
        <app-member-name [controls]="controls" (useMembershipNumberEmitter)="useMembershipNumberClicked($event)"></app-member-name>
      </ng-container>

      <ng-template #membershipLines>
        <app-membership-number [controls]="controls" (useMemberNameEmitter)="useMemberNameClicked($event)"></app-membership-number>
      </ng-template>

      <ng-container *ngIf="isPasswordCredentialSupported">
        <fieldset>
          <div class="checkboxes-container">
            <app-checkbox
              i18n
              name="remember-me"
              (checkedChange)="handleRememberMe($event)"
              [checked]="rememberMe">
              Remember me
            </app-checkbox>
          </div>
        </fieldset>
      </ng-container>

      <app-login-fail-assistant (hasError)="setHasError($event)"></app-login-fail-assistant>

      <ng-container *ngIf="displayCaptcha">
        <re-captcha #captchaElement
                    [siteKey]="siteKey"
                    (resolved)="handleCaptchaSuccess($event)"
        ></re-captcha>
      </ng-container>
      <div i18n class="form-error text-error small-text" *ngIf="captchaError">
        You must confirm you are not a robot.
      </div>
      <app-button
        i18n
        id="btn-confirm-default"
        [disabled]="(isSigningIn$ | async)"
        type="submit"
        [loading]="(isSigningIn$ | async) === true"
      >
        Confirm
      </app-button>

    </ng-container>
  </form>

  <div
    *ngIf="!(isSecure$ | async)"
    class="minimal-credentials_not-a-member color-black "
    role="navigation"
    aria-labelledby="login-help"
  >
    <div id="login-help">
      <ng-container *ngIf="!allowNameAuth">
        <aria-link-button
          i18n
          class="link-forgot-member-number"
          (clickLink)="openCannotRemember($event)"
          elementId="forgot-member-number"
          role="button">
          Forgot Member Number
        </aria-link-button>
        |
      </ng-container>
      <aria-link-button
        i18n
        (clickLink)="openNotMember($event)"
        class="minimal-credentials_not-a-member-dialog"
        elementId="not-a-member"
        role="button">
        Not a Member?
      </aria-link-button>
    </div>
  </div>

  <div i18n class="minimal-credentials_terms">
    By selecting Confirm, you accept the
  </div>

  <div class="minimal-credentials_terms" role="navigation" aria-labelledby="terms">
    <aria-link-button
      i18n
      elementId="terms"
      (clickLink)="openTerms()"
      class="terms-and-conditions__link">
      Terms and Conditions
    </aria-link-button>
  </div>

  <ng-container *ngIf="!(isSecure$ | async) && isWebApp()">
    <div class="privacy-policy" id="privacy-policy" role="navigation" aria-labelledby="privacy-policy">
      <aria-link-button
        i18n
        (clickLink)="openPrivacyPolicy()"
        class="privacy-policy__link"
        elementId="privacy-policy-link">
        Privacy Policy
      </aria-link-button>
    </div>
  </ng-container>
</section>
