import { getRouterSelectors } from '@ngrx/router-store'
import { createFeatureSelector, createSelector, MemoizedSelector, } from '@ngrx/store'
import { AAAStore } from '../../store/root-reducer'
import { selectIsPaceSetterValid } from '../issue/issue.selectors'
import { selectIsBreakdownLocationValid } from '../location/location.selectors'
import { selectIsTowLocationValid } from '../location/tow-location/tow-location.selectors'
import { selectIsSubmitValid } from '../submit/submit.selectors'
import { StepTypes } from '../ui/ui.types'
import { selectIsVehicleValid } from '../vehicle/vehicle.selectors'
import { WizardState } from './wizard.reducer'

const selectRouter: any = createFeatureSelector('router')

const { selectUrl } = getRouterSelectors(selectRouter)

// This proxy selector is sadly required for testing purposes.
// The selectUrl function is a non-writtable, non-projectable; un-mockable function.
// An alternative by Todd Motto:
// https://github.com/ultimatecourses/ngrx-store-effects-app/
// blob/27-testing-effects/src/products/store/selectors/pizzas.selectors.spec.ts#L108
export const selectCurrentUrl = createSelector(selectUrl, (url) => url)

export const createSelectIsCurrentStepValid: MemoizedSelector<
  AAAStore,
  (step: string) => boolean
> = createSelector(
  selectIsPaceSetterValid,
  selectIsBreakdownLocationValid,
  selectIsVehicleValid,
  selectIsSubmitValid,
  selectIsTowLocationValid,
  (
      isIssueValid: boolean,
      isBreakdownLocationValid: boolean,
      isVehicleValid: boolean,
      isSubmitValid: boolean,
      isTowingValid: boolean
    ) =>
    (step: StepTypes): boolean => {
      const stepValidationMap = {
        [StepTypes.ISSUE]: isIssueValid,
        [StepTypes.BREAKDOWN_LOCATION]: isBreakdownLocationValid,
        [StepTypes.VEHICLE]: isVehicleValid,
        [StepTypes.SUBMIT]: isSubmitValid,
        [StepTypes.TOWING]: isTowingValid,
      }
      return stepValidationMap[step]
    }
)

const stateKey = 'wizard'

export const selectWizardState = createFeatureSelector<WizardState>(stateKey)

export const currentEditStepLocation = createSelector(
  selectWizardState,
  (state: WizardState): any => (state ? state['editionStepLocation'] : null)
)
