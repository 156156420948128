import { AbstractIconComponent } from './abstract.icon'
import { Component } from '@angular/core'

@Component({
  selector: 'icon-cellphone',
  templateUrl: './cellphone.icon.html',
})
export class CellPhoneComponent extends AbstractIconComponent {
  static icon = 'cellphone'
}
