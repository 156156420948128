import {Component, Input} from '@angular/core'

@Component({
  selector: 'app-reviews-count',
  templateUrl: 'reviews-count.component.html',
  styleUrls: ['./reviews-count.component.scss'],
})
export class ReviewsCountComponent {

  @Input() numberReviews: number

  constructor() {
  }
}
