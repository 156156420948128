import { Component, EventEmitter, Input, Output } from '@angular/core'
import { I18nDistanceUnit } from '../i18n.types'

@Component({
    selector: 'app-unit-system-selector',
    templateUrl: './unit-system-selector.component.html',
    styleUrls: ['./unit-system-selector.component.scss'],
})
export class UnitSystemSelectorComponent {
    @Input()
    unitSystem: I18nDistanceUnit
    @Input()
    selected: boolean
    @Output() onClick = new EventEmitter<I18nDistanceUnit>()

    constructor() {
    }

    handleClick(event: Event) {
        event.preventDefault()
        this.onClick.emit(this.unitSystem)
    }

}
