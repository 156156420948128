import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { AAAStore } from '../../../../../store/root-reducer'

import { AbstractComponent } from '../../../../../shared/abstract.component'
import { phoneMask } from '../../../../auth/minimal-credentials/minimal-credentails.constants'
import { FormGroupState } from 'ngrx-forms'
import { PhoneNumberFormState } from '../../../forms/forms.reducer'
import { selectPhoneNumberAuth } from '../../../forms/forms.selectors'
import { AUTH_PHONE_NUMBER, authPhoneNumberFailure, authPhoneNumberRequest } from '../../../../auth/auth.actions'
import events from '../../../../tagging/events';
import { TaggingService } from '../../../../tagging/tagging.service';
import { combineLatest, Observable } from 'rxjs';
import { selectIsLoading } from '../../../loading/loading.selectors';
import { MEMBER_BASIC_INFO, MEMBER_INFO, MEMBERS_SEARCH } from '../../../../member/member.actions';
import { CALL_STATUS } from '../../../../dashboard/calls-statuses/call-status.actions';
import { map } from 'rxjs/operators';
import { DialogData } from '../../../ui.types';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-auth-phone-number',
  templateUrl: './auth-phone-number.component.html',
  styleUrls: ['./auth-phone-number.component.scss'],
})
export class AuthPhoneNumberComponent extends AbstractComponent implements OnInit, AfterViewInit {
  phoneNumberMask = phoneMask

  selectPhoneNumberAuth$ = this.store$.select(selectPhoneNumberAuth)

  form: FormGroupState<PhoneNumberFormState> = null

  hintMatches = true

  isSigningIn$: Observable<boolean> = combineLatest([
    this.store$.pipe(select(selectIsLoading(AUTH_PHONE_NUMBER.ACTION))),
    this.store$.pipe(select(selectIsLoading(MEMBER_INFO.ACTION))),
    this.store$.pipe(select(selectIsLoading(MEMBER_BASIC_INFO.ACTION))),
    this.store$.pipe(select(selectIsLoading(MEMBERS_SEARCH.ACTION))),
    this.store$.pipe(select(selectIsLoading(CALL_STATUS.ACTION))),
  ]).pipe(map((areActionsLoading) => areActionsLoading.some(Boolean)))

  @ViewChild('input')
  inputPhone: ElementRef

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private store$: Store<AAAStore>,
    private taggingService: TaggingService,
  ) {
    super()
  }

  ngOnInit() {
    this.subscriptions.push(
      this.selectPhoneNumberAuth$.subscribe(form => {
        this.form = form
      })
    )
    this.taggingService.setClickEvent(
      events.auth.FORM_PHONE_NUMBER_ONLY_AUTH_PROMPT,
      events.auth.PAGE_TYPE
    )
  }

  ngAfterViewInit(): void {
    this.inputPhone.nativeElement.focus()
  }

  handlePhoneNumberForm() {
    const phoneNumber = this.form.value.phoneNumber.replace(/\D+/g, '') || ''
    if (this.form.isValid && phoneNumber.length === 10 && this.hintMatches) {
      this.taggingService.setClickEvent(
        events.auth.FORM_PHONE_NUMBER_ONLY_AUTH_REQUEST,
        events.auth.PAGE_TYPE
      )
      this.store$.dispatch(authPhoneNumberRequest({payload: phoneNumber}))
      this.data.close()
    }
  }

  handleClose() {
    this.data.close()
    this.store$.dispatch(authPhoneNumberFailure())
  }

  validateNumber() {
    const phoneNumberInput = this.form.value.phoneNumber.replace(/\D+/g, '') || ''
    if (phoneNumberInput.length === 10) {
      this.hintMatches = this.data.params.phoneNumbersHint.findIndex(({phoneNumber}) => phoneNumberInput.toString().endsWith(phoneNumber)) !== -1
    } else {
      this.hintMatches = true
    }
  }

}
