<app-edit-tag
  [source]="vehicle"
  field="year"
  (selected)="editVehicleSection('years')"
  [disabled]="yearDisabled$ | async"
  [tabIndexPosition]="tabIndexPosition + 1"
></app-edit-tag>
<app-edit-tag
  [source]="vehicle"
  field="make"
  (selected)="editVehicleSection('makes')"
  [tabIndexPosition]="tabIndexPosition + 1"
></app-edit-tag>
<app-edit-tag
  alias="{{ getModel(vehicle) }}"
  [source]="vehicle"
  field="model"
  (selected)="editVehicleSection('models')"
  [tabIndexPosition]="tabIndexPosition + 1"
></app-edit-tag>
<app-edit-tag
  [source]="vehicle"
  field="color"
  (selected)="editVehicleSection('details')"
  [tabIndexPosition]="tabIndexPosition + 1"
></app-edit-tag>
<aria-link-button
  class="cancel-edit-vehicle"
  role="button"
  (clickLink)="editVehicleSection('')"
  [tabIndex]="tabIndexPosition + 1"
>
  <div [ngClass]="invertedCancel ? 'inverted' : 'default'">
    <ng-container i18n>CANCEL</ng-container>
    <icon-close [color]="invertedCancel ? '#003893' : '#fff'" ></icon-close>
  </div>
</aria-link-button>
