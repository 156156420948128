<div class="section-wrapper">
  <div class="top-section">
    <div class="line top-line">
      <div class="tiny-text label" i18n>Issue:</div>
      <div class="small-text bold description">{{ paceSetterCode$ | async | issue:'title' }}</div>
    </div>
  </div>
</div>

<div *ngIf="vehicle$ | async as vehicle" class="line">
  <div class="tiny-text label" i18n>Your Vehicle:</div>
  <div class="small-text bold description">{{ vehicle | vehicle:'color' }}</div>
</div>

<div
  class="line destination-container"
  *ngIf="towDestination$ | async as destination"
>
  <div class="tiny-text label" i18n>Tow To:</div>
  <div class="small-text bold">
    <div>{{ destination?.address }}</div>
  </div>
  <app-img
    *ngIf="destination?.isAar"
    classNames="approved-logo"
    src="assets/drrweb-lib/images/AAA Approved.svg"
    alt="AAA Approved"
    i18n-alt
  ></app-img>
</div>
