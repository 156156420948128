<div class="divider"></div>

<app-notes-input
  [inputValue]="note"
  (inputValueChange)="notesChanged($event)"
  [placeholder]="placeholder"
  [fieldId]="fieldId"
  [tabIndex]="tabIndex"
  [showToggle]="showToggle"
  [showExpanded]="showExpanded"
>
  <label *ngIf="noteInstructions" [for]="fieldId">
    {{ noteInstructions }}
  </label>
</app-notes-input>
