import { Component, Input, OnInit } from '@angular/core'
import { AAAStore } from '../../../store/root-reducer'
import { select, Store } from '@ngrx/store'
import { ADD_CALL, addCallRequest } from '../../dashboard/calls.actions'
import { Observable } from 'rxjs'
import { selectIsLoading } from '../../ui/loading/loading.selectors'
import { TaggingService } from '../../tagging/tagging.service'
import { selectContactForm, selectPassengers } from '../submit.selectors'
import { map } from 'rxjs/operators'
import { currentEditStepLocation } from '../../wizard/wizard.selectors'
import { EditService } from '../../../shared/services/edit.service'
import { UserContactInfo } from '../submit.reducer'
import { FormGroupState } from 'ngrx-forms'
import { NumericOnlyValueConverter } from '../../../shared/value-converters/numeric-only.value-converter'

import { selectEligibility } from '../../auth/auth.selectors'
import { AbstractResponsiveComponent } from '../../../shared/abstract-responsive.component'
import events from '../../tagging/events'
import { RapService } from '../../rap/rap.service'
import { Eligibility } from '../../auth/eligibility.types'
import { Title } from '@angular/platform-browser'
import { buildTitle } from '../../../shared/utils/title'
import { ErrorDialogTypes, StepTypes, TowSections } from '../../ui/ui.types'
import { selectActivePaceSetterCode } from '../../issue/issue.selectors';
import { PaceSetterCode } from '../../issue/issue.types';
import { openErrorDialog } from '../../ui/ui.actions';
import { TITLE_SUBMIT } from '../../constants/shared.constants';
import { AdobeEventTypes } from '../../tagging/tagging.types'
import { AdobeEventService } from '../../tagging/adobe/event-adobe.service'

const HOW_MANY_LABEL = () => $localize`How many?`
const SLOW_SUBMIT_THRESHOLD = 20000

@Component({
  selector: 'app-review-issue',
  templateUrl: './review-issue.component.html',
  styleUrls: ['./review-issue.component.scss'],
})
export class ReviewIssueComponent extends AbstractResponsiveComponent implements OnInit {
  constructor(
    public editService$: EditService,
    public store$: Store<AAAStore>,
    public tagging: TaggingService,
    private adobeEventService: AdobeEventService,
    private rapService: RapService,
    private titleService: Title
  ) {
    super()
    this.titleService.setTitle(
      buildTitle(TITLE_SUBMIT(), this.rapService.isRapUser())
    )
    this.tagging.setPageEvent(
      events.submit.SUMMARY_PAGE_PROMPT,
      events.submit.SUMMARY_PAGE_TYPE
    )
    this.tagging.setPageLoadEvent({ pageType: events.submit.PAGE_TYPE, pageName: events.submit.PAGE_NAME_REVIEW })
    this.displayCustomWarning = false
  }
  DEFAULT_TEXT_LENGTH = 250
  remainingText = this.DEFAULT_TEXT_LENGTH
  howManyLabel = HOW_MANY_LABEL()
  displayCustomWarning = false
  @Input() withoutHeader: Boolean = false

  eligibility$: Observable<Eligibility> = this.store$.pipe(
    select(selectEligibility)
  )
  eligibility

  selectedPassengers$: Observable<any> = this.store$.pipe(
    select(selectPassengers),
    map((passengers) => {
      const quantity =
        (passengers.quantity ? passengers.quantity.label : '') || ''
      const notes = passengers.notes || ''
      return { quantity, notes }
    })
  )

  currentEditing$: Observable<any> = this.store$.pipe(
    select(currentEditStepLocation)
  )

  isCallRequesting$: Observable<boolean> = this.store$.pipe(
    select(selectIsLoading(ADD_CALL.ACTION))
  )

  paceSetterCode$: Observable<PaceSetterCode> = this.store$.pipe(
    select(selectActivePaceSetterCode)
  )
  paceSetterCode

  contactForm: FormGroupState<UserContactInfo> = null
  controls: any = null
  phoneValueConverter = new NumericOnlyValueConverter()

  isRapUser: Boolean = false
  phoneNumber = ''
  maskPatterns = {
    N: {
      pattern: /[a-zA-Z\s]/
    },
    P: {
      pattern: /[2-9]/
    },
    0: {
      pattern: /\d/
    },
  }

  ngOnInit(): void {
    this.isRapUser = this.rapService.isRapUser()
    this.subscriptions.push(
      this.store$.pipe(select(selectContactForm)).subscribe((form) => {
        this.contactForm = form
        this.controls = form.controls
        this.controls.displayId.value = true
        this.controls.displayId.isPristine = true
        this.controls.displayId.isDirty = true
        this.controls.displayId.isTouched = true
        this.remainingCharacters(this.controls.additionalDetails.value)
      }),
      this.eligibility$.subscribe((eligibility) => {
        this.eligibility = eligibility
        if (eligibility) {
          this.controls.firstName.value =
            eligibility.contractIdentityData?.contact?.firstName?.substring(
              0,
              15
            )
          this.controls.lastName.value =
            eligibility.contractIdentityData?.contact?.lastName?.substring(
              0,
              15
            )
        } else {
          this.controls.firstName.value = 'first'
          this.controls.lastName.value = 'last'
        }
      }),
      this.paceSetterCode$.subscribe((paceSetterCode) => this.paceSetterCode = paceSetterCode)
    )
  }

  handleCallSubmit(phoneInput, firstNameInput, lastNameInput) {
    if(this.rapService.isRapNotEligible(this.paceSetterCode, this.eligibility)) {
      this.store$.dispatch(openErrorDialog({
        payload: {
          type: ErrorDialogTypes.NOT_ELIGIBLE
        }
      }))
      return
    }

    // if form is invalid, avoid to continue
    if (!this.contactForm.isValid) {
      if (!this.controls.contactNumber.isValid) {
        this.controls.contactNumber.isPristine = false
        this.controls.contactNumber.isDirty = true
        this.controls.contactNumber.isTouched = true
        phoneInput.focus()
      }

      if(this.isRapUser) {
        if (!this.controls.firstName.isValid) {
          this.controls.firstName.isPristine = false
          this.controls.firstName.isDirty = true
          this.controls.firstName.isTouched = true
          firstNameInput.focus()
        }

        if (!this.controls.lastName.isValid) {
          this.controls.lastName.isPristine = false
          this.controls.lastName.isDirty = true
          this.controls.lastName.isTouched = true
          lastNameInput.focus()
        }
      }

      if (!this.controls.displayId.isValid) {
        this.controls.displayId.isPristine = false
        this.controls.displayId.isDirty = true
        this.controls.displayId.isTouched = true
      }

      return
    }

    this.tagging.setClickEvent(
      events.submit.SUMMARY_SUBMIT_CLICK,
      events.submit.SUMMARY_PAGE_TYPE
    )

    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: events.submit.SUMMARY_CALL_REQUEST_SUBMITTED
    })

    if (this.controls.additionalDetails.value.length > 2) {
      this.adobeEventService.sendEvent({
        eventName: AdobeEventTypes.CTA,
        eventValue: events.submit.SUMMARY_OPTIONAL_DETAILS_ENTERED
      })
    }

    this.store$.dispatch(addCallRequest())

    setTimeout(() => {
      this.displayCustomWarning = true
    }, SLOW_SUBMIT_THRESHOLD)
  }

  handleTextUpdate() {
    if (this.controls.shouldTextUpdates.value) {
      this.adobeEventService.sendEvent({
        eventName: AdobeEventTypes.CTA,
        eventValue: events.submit.SUMMARY_TEXT_UPDATES_REMOVED
      })
    }
  }

  remainingCharacters(value: string) {
    this.remainingText = this.DEFAULT_TEXT_LENGTH - value.length
  }

  goToPassengers() {
    this.editService$.doEdit(StepTypes.TOWING, TowSections.PASSENGERS)
  }
}
