import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { AAAStore } from '../../../store/root-reducer'
import { cancelRequest } from '../../actions/shared.actions'
import { Store, select } from '@ngrx/store'
import { TaggingService } from '../../../modules/tagging/tagging.service'
import { Observable } from 'rxjs'
import { ADD_CALL, CALL_CANCEL } from '../../../modules/dashboard/calls.actions'
import { selectIsLoading } from '../../../modules/ui/loading/loading.selectors'
import { selectHasAvailableCall } from '../../../modules/dashboard/calls-statuses/call-status.selectors'
import events from '../../../modules/tagging/events'
import { AbstractResponsiveComponent } from '../../abstract-responsive.component'
import { MessageDialogTypes } from '../../../modules/ui/ui.types'
import { openMessageDialog } from '../../../modules/ui/ui.actions'
import { RapService } from '../../../modules/rap/rap.service'
import { AdobeEventTypes } from '../../../modules/tagging/tagging.types'
import { AdobeEventService } from '../../../modules/tagging/adobe/event-adobe.service'

@Component({
  selector: 'app-button-cancel-request',
  templateUrl: './cancel-request.component.html',
  styleUrls: ['./cancel-request.component.scss'],
})
export class CancelRequestButtonComponent extends AbstractResponsiveComponent implements OnInit {
  constructor(
    private store$: Store<AAAStore>,
    private tagging: TaggingService,
    private rapService: RapService,
    private adobeEventService: AdobeEventService
  ) {
    super()
  }

  @Input() bottom: Boolean = false
  @Input() tabIndex = 0
  @Input() defaultColor = false

  @Output() cancelExpanded: EventEmitter<Boolean> = new EventEmitter<Boolean>()

  cancelling = false

  loading$: Observable<boolean> = this.store$.pipe(
    select(selectIsLoading(CALL_CANCEL.ACTION))
  )

  isCallRequesting$: Observable<boolean> = this.store$.pipe(
    select(selectIsLoading(ADD_CALL.ACTION))
  )

  hasAvailableCall = false
  hasAvailableCall$: Observable<boolean> = this.store$.pipe(
    select(selectHasAvailableCall)
  )

  ngOnInit(): void {
    this.subscriptions.push(
      this.hasAvailableCall$.subscribe((hasCall) => {
        this.hasAvailableCall = hasCall
      })
    )
  }

  // Trigger button click action
  cancelCall() {
    this.cancelling = true
    this.cancelExpanded.emit(this.cancelling)
  }

  confirmCancel() {
    this.store$.dispatch(cancelRequest())
    this.cancelling = false
    this.cancelExpanded.emit(this.cancelling)
    this.tagging.setClickEvent(
      this.hasAvailableCall ? events.dashboard.CALL_CANCEL_REQUEST : events.dashboard.PARTIAL_CALL_CANCELED,
      events.dashboard.CALL_STATUS_PAGE_TYPE
    )
    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: this.hasAvailableCall ? events.dashboard.CANCEL_CALL_REQUEST : events.dashboard.PARTIAL_CALL_CANCELED
    })

    if (this.hasAvailableCall) {
      return
    }
    if (this.rapService.isRapUser()) {
      this.store$.dispatch(openMessageDialog({
        payload: {
          type: MessageDialogTypes.PARTIAL_CALL_CANCELED
        }
      }))
    }
  }

  cancelCancel() {
    this.cancelling = false
    this.cancelExpanded.emit(this.cancelling)
  }

  tagEvent() {
    this.tagging.setClickEvent(
      events.shared.CALL_AAA_CLICK,
      events.shared.MENU_PAGE_TYPE
    )
  }

  cancelRequestLabel() {
    return $localize`Cancel Request`
  }
}
