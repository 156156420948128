<h2 i18n
    i18n-aria-label
    role="heading"
    aria-level="1"
    aria-live="assertive"
    class="large-title"
    aria-label="Select your vehicle's make">Select your vehicle's make</h2>

<div class="makes_common" aria-live="assertive" #makesContainer>
  <button
    *ngFor="let make of commonMakes; let index = index;"
    [attr.aria-label]="'Select vehicle make - ' +  make"
    type="button"
    triggerable-element
    class="makes_tile no-padding"
    [tabindex]="400 + index"
    (click)="makeChange(make.label)"
    [ngClass]="{
      'first-focusable-element': index === 0
    }"
  >
    <ng-container *ngIf="make.icon; else makeLabel">
      <app-img
        src="assets/drrweb-lib/images/common-makes/{{ make.icon }}"
        [alt]="make"
      ></app-img>
    </ng-container>
    <ng-template #makeLabel>
      {{make.label}}
    </ng-template>
  </button>
</div>

<div class="selection" aria-live="polite">
  <app-select
    i18n-placeholder
    [elementId]="'makes-select'"
    placeholder="See other makes"
    [items$]="notListedMakes$"
    [searchable]="true"
    [tabindex]="400 + (commonMakes.length + 1)"
    [dropdownPosition]="'top'"
    (selectionMade)="makeChange($event)">
  </app-select>

  <ng-container *ngIf="isLoading$ | async">
    <app-loading
      width="25"
      height="25"
      class="typeahead_icon-begin"
    ></app-loading>
  </ng-container>
</div>
