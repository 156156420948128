import { PACE_SETTER_SITUATION_TYPES } from '../issue/issue.types'

export enum EVENT_TYPES {
  DRR = 'DRR',
  DRRRAP = 'DRRRAP',
}

export enum EVENT_ACTIONS {
  LINKCLICK = 'LINKCLICK',
  ITEMVIEW = 'ITEMVIEW',
  AUTOMATED = 'AUTOMATED',
}

export interface ExtraParams {
  action: string,
  pageType: string,
  params?: object,
  rapAppId?: string,
  channel?: string,
}

export interface GoogleAnalyticsParams extends ExtraParams {
  eventAction: EVENT_ACTIONS,
  eventType: EVENT_TYPES,
  category: string,
  appId: string,
  subCategory: EVENT_TYPES,
  'Event Detail': string,
  memId?: string,
  status?: number,
  eventName?: AdobeEventTypes,
  click_value?: AdobeEventPropType
  cta_click_value? : AdobeEventPropType
  issue_type?: PACE_SETTER_SITUATION_TYPES,
}

export enum AdobeEventTypes {
  CLICK = 'click',
  CTA = 'cta_click',
  SYSTEM = 'system_triggered',
  PAGE_LOAD = 'pageload',
  MEMBER_VALIDATION_PROMPT = 'member_validation_prompt',
  MEMBER_VALIDATION_TOGGLE = 'toggle_member_validation',
  MEMBER_VALIDATION = 'member_validation',
  MEMBER_VALIDATION_RETURN = 'member_validation_return',
}

export type AdobeClickEvent = {
  [K in AdobeEventPropType]?: string
} & {
  product_category?: ProductCategory
  issue_type?: string
}

export enum AdobeEventPropType {
  CTA = 'cta_value',
  CLICK = 'click_value',
  SYSTEM = 'trigger_value'
}

export const ADOBE_EVENT_PROPS = {
  [AdobeEventTypes.SYSTEM]: AdobeEventPropType.SYSTEM,
  [AdobeEventTypes.CTA]: AdobeEventPropType.CTA,
  [AdobeEventTypes.CLICK]: AdobeEventPropType.CLICK
}

export interface AdobeEventData {
  eventValue: string
  eventName: AdobeEventTypes
}

export interface PageLoadEvent {
  business_line: 'Automotive'
  business_line_product: 'Digital ERS'
  page_type: string
  product_category: ProductCategory
  logged_in: 'Y' | 'N'
  user_type: string
  vendor_name: string
  vendor_code: string
}

export interface PageLoadEventParams {
  pageName: string
  pageType?: string,
}

export enum BusinessLineProduct {
  DIGITAL_ERS = 'Digital ERS'
}

export enum FormSubmitStatus {
  SUCCESS = 'login success',
  FAIL = 'login fail'
}

export enum MemberValidationType {
  MEMBER_NAME = 'Member Name',
  MEMBER_NUMBER = 'Member Number',
  CLUB_SIGN_IN = 'Club Sign In',
  APP_SIGN_IN = 'App Sign In',
}

export interface MemberValidationAction {
  member_validation_type: MemberValidationType,
  business_line_product: BusinessLineProduct,
}

export interface MemberValidationReturnAction extends MemberValidationAction {
  membership_number: string,
  form_submit_status: FormSubmitStatus,
  error_type?: number,
}

export enum ProductCategory {
  DRR = 'DRR',
  RAP = 'RAP'
}
export interface AdobeAnalyticsParams {
  eventName: AdobeEventTypes
  eventAttributes?: MemberValidationAction | MemberValidationReturnAction | AdobeClickEvent | PageLoadEvent
  directAttributes?: {
    issue_type?: string,
    [key: string]: string
  }
}

export enum HotjarHitTypes {
  EVENT = 'event',
  IDENTIFY = 'identify'
}

export interface HotjarParams {
  eventActionOrSessionId: string,
  hitType?: HotjarHitTypes,
  membershipNumber?: string,
}
