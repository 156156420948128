import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { AAACallStatus } from '../calls.types'

const LABEL_ACTIVE_REQUESTS = () => $localize`Active requests`

@Component({
  selector: 'app-multiple-requests-button',
  templateUrl: './multiple-requests-button.component.html',
  styleUrls: ['./multiple-requests-button.component.scss'],
  host: { class: 'multiple-requests-button' },
})
export class MultipleRequestsButtonComponent implements OnInit {
  @Input() requests: AAACallStatus[] = []
  @Output() selected: EventEmitter<any> = new EventEmitter<any>()

  isExpanded = false
  ariaLabel = LABEL_ACTIVE_REQUESTS()
  constructor() {}

  ngOnInit() {
    this.isExpanded = false
    this.ariaLabel += ` - ${this.requests.length}`
  }

  toggleDropdown() {
    if (this.requests && this.requests.length > 0) {
      this.isExpanded = !this.isExpanded
    } else {
      this.isExpanded = false
    }
  }

  select($event, request) {
    $event.stopPropagation()
    $event.preventDefault()
    this.isExpanded = false
    this.selected.emit(request)
  }

  requestTrackByFn(request) {
    return request.callId
  }
}
