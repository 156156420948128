<fieldset>
  <legend> Membership </legend>
<section class="minimal-credentials no-shadow">
  <div class="minimal-credentials_membership-line"
       role="navigation"
       aria-labelledby="membership-number">
    <label i18n
           for="membershipNumber"
           id="membership-number">Membership Number</label>
    <aria-link-button i18n
                      elementId="use-member-name"
                      class="minimal-credentials_no-membership color-black  link-use-member-name"
                      (clickLink)="setUseMemberName($event)">
      Use Member Name
    </aria-link-button>
  </div>
  <input i18n-placeholder
         name="membershipNumber"
         id="membershipNumber"
         type="tel"
         [mask]="mask"
         [showMaskTyped]="controls.membershipNumber.value"
         [ngrxFormControlState]="controls.membershipNumber"
         placeholder="16 digit number on your AAA card"
         pattern="^\d{3}\s{2}\d{3}\s{2}\d{9}\s{2}\d$"
         aria-required="true"
         autocomplete="on"
         #membershipNumberInput
         required/>
  <app-form-error i18n
                  [control]="controls?.membershipNumber"
                  name='membershipNumber'
                  type='required'>
    Membership number is required.
  </app-form-error>
  <app-form-error i18n
                  [control]="controls?.membershipNumber"
                  name='membershipNumber'
                  type='pattern'>
    Membership number must be exactly 16 characters long.
  </app-form-error>
  <app-form-error i18n
                  [control]="controls?.membershipNumber"
                  name='membershipNumber'
                  type='checkNumber'>
    {{controls?.membershipNumber.errors['checkNumber']}}
  </app-form-error>

  <div class="minimal-credentials_postal-code-line">
    <label i18n for="zipCode">Home Postal Code</label>
    <app-input-zip-code id="zipCode" [controls]="controls" [focusInputHelper]="membershipNumberInputElem"></app-input-zip-code>
  </div>
</section>
</fieldset>
