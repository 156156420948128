import { VehicleComponent } from './vehicle.component'
import { SelectVehicleComponent } from './select-vehicle/select-vehicle.component'
import { VehicleSummaryComponent } from './vehicle-summary/vehicle-summary.component'
import { CreateVehicleComponent } from './create-vehicle/create-vehicle.component'
import { MakesComponent } from './makes/makes.component'
import { ModelsComponent } from './models/models.component'
import { ColorComponent } from './color/color.component'
import { VehicleDetailsComponent } from './vehicle-details/vehicle-details.component'
import { DetailsEntryComponent } from './details-entry/details-entry.component'
import { VehicleEditOptionsComponent } from './vehicle-edit-options/vehicle-edit-options.component'
import { EditTagComponent } from './edit-tag/edit-tag.component'
import { VehicleHeaderComponent } from './vehicle-header/vehicle-header.component'
import { VehicleYearsComponent } from './vehicle-years/vehicle-years.component'
import { AddPlateComponent } from './add-plate/add-plate.component'
import { VehicleConfirmComponent } from './vehicle-confirm/vehicle-confirm.component'

export function vehicleComponents() {
  return [
    ColorComponent,
    CreateVehicleComponent,
    DetailsEntryComponent,
    MakesComponent,
    ModelsComponent,
    SelectVehicleComponent,
    VehicleComponent,
    VehicleDetailsComponent,
    VehicleHeaderComponent,
    VehicleSummaryComponent,
    VehicleEditOptionsComponent,
    VehicleYearsComponent,
    EditTagComponent,
    AddPlateComponent,
    VehicleConfirmComponent,
  ]
}
