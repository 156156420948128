import { select, Store } from '@ngrx/store'
import { Component, HostListener, Input, OnInit } from '@angular/core'
import { AAAStore } from '../../../store/root-reducer'
import { AbstractComponent } from '../../abstract.component'
import { selectIsCloseButtonVisible, } from '../../../modules/ui/ui.selectors'
import { OEMLogoData } from '../../utils/branding'
import { RapService } from '../../../modules/rap/rap.service'
import { openPromptDialog } from '../../../modules/ui/ui.actions'
import { PromptDialogTypes } from '../../../modules/ui/ui.types'
import { getCurrentLocale, I18N_SUPPORTED_APP_IDS } from '../../i18n/i18n.utils'
import { OEMBranding } from '../../../modules/auth/auth.types'
import { ConfigService } from '../../../modules/config/config.service'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends AbstractComponent implements OnInit {
  @Input() scrollTop = 0
  showLogo = false
  appIdLogo
  locale = getCurrentLocale()
  localeSwitcherEnabled = false
  isCloseButtonVisible$ = this.store$.pipe(select(selectIsCloseButtonVisible))
  isNativeAppView = this.configService.getConfig().nativeAppView

  constructor(
    private store$: Store<AAAStore>,
    private rapService: RapService,
    protected configService: ConfigService,
  ) {
    super()
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.scrollTop = window.scrollY
  }

  isScrolled() {
    return this.scrollTop > 5
  }

  ngOnInit(): void {
    if (this.rapService.isRapUser()) {
      const rapId = this.rapService.getRapAppId() as OEMBranding
      this.localeSwitcherEnabled = I18N_SUPPORTED_APP_IDS.includes(rapId)
      this.appIdLogo = OEMLogoData[rapId]
    } else {
      this.appIdLogo = {
        width: '65',
        height: '40',
        className: 'aaa-logo',
        fileName: 'logo',
        alt: 'AAA'
      }
    }
    this.showLogo = true;
  }

  openLanguageSwitcher() {
    this.store$.dispatch(openPromptDialog({
      payload: {
        type: PromptDialogTypes.I18N_SWITCHER
      }
    }))
  }
}
