import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectDisplayEta, selectDisplayArrivalTime } from '../calls-statuses/call-status.selectors';
import { AAAStore } from '../../../store/root-reducer';

@Component({
  selector: 'app-arrival-time-label',
  templateUrl: './arrival-time-label.component.html',
  styleUrls: ['./arrival-time-label.component.scss']
})
export class ArrivalTimeLabelComponent {
  isArrivalTimeDisplayed = true

  displayEta$: Observable<Date> = this.store$.pipe(select(selectDisplayEta))
  displayArrivalTime$: Observable<number> = this.store$.pipe(select(selectDisplayArrivalTime))

  constructor(
    private store$: Store<AAAStore>
  ) {}

  toggleTime() {
    this.isArrivalTimeDisplayed = !this.isArrivalTimeDisplayed
  }

}
