import { Component, Input, OnInit } from '@angular/core'
import { PaceSetterDetails, PaceSetterList, PaceSetterOptions, PaceSetterSituation, } from '../issue.types'
import { Observable } from 'rxjs'
import { select, Store } from '@ngrx/store'
import {
  selectActivePaceSetterDetails,
  selectActivePaceSetterSelection,
  selectActivePaceSetterSituation,
  selectActivePaceSetterStep,
  selectPaceSetterNotes,
} from '../issue.selectors'
import { AAAStore } from '../../../store/root-reducer'
import { isElementEmpty } from '../../../shared/layouts/layout-utilities'
import { setPaceSetterNotes } from '../issue.actions'
import { AbstractResponsiveComponent } from '../../../shared/abstract-responsive.component'
import { selectIsRapUser } from '../../auth/auth.selectors';
import { Location } from '@angular/common'
import { TaggingService } from '../../tagging/tagging.service';

@Component({
  selector: 'app-situation-editor',
  templateUrl: './situation-editor.component.html',
  styleUrls: ['./situation-editor.component.scss'],
})
export class SituationEditorComponent
  extends AbstractResponsiveComponent
  implements OnInit {
  details$: Observable<PaceSetterDetails> = this.store$.pipe(
    select(selectActivePaceSetterDetails)
  )
  isRapUser$: Observable<boolean> = this.store$.pipe(
    select(selectIsRapUser)
  )

  activeStep$: Observable<number> = this.store$.pipe(
    select(selectActivePaceSetterStep)
  )

  activeSelections$: Observable<PaceSetterList[]> = this.store$.pipe(
    select(selectActivePaceSetterSelection)
  )

  notes$: Observable<string> = this.store$.pipe(select(selectPaceSetterNotes))

  activeSituation$: Observable<PaceSetterSituation> = this.store$.pipe(
    select(selectActivePaceSetterSituation)
  )

  @Input() template: PaceSetterOptions
  @Input() containingForm: any

  listItemsCount: number

  constructor(
    private store$: Store<AAAStore>,
    private location: Location,
    private taggingService: TaggingService,
  ) {
    super()
  }

  ngOnInit() {
    const pageLoadTagging = this.template?.pageLoadTagging
    if (pageLoadTagging) {
      this.taggingService.setPageLoadEvent({ pageType: pageLoadTagging.pageType, pageName: pageLoadTagging.pageName })
    }
    this.containingForm?.form.markAsDirty()
    this.activeStep$.subscribe((activeStep) => {
      const detailGroup = this.template.detailGroups[activeStep - 1]
      // The count of list items (if any) is in use for incrementing the UI tab index
      this.listItemsCount =
        detailGroup && detailGroup.lists
          ? this.template.detailGroups[activeStep - 1].lists[0]?.values.length
          : 1

      if(!this.listItemsCount) {
        this.listItemsCount = 0
      }
    })
  }

  isEmpty(node): boolean {
    return isElementEmpty(node, 'panel-default')
  }

  back() {
    this.location.back()
  }

  setNotes($event) {
    this.store$.dispatch(setPaceSetterNotes({ notes: $event }))
  }
}
