import { AbstractIconComponent } from './abstract.icon'
import { Component } from '@angular/core'

@Component({
  selector: 'icon-notes',
  templateUrl: './notes.icon.html',
})
export class NotesIconComponent extends AbstractIconComponent {
  static icon = 'notes'
}
