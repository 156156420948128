<div class="call-status-eta">
  <app-arrival-time-label></app-arrival-time-label>
</div>
<div class="call-status-tracker">
  <app-call-status-timeline [statuses]="statuses">
  </app-call-status-timeline>
  <div class="call-status-list">
    <app-call-status-item
      *ngFor="let statusMessage of (statuses | async)"
      [title]="statusMessage.title"
      [description]="statusMessage.description"
      [active]="statusMessage.active"
    ></app-call-status-item>
  </div>
</div>
