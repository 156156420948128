import { CredentialEntryComponent } from './credential-entry/credential-entry.component'
import { DesktopHeaderComponent } from './credential-entry/desktop-header/desktop-header.component'
import { MobileHeaderComponent } from './credential-entry/mobile-header/mobile-header.component'
import { AgentIdentificationComponent } from './agent-identification/agent-identification.component'
import { DesktopHeaderAgentComponent } from './agent-identification/desktop-header-agent/desktop-header-agent.component'
import { MobileHeaderAgentComponent } from './agent-identification/mobile-header-agent/mobile-header-agent.component'
import { SignInComponent } from './sign-in/sign-in.component';
import { SignInStarterComponent } from './sign-in/sign-in-starter.component';

export function authComponents() {
  return [
    SignInComponent,
    SignInStarterComponent,
    CredentialEntryComponent,
    DesktopHeaderComponent,
    MobileHeaderComponent,
    AgentIdentificationComponent,
    DesktopHeaderAgentComponent,
    MobileHeaderAgentComponent,
  ]
}
