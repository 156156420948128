import { Component, ViewEncapsulation } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { AAAStore } from '../../store/root-reducer'
import { FUEL_TYPES, PaceSetterList, PaceSetterOptions, PaceSetterSituation } from './issue.types'
import {
  selectActivePaceSetterSelection,
  selectActivePaceSetterSituation,
  selectActivePaceSetterSituationName,
  selectActivePaceSetterStep,
  selectActiveSituationTemplate,
} from './issue.selectors'
import { Observable } from 'rxjs'
import { paceSetterNext } from './issue.actions'
import { capitalize, markFormGroupAsTouched } from '../../shared/utils'
import { TaggingService } from '../tagging/tagging.service'
import { openErrorDialog, openMessageDialog } from '../ui/ui.actions'
import { ErrorDialogTypes, MessageDialogTypes } from '../ui/ui.types'
import { addPartialCallRequest } from '../dashboard/calls.actions'
import events from '../tagging/events'
import { selectEligibility, selectIsRapUser, selectModeConfiguration } from '../auth/auth.selectors'
import { VendorConfigurationMode } from '../auth/mode-configuration.types'
import { Title } from '@angular/platform-browser';
import { RapService } from '../rap/rap.service';
import { buildTitle } from '../../shared/utils/title';
import { Eligibility } from '../auth/eligibility.types';
import { AdobeEventService } from '../tagging/adobe/event-adobe.service'
import { AdobeEventTypes } from '../tagging/tagging.types'

const TITLE_PAGE = () => $localize`What Happened?`
const DIALOG_TITLE_ALERT = () => $localize`Alert`
const DIALOG_BODY_ALERT = () => $localize`To continue your request for Roadside Assistance, please contact us at`

@Component({
  styleUrls: ['./issue.component.scss'],
  templateUrl: './issue.component.html',
  selector: 'app-issue',
  encapsulation: ViewEncapsulation.None,
})
export class IssueComponent {
  isDesktop: boolean

  activeStep$: Observable<number> = this.store$.pipe(
    select(selectActivePaceSetterStep)
  )

  activeSituation$: Observable<PaceSetterSituation> = this.store$.pipe(
    select(selectActivePaceSetterSituation)
  )

  activeSituationName$: Observable<string> = this.store$.pipe(
    select(selectActivePaceSetterSituationName)
  )

  activePaceSetterSelection$: Observable<PaceSetterList[]> = this.store$.pipe(
    select(selectActivePaceSetterSelection)
  )
  activePaceSetterSelection: PaceSetterList[]

  currentTemplate$: Observable<PaceSetterOptions> = this.store$.pipe(
    select(selectActiveSituationTemplate)
  )

  modeConfiguration$: Observable<VendorConfigurationMode> = this.store$.pipe(
    select(selectModeConfiguration)
  )
  rapConfig: VendorConfigurationMode

  eligibility$: Observable<Eligibility> = this.store$.pipe(
    select(selectEligibility)
  )
  eligibility: Eligibility

  isRapUser$ = this.store$.pipe(select(selectIsRapUser))

  constructor(
    private store$: Store<AAAStore>,
    private taggingService: TaggingService,
    private adobeEventService: AdobeEventService,
    private titleService: Title,
    private rapService: RapService,
  ) {
    this.titleService.setTitle(buildTitle(TITLE_PAGE(), this.rapService.isRapUser()))
    this.taggingService.setPageEvent(events.issue.ISSUE_PAGE_PROMPT, events.issue.ISSUE_PAGE_TYPE)

    this.modeConfiguration$.subscribe((configData: VendorConfigurationMode) => this.rapConfig = configData)
    this.eligibility$.subscribe((eligibility: Eligibility) => this.eligibility = eligibility)

    this.activePaceSetterSelection$.subscribe((activePaceSetterSelection: PaceSetterList[]) => {
      this.activePaceSetterSelection = activePaceSetterSelection
    })
  }

  validate({ form }) {
    const touchedForm = markFormGroupAsTouched(form)
    return touchedForm.valid
  }

  next($event, activeSituationName): void {
    $event.preventDefault()
    $event.stopPropagation()

    const actionData = {
      cta_value: capitalize(`${activeSituationName} ${events.issue.ISSUE_PAGE_DETAILS_NEXT_CLICK}`),
      issue_type: activeSituationName
    }
    this.adobeEventService.sendEvent({
      eventValue: actionData.cta_value,
      eventName: AdobeEventTypes.CTA
    }, actionData.issue_type, actionData)

    this.taggingService.setClickEvent(
      `${capitalize(activeSituationName)} ${events.issue.ISSUE_PAGE_DETAILS_NEXT_CLICK}`,
      events.issue.ISSUE_PAGE_TYPE
    )

    const activePaceSetter = this.activePaceSetterSelection.length ? this.activePaceSetterSelection[0] : null
    const firstPaceSetterCode = activePaceSetter && activePaceSetter.members.length ? activePaceSetter.members[0] : null
    if (firstPaceSetterCode?.name === FUEL_TYPES.HYDROGEN_ALT) {
      this.store$.dispatch(
        openMessageDialog({
          payload: {
            type: MessageDialogTypes.CUSTOM_PHONE,
            title: DIALOG_TITLE_ALERT(),
            content: DIALOG_BODY_ALERT(),
          },
        })
      )
    } else if(this.rapService.isRapNotEligible(firstPaceSetterCode, this.eligibility)) {
      this.store$.dispatch(openErrorDialog({
        payload: {
          type: ErrorDialogTypes.NOT_ELIGIBLE
        }
      }))
    } else {
      this.store$.dispatch(paceSetterNext())
      this.store$.dispatch(addPartialCallRequest())
    }
  }

}
