<div class="summary-container" [ngClass]="{ 'no-header': withoutHeader }">
  <div class="left-side">
    <h2 i18n class="title condensed">
      Review and submit
    </h2>
  </div>

  <div class="right-side">
    <ng-template [ngTemplateOutlet]="contactForm" ></ng-template>
    <ng-template [ngTemplateOutlet]="stepsContainer"></ng-template>
  </div>
</div>

<ng-template #stepsContainer>
  <div class="steps-container">
    <h3 i18n class="steps-container_title"> Request details </h3>

    <app-summary></app-summary>

    <!-- Passengers -->
    <ng-container *ngIf="selectedPassengers$ | async as passengers">
      <ng-container *ngIf="passengers?.quantity">
        <div class="situation-seperate separator">
          <div class="issue-icon">
            <app-img i18n-alt
              src="assets/drrweb-lib/images/icons/passengers.svg"
              classNames="icon"
              alt="Passengers count"
            ></app-img>
          </div>
          <div i18n
            class="piece-title small-text bold uppercase passengers-quantity-label"
            [ngClass]="{ disabled: !passengers?.quantity }">
            Passengers:
          </div>
          <div class="piece-heading small-text passengers-quantity-value"
            [ngClass]="{ disabled: !passengers?.quantity }">
            {{ passengers?.quantity || howManyLabel }}
          </div>
          <ng-container *ngIf="!(currentEditing$ | async) && passengers?.quantity">
            <div class="piece-edit">
              <aria-link-button i18n-ariaLabel
                [tabIndex]="908"
                ariaLabel="Edit Passengers"
                (clickLink)="goToPassengers()">
                <icon-edit-outlined></icon-edit-outlined>
              </aria-link-button>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #contactForm>
  <form class="contact-form"
    novalidate
    [ngrxFormState]="contactForm"
    (ngSubmit)="handleCallSubmit(phoneInput, firstNameInput, lastNameInput)">
    <div class="questionnaire">
      <div class="how-to">
        <icon-notes class="how-to-icon"></icon-notes>
        <label for="additionalDetails">
          <ng-container i18n>Any additional details?</ng-container>
          <small i18n> (Optional) </small>
        </label>
      </div>

      <div class="input-container">
        <div class="input-telephone">
          <textarea
            i18n-placeholder
            class="app-square-textbox input-details first-focusable-element"
            id="additionalDetails"
            name="additionalDetails"
            #additionalDetailsInput
            [ngrxFormControlState]="controls?.additionalDetails"
            placeholder="Add Notes (Optional)..."
            [ngrxEnableFocusTracking]="true"
            maxlength="250"
            [tabIndex]="910"
          ></textarea>
        </div>
        <p class="remaining-text" *ngIf="controls.additionalDetails.isFocused">
          <ng-container i18n>Remaining characters:</ng-container>
          <strong>{{ remainingText }}</strong> / {{ DEFAULT_TEXT_LENGTH }}
        </p>
      </div>

      <div class="how-to">
        <icon-cellphone class="how-to-icon"></icon-cellphone>
        <label for="contactNumber">
          <ng-container i18n>How can the driver reach you?</ng-container>
          <small i18n> (Required) </small>
        </label>
      </div>

      <div class="input-container">
        <div class="input-telephone">
          <span class="input-telephone-prefix">+1</span>
          <input
            i18n-placeholder
            class="app-square-textbox input-telephone-field first-focusable-element"
            type="text"
            id="contactNumber"
            name="contactNumber"
            #phoneInput
            [patterns]="maskPatterns"
            mask="(P00) 000-0000"
            [showMaskTyped]="controls.contactNumber.value"
            [ngrxFormControlState]="controls?.contactNumber"
            [ngrxValueConverter]="phoneValueConverter"
            placeholder="Enter your phone number"
            type="tel"
            autocomplete="tel-national"
            (paste)="$event.preventDefault()"
            [tabIndex]="910"
            required
            aria-required="true"
          />
        </div>
        <app-form-error i18n
          [control]="controls?.contactNumber"
          name='contactNumber'
          type='required'
        >
          Phone number is required.
        </app-form-error>
        <app-form-error i18n
          [control]="controls?.contactNumber"
          name='contactNumber'
          type='pattern'
        >
          10 digit phone number is required.
        </app-form-error>
      </div>

      <div class="checkboxes-container">
        <app-checkbox
          name="updates"
          [ngrxFormControlState]="controls?.shouldTextUpdates"
          [tabIndex]="911"
          [focusInverted]="true"
          (checkedChange)="handleTextUpdate()"
        >
          <ng-container i18n>Text me updates</ng-container>
          <div i18n class="custom-message">
            Messages and data rates apply. You may also receive follow-up
            surveys via text.
          </div>
        </app-checkbox>
      </div>

      <!-- TODO Check RAP form -->
      <div class="how-to" [hidden]='!isRapUser'>
        <label for="firstName">
          <ng-container i18n>Who will be waiting with the vehicle?</ng-container>
          <small i18n> (Required) </small>
        </label>
      </div>

      <!-- TODO Check RAP form -->
      <div class="input-name-container" [hidden]='!isRapUser'>
        <div class="input-name-row">
          <div class="input-name">
            <input
              i18n-placeholder
              class="app-square-textbox input-name-field float-left"
              type="text"
              id="firstName"
              name="firstName"
              #firstNameInput
              mask="N{15}"
              [patterns]="maskPatterns"
              [ngrxFormControlState]="controls?.firstName"
              placeholder="Enter Your First Name"
              autocomplete="given-name"
              (paste)="$event.preventDefault()"
              [tabIndex]="913"
            />
          </div>
          <div class="input-name">
            <input
              i18n-placeholder
              i18n-arial-label
              class="app-square-textbox input-name-field float-left"
              type="text"
              id="lastName"
              name="lastName"
              #lastNameInput
              mask="N{15}"
              [patterns]="maskPatterns"
              [ngrxFormControlState]="controls?.lastName"
              placeholder="Enter Your Last Name"
              autocomplete="family-name"
              (paste)="$event.preventDefault()"
              [tabIndex]="914"
              arial-label="last name input"
            />
          </div>
        </div>
        <div class="input-name-row">
          <div>
            <app-form-error
              i18n
              class="input-name"
              [control]="controls?.firstName"
              name='firstName'
              type='required'
            >
              First name is required.
            </app-form-error>
            <app-form-error
              i18n
              class="input-name"
              [control]="controls?.lastName"
              name='lastName'
              type='required'
            >
              Last name is required.
            </app-form-error>
          </div>
        </div>
      </div>
    </div>

    <div i18n class="custom-warning" *ngIf="displayCustomWarning">
      Your request is taking a little longer than expected! Thank you for your patience.
    </div>

    <div class="submit-disclaimer-holder">
      <app-button
        i18n
        id="submit-my-request-btn"
        [loading]="isCallRequesting$ | async"
        [color]="'primary-yellow'"
        [hasShadow]="true"
        [disabled]="isCallRequesting$ | async"
        [tabIndex]="915"
        type="submit">
        SUBMIT MY REQUEST
      </app-button>

      <ng-container *ngIf="!isRapUser">
        <div i18n class="disclaimer">
          By selecting <strong> Submit My Request </strong> above,
          I agree to present PHOTO ID to verify that I am a AAA member.
          I acknowledge that the vehicle requires current registration
          and tags to receive AAA service.
        </div>
      </ng-container>
    </div>
  </form>
</ng-template>
