<div class="dashboard_main">
  <div class="map-container"
       #progressMap>
    <app-progress-map>
    </app-progress-map>
  </div>
  <div class="dashboard_status-column">
    <app-on-our-way [draggerTarget]="progressMap"></app-on-our-way>
  </div>
</div>
