import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { FormGroupState } from 'ngrx-forms';
import { Observable } from 'rxjs';
import { selectIsSecure, selectModeConfiguration } from '../../../modules/auth/auth.selectors';
import { VinMileageAuthFormState } from '../../../modules/ui/forms/forms.reducer';
import { selectVinMileageAuthForm } from '../../../modules/ui/forms/forms.selectors';
import { AAAStore } from '../../../store/root-reducer';
import { ValidationFormComponent } from '../../types';
import { VendorConfigurationMode } from '../../../modules/auth/mode-configuration.types';
import { acceptVin8Form } from '../../../modules/auth/auth.actions';
import { selectMileageLabel } from '../../../modules/ui/ui.selectors'

@Component({
  selector: 'app-vin-mileage-credentials',
  templateUrl: './vin-mileage-credentials.component.html',
  styleUrls: ['./vin-mileage-credentials.component.scss']
})
export class VinMileageCredentialsComponent implements ValidationFormComponent, OnInit {

  mileageLabel$ = this.store$.select(selectMileageLabel)
  mileageLabel: string
  isSecure$ = this.store$.pipe(select(selectIsSecure))
  is8VinForm: boolean

  constructor(private store$: Store<AAAStore>) {
  }

  vinMileageAuthForm: FormGroupState<VinMileageAuthFormState>;
  vinMileageAuthForm$: Observable<FormGroupState<VinMileageAuthFormState>> = this.store$.pipe(
    select(selectVinMileageAuthForm)
  )
  config$: Observable<VendorConfigurationMode> = this.store$.pipe(
    select(selectModeConfiguration)
  )

  get formState(): FormGroupState<any> {
    return this.vinMileageAuthForm
  }

  get controls(): any {
    return this.formState && this.formState.controls
  }

  ngOnInit(): void {
    this.config$.subscribe((config) => {
      this.is8VinForm = !!config.acceptVin8Char
      this.store$.dispatch(acceptVin8Form({payload: this.is8VinForm}))
    })
    this.vinMileageAuthForm$.subscribe((vinMileageAuthForm) => {
      this.vinMileageAuthForm = vinMileageAuthForm
    })
    this.mileageLabel$.subscribe((mileageLabel) => {
      this.mileageLabel = mileageLabel
    })
  }

}
