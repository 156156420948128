<ng-container *ngIf="(memberData$ | async ) as memberData">
  <div class="entitlement">
    <!-- Greetings message -->
    <div class="entitlement-greetings" *ngIf="memberData?.joinDate">
      <div class="normal-text">
        {{_getMemberGreetings(memberData?.joinDate)}}
      </div>
    </div>
    <!-- Member Name -->
    <div class="entitlement-row" *ngIf="memberData?.firstName || memberData?.lastName">
      <div i18n class="entitlement-row-title small-text bold uppercase">
        Member Name:
      </div>
      <div class="entitlement-row-text small-text">
        {{ memberData?.firstName || '' }}
        {{ memberData?.lastName || '' }}
      </div>
    </div>
    <!-- Member Level -->
    <div class="entitlement-row" *ngIf="memberData?.plusIndicator">
      <div i18n class="entitlement-row-title small-text bold uppercase">
        Member Level:
      </div>
      <div class="entitlement-row-text small-text">
        {{ getPlusIndicatorName(memberData?.plusIndicator) || ''}}
      </div>
    </div>
  </div>
</ng-container>
