<div class="progress-bar">
  <div class="square-tiles"></div>
</div>
<div class="dashboard_main">
  <section class="section-container">
    <header class="background-primary p-20">
      <h3 class="color-white"><em class="font-size-larger">Thank you</em><br>for submitting your request!</h3>
    </header>
    <div class="bottom-drawer-container">
      <div class="container-finding-provider">
        <div class="eta-container">
          <h3>Your call has <br>been received.</h3>
          <app-eta-label></app-eta-label>
        </div>
        <p class="mb-20">Waiting for an available service truck.</p>
        <app-progress-bar></app-progress-bar>
      </div>
      <app-informative-content></app-informative-content>
      <div class="container-finding-provider">
        <app-service-summary-improvement></app-service-summary-improvement>
      </div>
      <div class="container-finding-provider">
        <p class="small-text color-darkgrey mb-10">*ETA is only an estimate. A real time ETA will be provided once your service provider is in route.</p>
        <ng-container *ngIf="advisories$ | async as advisories">
          <ng-container *ngIf="!(isRapUser$ | async)">
            <app-status-update *ngFor="let advisory of advisories"
              [update]="advisory"></app-status-update>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </section>
</div>
