import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { AAAStore } from '../../../store/root-reducer'
import { select, Store } from '@ngrx/store'
import { AbstractComponent } from '../../../shared/abstract.component'
import { requestVehicleByPlate, resetVehicleByPlate, setVehicleByPlateColor } from '../vehicle.actions'
import { selectVehiclesByPlate } from '../vehicle.selectors'
import { EditService } from '../../../shared/services/edit.service'
import { Observable } from 'rxjs'
import { FormGroupState } from 'ngrx-forms'
import { PlateToVinFormState, setResetPlateToVinForm } from '../../ui/forms/forms.reducer'
import { selectPlateToVinForm } from '../../ui/forms/forms.selectors'
import { AdobeEventService } from '../../tagging/adobe/event-adobe.service'
import { AdobeEventTypes } from '../../tagging/tagging.types'
import events from '../../tagging/events'
import { selectMemberVehicles } from '../../member/member.selectors'

enum VehicleLoadStatus {
  NONE,
  FOUND,
  NOT_FOUND,
}

@Component({
  selector: 'app-add-plate',
  templateUrl: './add-plate.component.html',
  styleUrls: ['./add-plate.component.scss'],
})
export class AddPlateComponent extends AbstractComponent implements OnInit, OnDestroy {
  @ViewChild('vehicleColor') vehicleColor: any;

  form$: Observable<FormGroupState<PlateToVinFormState>> =
    this.store$.pipe(select(selectPlateToVinForm))
  form: FormGroupState<PlateToVinFormState>
  submitPending = false
  displayError = false
  displayAddVehicle = false

  memberVehicles$ = this.store$.pipe(select(selectMemberVehicles))

  vehiclesByPlate$ = this.store$.select(selectVehiclesByPlate)
  vehicleLoadStatus = VehicleLoadStatus.NONE

  state: string
  errorState = false

  color: string
  errorColor = false


  constructor(
    private store$: Store<AAAStore>,
    public editService: EditService,
    private adobeEventService: AdobeEventService
  ) {
    super()
  }

  ngOnInit() {
    this.store$.dispatch(resetVehicleByPlate())
    this.subscriptions.push(
      this.form$.subscribe((form) => {
        this.form = form
        if (form.value.state) {
          this.state = form.value.state
        }
        if (form.value.color) {
          this.color = form.value.color
        }
      }),
      this.vehiclesByPlate$.subscribe((vehicles) => {
        // note before the first request, vehicles will be null
        this.vehicleLoadStatus = Boolean(vehicles?.length) ? VehicleLoadStatus.FOUND : VehicleLoadStatus.NOT_FOUND
        if (this.submitPending) {
          this.handleAddVehicle()
        }
      })
    )
  }

  ngOnDestroy() {
    super.ngOnDestroy()
    this.clearForm()
  }

  setState(state: string) {
    this.state = state
    this.errorState = false
    this.searchVehicle()
    this.vehicleColor.ngSelect.focus()
  }

  setColor(color: string) {
    this.color = color
    this.errorColor = false
  }

  searchVehicle() {
    this.vehicleLoadStatus = VehicleLoadStatus.NONE
    this.hideError()
    if (this.form.controls.licensePlate.isValid && this.form.controls.state.isValid) {
      const licensePlate = this.form.value.licensePlate.replace(/\s/g, '').toUpperCase()
      const state = this.form.value.state
      this.store$.dispatch(requestVehicleByPlate({
        payload: {
          tag: licensePlate,
          state,
        }
      }))
    }
  }

  handleAddVehicle() {
    if (this.validateForm() && this.form.isValid) {
      if (this.vehicleLoadStatus !== VehicleLoadStatus.NONE) {
        this.addVehicle()
      } else {
        this.submitPending = true
      }
    }
  }

  addVehicle() {
    this.submitPending = false
    this.displayAddVehicle = true
    if (this.vehicleLoadStatus === VehicleLoadStatus.NOT_FOUND) {
      this.showError()
    } else {
      this.store$.dispatch(setVehicleByPlateColor({payload: {color: this.color}}))
      this.editService.editVehicleSection(false, '', 'confirm')
    }
  }

  errorTimeout
  showError() {
    clearTimeout(this.errorTimeout);
    this.displayError = true
    this.errorTimeout = setTimeout(() => this.hideError(), 5000)
  }

  hideError() {
    this.displayError = false
  }

  validateForm() {
    if (!this.state) {
      this.errorState = true
    }
    if (!this.color) {
      this.errorColor = true
    }
    return !this.errorState && !this.errorColor
  }

  clearForm() {
    this.state = ''
    this.color = ''
    this.store$.dispatch(setResetPlateToVinForm())
  }

  handleDontKnowLicensePlate() {
    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: events.vehicle.PLATE_TO_VIN_DONT_KNOW_LICENSE
    })
    this.clearVehicleAndGoToYears()
  }

  clearVehicleAndGoToYears() {
    this.store$.dispatch(resetVehicleByPlate())
    this.editService.editVehicleSection(false, '', 'years')
    this.clearForm()
  }
}
