import { animate, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AAAStore } from '../../../store/root-reducer';
import events from '../../tagging/events';
import { TaggingService } from '../../tagging/tagging.service';
import { setProblemTooltip } from '../../ui/ui.actions';
import { selectProblemTooltip } from '../../ui/ui.selectors';
import { PaceSetterSituation } from '../issue.types';

const TOOLTIP_DISPLAY_TIME_MS = 20000

@Component({
  selector: 'app-problem-tooltip',
  templateUrl: './problem-tooltip.component.html',
  styleUrls: ['./problem-tooltip.component.scss'],
  animations: [
    trigger('slideInAnimation', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({'max-height':0, opacity: 0}),
        animate(500, style({'max-height':100, opacity: 1}))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(500, style({'max-height':0}))
      ])
    ])
  ],
})
export class ProblemTooltipComponent implements OnInit {
  @Output() selectIssue: EventEmitter<PaceSetterSituation> = new EventEmitter<PaceSetterSituation>()

  problemType: PaceSetterSituation = null
  problemTooltip$: Observable<PaceSetterSituation | null> = this.store$.pipe(
    select(selectProblemTooltip)
  )

  fadeTimeoutId

  constructor(
    private store$: Store<AAAStore>,
    private eRef: ElementRef,
    private taggingService: TaggingService,
  ) {
    this.problemTooltip$.subscribe(
      (problemType) => {
        if (problemType && this.problemType !== problemType) {
          clearTimeout(this.fadeTimeoutId)
        }
        this.problemType = problemType
        if (problemType) {
          this.fadeTimeoutId = setTimeout(() => this.store$.dispatch(setProblemTooltip({ payload: null })), TOOLTIP_DISPLAY_TIME_MS)
        }
      }
    )
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(!this.eRef.nativeElement.contains(event.target)) {
      this.store$.dispatch(setProblemTooltip({ payload: null }))
    }
  }

  select() {
    this.selectIssue.emit(this.problemType)
    const action = this.problemType.name + events.issue.ISSUE_PAGE_NEXT_TOOLTIP_CLICK
    this.taggingService.setClickEvent(action, events.issue.ISSUE_PAGE_TYPE)

    // dispatch action to set the tooltip as null when the user selects an issue
    this.store$.dispatch(setProblemTooltip({ payload: null }))
  }

  ngOnInit(): void {
  }

}
