import { Inject, Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import {
  automatedEventAction,
  NOTIFY_AUTOMATED_ACTION,
  NOTIFY_CLICK_ACTION,
  NOTIFY_HOTJAR_ACTION,
  NOTIFY_NAVIGATION_ACTION,
  notifyEventActionFailure,
  notifyHotjarAction,
} from './tagging.actions'
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators'
import { ErrorReportingService } from '../../shared/services/error-reporting.service'
import { Store } from '@ngrx/store'
import { AAAStore } from '../../store/root-reducer'
import { selectActivePaceSetterSituationName } from '../issue/issue.selectors'
import { awaitHoistedFunction } from '../../shared/utils/awaitHoistedFunction'
import { selectAuthMethod, selectIsRapUser } from '../auth/auth.selectors'
import { AppId, AuthMethods } from '../auth/auth.types'
import { selectMembershipNumber } from '../member/member.selectors'
import { selectChannel, selectIsFirstTimeAccess } from '../ui/ui.selectors'
import { PACE_SETTER_SITUATION_TYPES_MAP_ANALYTICS } from '../issue/issue.types'
import {
  EVENT_ACTIONS,
  EVENT_TYPES,
  ExtraParams,
  GoogleAnalyticsParams,
  HotjarHitTypes,
  HotjarParams,
} from './tagging.types'
import { getCookie } from '../../shared/utils/cookies'
import { selectUserSessionId } from '../session/session.selectors'
import { HOTJAR_RECORD_EVENT_LIST } from './tagging.utils'
import { ROUTER_NAVIGATED } from '@ngrx/router-store'
import { ConfigService } from '../config/config.service'

export const defaultAttrs = {
  eventType: EVENT_TYPES.DRR,
  category: 'Automotive',
  appId: 'DRR',
  subCategory: EVENT_TYPES.DRR,
  'Event Detail': null,
}

export const mapAuthMethodValue = (method: AuthMethods) => {
  switch (method) {
    case AuthMethods.MEMBER_NAME:
      return 'Name'
    case AuthMethods.MEMBERSHIP_NUMBER:
      return 'Number'
    case AuthMethods.AAA_TOKEN:
      return 'Token'
    case AuthMethods.AAA_NATIONAL:
      return 'IdEts'
  }
}

@Injectable()
export class TaggingEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AAAStore>,
    private errorReportingService: ErrorReportingService,
    private configService: ConfigService,
  ) {}

  handleClickAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NOTIFY_CLICK_ACTION),
        withLatestFrom(
          this.store$.select(selectActivePaceSetterSituationName),
          this.store$.select(selectAuthMethod),
          this.store$.select(selectMembershipNumber),
          this.store$.select(selectIsFirstTimeAccess),
          this.store$.select(selectIsRapUser),
          this.store$.select(selectChannel),
          this.store$.select(selectUserSessionId),
        ),
        switchMap(([action, issueName, authMethod, memberNumber, firstTimeAccess, isRapUser, channel, sessionId]) =>
          this.transmitEvent(
            EVENT_ACTIONS.LINKCLICK,
            this.setExtraParamsOnEvent({
              issueName,
              authMethod,
              membershipNumber: memberNumber,
              event: action,
              isRapUser,
              channel,
              firstTimeAccess,
              sessionId
            })
          )
        ),
        catchError((error) =>
          this.errorReportingService.notifyErrorObservable(
            error,
            notifyEventActionFailure
          )
        )
      ),
    { dispatch: false }
  )

  handleNavigationAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NOTIFY_NAVIGATION_ACTION),
        withLatestFrom(
          this.store$.select(selectActivePaceSetterSituationName),
          this.store$.select(selectAuthMethod),
          this.store$.select(selectMembershipNumber),
          this.store$.select(selectIsFirstTimeAccess),
          this.store$.select(selectIsRapUser),
          this.store$.select(selectChannel),
          this.store$.select(selectUserSessionId),
        ),
        switchMap(([action, issueName, authMethod, memberNumber, firstTimeAccess, isRapUser, channel, sessionId]) =>
          this.transmitEvent(
            EVENT_ACTIONS.ITEMVIEW,
            this.setExtraParamsOnEvent({
              issueName,
              authMethod,
              membershipNumber: memberNumber,
              event: action,
              isRapUser,
              channel,
              firstTimeAccess,
              sessionId
            })
          )
        ),
        catchError((error) =>
          this.errorReportingService.notifyErrorObservable(
            error,
            notifyEventActionFailure
          )
        )
      ),
    { dispatch: false }
  )

  handleAutomatedAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NOTIFY_AUTOMATED_ACTION),
        filter(
          (action: ReturnType<typeof automatedEventAction>) =>
            !action.meta ||
            Boolean(action.meta.callIdentifier && action.meta.callStatus)
        ),
        withLatestFrom(
          this.store$.select(selectActivePaceSetterSituationName),
          this.store$.select(selectAuthMethod),
          this.store$.select(selectMembershipNumber),
          this.store$.select(selectIsFirstTimeAccess),
          this.store$.select(selectIsRapUser),
          this.store$.select(selectChannel),
          this.store$.select(selectUserSessionId),
        ),
        switchMap(([action, issueName, authMethod, memberNumber, firstTimeAccess, isRapUser, channel, sessionId]) =>
          this.transmitEvent(
            EVENT_ACTIONS.AUTOMATED,
            this.setExtraParamsOnEvent({
              issueName,
              authMethod,
              membershipNumber: memberNumber,
              event: action,
              isRapUser,
              channel,
              firstTimeAccess,
              sessionId
            })
          )
        ),
        catchError((error) =>
          this.errorReportingService.notifyErrorObservable(
            error,
            notifyEventActionFailure
          )
        )
      ),
    { dispatch: false }
  )

  handleHotjarSessionId$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROUTER_NAVIGATED),
        withLatestFrom(
          this.store$.select(selectUserSessionId),
          this.store$.select(selectMembershipNumber)
        ),
        map(([_, eventActionOrSessionId, membershipNumber], index) => {
          const isFirstNavigation = index === 0

          const payload = {
            eventActionOrSessionId,
            hitType: HotjarHitTypes.IDENTIFY,
            membershipNumber
          }

          if (isFirstNavigation) {
            this.transmitHotjarEvent(payload)
          }
        })),
      { dispatch: false }
  )

  handleHotjarAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NOTIFY_HOTJAR_ACTION),
        filter(
          (action: ReturnType<typeof notifyHotjarAction>) =>
            HOTJAR_RECORD_EVENT_LIST.includes(action.payload)
        ),
        map((action) => this.transmitHotjarEvent({ eventActionOrSessionId: action.payload }))
      ),
    { dispatch: false }
  )

  async transmitEvent(eventAction: EVENT_ACTIONS, extraParams: ExtraParams): Promise<void> {
    if (getCookie('AAA_AppId') === AppId.MONITOR) {
      return
    }
    const rapAppId = extraParams.rapAppId
      ? {
        appId: extraParams.rapAppId,
        rapChannel: extraParams.channel,
        eventType: EVENT_TYPES.DRRRAP,
        subCategory: EVENT_TYPES.DRRRAP,
      }
      : {}

    const data: GoogleAnalyticsParams = {
      ...defaultAttrs,
      ...extraParams.params,
      eventAction,
      action: extraParams.action,
      pageType: extraParams.pageType,
      ...rapAppId,
    }

    const error = new Error(
      `Malformed analytical event data: ${JSON.stringify(data)}`
    )
    if (!eventAction || !extraParams.action || !extraParams.pageType) {
      throw error
    }
    (window as any).lc_logEvent(data)
  }

  transmitHotjarEvent(params: HotjarParams): void {
    const payload: Array<string | {}> = [
      params.hitType = params.hitType ?? HotjarHitTypes.EVENT,
      params.eventActionOrSessionId
    ]

    if (params.membershipNumber) {
      payload.push({ membershipNumber: params.membershipNumber })
    }

    try {
      (window as any)?.hj(...payload)
      if (this.configService.getConfig().configTools) {
        console.log(`Hotjar: ${params.hitType} - ${params.eventActionOrSessionId}`,)
      }
    } catch (err) {
      console.log(err)
    }
  }

  private setExtraParamsOnEvent(
    {
      issueName,
      authMethod,
      membershipNumber,
      event,
      isRapUser,
      channel,
      firstTimeAccess,
      sessionId
    }: {
      issueName: string,
      authMethod: AuthMethods,
      membershipNumber: string,
      event,
      isRapUser: boolean,
      channel: string,
      firstTimeAccess: boolean,
      sessionId: string,
    }) {
    return {
      ...event.payload,
      params: {
        ...event.payload.params,
        ...(issueName ? { path: this.mapIssueName(issueName) } : {}),
        ...(authMethod
          ? { validationType: mapAuthMethodValue(authMethod) }
          : {}),
        ...(membershipNumber ? { memId: membershipNumber } : {}),
        ...(firstTimeAccess ? { firstTimeAccess: true } : {}),
        refid: sessionId
      },
      ...this.generateRapInfo(isRapUser, channel)
    }
  }

  private mapIssueName = (issueName: string) =>
    PACE_SETTER_SITUATION_TYPES_MAP_ANALYTICS[issueName]
      ? PACE_SETTER_SITUATION_TYPES_MAP_ANALYTICS[issueName]
      : issueName

  private generateRapInfo = (isRapUser, channel) => isRapUser
    ? {
      rapAppId: getCookie('AAA_AppId'),
      channel,
    }
    : {}
}

