<ion-row>
  <ion-col class="ion-margin-bottom">
    <aaa-input
      i18n-label
      #inputHelper
      variant="secondary"
      mask="membership_number"
      label="Membership Number"
      [ngrxFormControlState]="controls.membershipNumber"
      type="tel"
      autocomplete="on"
    >
      <app-form-helper
        helper
        i18n
        [control]="controls?.membershipNumber"
        name='membershipNumber'
        type='required'
      >
        Membership number is required.
      </app-form-helper>
      <app-form-helper
        helper
        i18n
        [control]="controls?.membershipNumber"
        name='membershipNumber'
        type='pattern'
      >
        Membership number must be exactly 16 characters long.
      </app-form-helper>
      <app-form-helper
        helper
        i18n
        [control]="controls?.membershipNumber"
        name='membershipNumber'
        type='checkNumber'
      >
        {{controls?.membershipNumber.errors['checkNumber']}}
      </app-form-helper>
    </aaa-input>
  </ion-col>
</ion-row>
<ion-row class="ion-margin-vertical extra-bottom-margin">
  <ion-col class="ion-margin-bottom">
    <app-input-postal-code
      id="zipCode"
      [controls]="controls"
      [focusInputHelper]="inputHelper"
    ></app-input-postal-code>
  </ion-col>
</ion-row>
