<section class="select-location">
  <div class="select-location__map-container"
    (click)="handleFindMyLocation()">
    <app-map [static]="true" view="BREAKDOWN_LOCATION"></app-map>
    <div class="select-location__overlay">
      <p class="select-location__text" i18n>Please, click <span>Allow</span> when the browser prompts for your current location.</p>
      <div *ngIf="getBrowserImage() as browserImage">
        <figure class="select-location__image"
          [ngClass]="browserImage.cssClass">
          <app-img [src]="browserImage.image" i18n-alt
            alt="Service Location"></app-img>
          <div class="select-location__border-hand">
            <icon-hand></icon-hand>
          </div>
        </figure>
      </div>
      <p class="select-location__text" ><ng-container i18n>It's the</ng-container> <span><em i18n>fast</em></span><ng-container i18n>way to access your precise location.</ng-container></p>
    </div>
  </div>
  <div class="select-location__buttons-container">
    <h2 id="find-your-location" i18n
      role="heading"
      class="select-location__heading"
      aria-level="2">Let's start by finding you and your vehicle:
    </h2>
    <app-button
      class="select-location__find-location-button"
      id="find-my-location"
      [disabled]="hasDeniedGpsAccess || isLoading"
      [loading]="isLoading"
      (buttonClick)="handleFindMyLocation()"
      [color]="'primary-yellow'"
      [hasShadow]="true"
      appCtaClickEvent="Find My Location"
      type="submit">
      <icon-locate color="#15284C"></icon-locate>
      <ng-container i18n>FIND MY LOCATION</ng-container>
    </app-button>
    <aria-link-button (clickLink)="handleEnterYourAddress()"
      [analyticData]="{ eventName: 'cta_click', eventValue: 'Enter Your Address' }"
      class="select-location__enter-address-link">
      <icon-magnifier scale="0.6"></icon-magnifier>
      <u i18n>Enter Your Address</u>
    </aria-link-button>
  </div>
</section>
