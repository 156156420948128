<div *ngIf="problems" class="problem-checklist">
  <div
    *ngIf="heading"
    class="problem-checklist_heading"
    (click)="toggleExpanded($event)"
  >
    <div *ngIf="allowCollapse" class="problem-checklist_plus-container">
      <div class="problem-checklist_minus"></div>
      <div
        class="problem-checklist_minus"
        [ngClass]="{ 'problem-checklist_plus': !open }"
      ></div>
    </div>
    <span>{{ heading }}</span>
  </div>
  <div
    class="problem-checklist_list"
    [ngClass]="{ 'problem-checklist_expanded': open }"
  >
    <div
      *ngFor="let problem of problems; let i = index"
      class="problem-checklist_item"
    >
      <!-- Radio (square) single -->
      <ng-container *ngIf="!multiSelect">
        <app-radio-square
          [name]="name"
          [required]="required"
          [value]="problem"
          [checked]="isSelected(problem)"
          (checkedChange)="problemChange(problem, $event)"
          [tabIndex]="tabIndexPosition + 1 + i"
          [first]="i === 0"
        >
          {{ problem.label || problem.name }}
          <div class="problem-checklist_obs" *ngIf="problem.obs">
            {{ problem.obs }}
          </div>
        </app-radio-square>
      </ng-container>
      <!-- Multi selects -->
      <ng-container *ngIf="multiSelect">
        <app-checkbox
          [name]="name"
          [value]="problem"
          [checked]="isSelected(problem)"
          (checkedChange)="problemChange(problem, $event)"
          [tabIndex]="tabIndexPosition + 1 + i"
          [first]="i === 0"
        >
          {{ problem.name }}
          <div class="problem-checklist_obs" *ngIf="problem.obs">
            {{ problem.obs }}
          </div>
        </app-checkbox>
      </ng-container>
    </div>
  </div>
</div>
