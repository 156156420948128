<section class="minimal-credentials no-shadow">
  <div class="minimal-credentials_membership-line"
       role="navigation"
       style="margin-top: 15px;"
       aria-labelledby="mobile-number">
    <label for="phoneNumber"
           id="mobile-phone">Phone Number of Primary Account Holder</label>
  </div>
  <input name="phoneNumber"
         id="phoneNumber"
         type="tel"
         [ngrxFormControlState]="controls.phoneNumber"
         placeholder="10 digit phone number"
         [mask]="phoneNumberMask"
         [showMaskTyped]="controls.phoneNumber.value"
         aria-required="true"
         autocomplete="tel-national"
         #phoneNumberInput
         required/>
  <app-form-error [control]="controls?.phoneNumber"
                  name='phoneNumber'
                  type='required'>
    Phone number is required.
  </app-form-error>
  <app-form-error [control]="controls?.phoneNumber"
                  name='phoneNumber'
                  type='pattern'>
    Phone number must be exactly 10 characters long.
  </app-form-error>

  <div class="minimal-credentials_postal-code-line">
    <label i18n for="zipCode">Home Postal Code</label>
    <app-input-zip-code id="zipCode" [controls]="controls" [focusInputHelper]="phoneNumberInputElem"></app-input-zip-code>
  </div>
</section>
