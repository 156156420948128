<ng-container *ngIf="isLicensePlateToVin$ | async; else default">
  <app-vehicle-tile
    [vehicle]="vehicle"
    (vehicleSelected)="vehicleClick()"
  >
  </app-vehicle-tile>
</ng-container>
<ng-template #default>
  <a
    role="button"
    *ngIf="vehicle"
    class="vehicle-button"
    [ngClass]="{
      'first-focusable-element': first
    }"
    (click)="vehicleClick()"
    [attr.aria-label]="'Select vehicle ' + (vehicle | vehicle)"
    [tabindex]="tabIndexPosition"
    triggerable-element
    [title]="vehicle | vehicle"
  >
    <div class="info-container">
      <div>{{ vehicle | vehicle }}</div>
      <div>
        {{ vehicle.color | titlecase }} {{ vehicle.wheelDrive ? ' | ' +
        vehicle.wheelDrive : '' }}
      </div>
    </div>
  </a>
</ng-template>

<div class="edit-container" *ngIf="!isEditing && (allowEditVehicle$ | async)">
  <aria-link-button
    class="edit-vehicle-link"
    role="button"
    [tabIndex]="tabIndexPosition"
    (clickLink)="toggleVehicleEditor()"
  >
    <div>
      <ng-container i18n>EDIT</ng-container>
      <app-img i18n-alt src="assets/drrweb-lib/images/icons/edit small white.svg" alt="Edit"></app-img>
    </div>
  </aria-link-button>
  <span>&nbsp;|&nbsp;</span>
  <aria-link-button
    class="delete-vehicle-link"
    role="button"
    [tabIndex]="tabIndexPosition"
    (clickLink)="deleteVehicle()"
  >
    <div>
      <ng-container i18n>DELETE</ng-container>
      <app-img i18n-alt src="assets/drrweb-lib/images/icons/close white.svg" classNames="delete" alt="Delete" ></app-img>
    </div>
  </aria-link-button>
</div>

<app-vehicle-edit-options
  *ngIf="isEditing"
  [vehicle]="vehicle"
  [isActive]="isActive"
  [tabIndexPosition]="tabIndexPosition"
  (clickCancel)="toggleVehicleEditor()"
></app-vehicle-edit-options>
