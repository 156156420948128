import { Component, OnInit } from '@angular/core'
import { MessageDialogTypes, Option, StepTypes, SubmitSections, } from '../../../ui/ui.types'
import { passengerOptions, Passengers } from '../../../submit/submit.reducer'
import { Observable } from 'rxjs'
import { select, Store } from '@ngrx/store'
import { AAAStore } from '../../../../store/root-reducer'
import { selectPassengersForm } from '../../../submit/submit.selectors'
import { Router } from '@angular/router'
import { WizardService } from '../../../wizard/wizard.service'
import { TaggingService } from '../../../tagging/tagging.service'
import { currentEditStepLocation } from '../../../wizard/wizard.selectors'
import { openMessageDialog } from '../../../ui/ui.actions'
import { FormGroupState } from 'ngrx-forms'
import { addPartialCallRequest } from '../../../dashboard/calls.actions'
import { AbstractResponsiveComponent } from '../../../../shared/abstract-responsive.component'
import events from '../../../tagging/events'
import { Title } from '@angular/platform-browser';
import { RapService } from '../../../rap/rap.service';
import { buildTitle } from '../../../../shared/utils/title';
import { AdobeEventTypes } from '../../../tagging/tagging.types'
import { AdobeEventService } from '../../../tagging/adobe/event-adobe.service'

const TITLE_PASSENGERS = () => $localize`Passengers`
const PASSENGER_TITLE_TRUCK_LIMIT = () => $localize`Truck capacity limited`
const PASSENGER_CONTENT_TRUCK_LIMIT = () => $localize`Trucks only have 2 seats available. In order to safely transport all of your passengers, please find other means of transportation.`

@Component({
  selector: 'app-indicate-passengers',
  templateUrl: './indicate-passengers.component.html',
  styleUrls: ['./indicate-passengers.component.scss'],
})
export class IndicatePassengersComponent
  extends AbstractResponsiveComponent
  implements OnInit {
  _passengerOptions: Option[] = passengerOptions()

  passengersForm: FormGroupState<Passengers> = null
  controls: any = {}

  currentEditing$: Observable<any> = this.store$.pipe(
    select(currentEditStepLocation)
  )

  currentUrl: string

  constructor(
    private store$: Store<AAAStore>,
    private router: Router,
    private wizardService: WizardService,
    private tagging: TaggingService,
    private titleService: Title,
    private rapService: RapService,
    private adobeEventService: AdobeEventService
  ) {
    super()
    this.titleService.setTitle(buildTitle(TITLE_PASSENGERS(), this.rapService.isRapUser()))
    this.tagging.setPageEvent(events.towTo.PASSENGERS_PAGE_PROMPT, events.towTo.PASSENGERS_PAGE_TYPE)
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store$.pipe(select(selectPassengersForm)).subscribe((form) => {
        this.passengersForm = form
        this.controls = this.passengersForm.controls
      }),
      this.currentEditing$.subscribe((url) => {
        this.currentUrl = url
      })
    )
  }

  updatePassengers(event) {
    // More than two passengers should trigger the message
    const count = parseInt(event.label.replace(/\D/g, ''), 10)
    if (count >= 3) {
      this.store$.dispatch(
        openMessageDialog({
          payload: {
            type: MessageDialogTypes.CUSTOM,
            title: PASSENGER_TITLE_TRUCK_LIMIT(),
            content: PASSENGER_CONTENT_TRUCK_LIMIT(),
          },
        })
      )
    }

    const _action = `${events.towTo.PASSENGERS_COUNT_SELECT}: ${event.value || event.label || 1}`
    this.tagging.setClickEvent(_action, events.towTo.PASSENGERS_PAGE_TYPE)
    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: _action
    })
  }

  onNext() {
    this.tagging.setClickEvent(
      events.towTo.PASSENGERS_NEXT_CLICK,
      events.towTo.PASSENGERS_PAGE_TYPE
    )

    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: events.towTo.PASSENGERS_NEXT_CLICK
    })

    // TODO: move these calls to "next" click effects
    if (this.passengersForm.isValid) {
      if (!this.currentUrl) {
        this.store$.dispatch(addPartialCallRequest())
        return this.router.navigate([], {
          queryParams: {
            step: StepTypes.SUBMIT,
            section: SubmitSections.SUMMARY,
          },
        })
      }

      this.wizardService.backToEditUrl(this.currentUrl, true)
    }
  }
}
