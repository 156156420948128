<ng-container *ngIf="isOpen">
  <div class="fullscreen-adjust-location"
    [ngClass]="{ 'desktop-version': !isMobile && popupMode, 'no-popup': !popupMode }">
    <div class="header" *ngIf="popupMode">
      <div class="title">
        {{ headingTitle }}
      </div>
      <button class="close-button" aria-label="close" (click)="closeMapModal()">
        <icon-close-blue></icon-close-blue>
      </button>
    </div>

    <div class="full-map">
      <app-map
        [tabIndexPosition]="2500"
        [userLocation]="userCoords"
        [breakdownMarker]="breakdownMarker$ | async"
        [facilitiesDisplay]="facilitiesDisplay"
        [towDestinationMarker]="selectedShop"
        [center]="location"
        [view]="view"
        (mapDrag)="handleMapDrag($event)"
        [preventTouch]="false"
        [showActionButtons]="showSearchArea || needMoreInfoBlocked"
        (searchAreaClick)="handleSearchArea($event)"
        [isLoading]="isLoadingSearchArea$ | async"
        [showSelectedTowDestination]="isMobile"
        [enableTowDestination]="true"
        [breakdownMarkerRender]="breakdownMarkerRender"
        [blockOverlay]="needMoreInfoBlocked"
        [hasDeniedGpsAccess]="hasDeniedGpsAccess$ | async"
        [isValidBreakdownLocation]="false"
        (adjustPinClick)="adjustPin()"
        (useCurrentLocation)="findMyLocation()"
        (onShopDetailsClose)="closeShopDetails()"
      >
      </app-map>
      <div class="full-map_top-message" *ngIf="expandedResults">
        <div i18n class="small-text" tabindex="-1">
          No facilities were found within the requested area, displaying the closest ones.
        </div>
      </div>
      <ng-container *ngIf="needMoreInfoBlocked">
        <div class="full-map_need-more-info">
          <div i18n class="small-text" tabindex="-1">
            We need more information to help find you.
          </div>
        </div>
      </ng-container>
    </div>

    <ng-container *ngIf="view === 'TOWING_DESTINATION' && !isRapUser && !(isEVstation$ | async)">
      <div class="pin-legend__container">
        <div class="pin-legend__item">
          <icon-aar-destination-pin></icon-aar-destination-pin>
          <span i18n>AAA Approved Facility</span>
        </div>
        <div class="pin-legend__item">
          <icon-aar-destination-pin [isCOR]="true"></icon-aar-destination-pin>
          <span i18n>AAA Owned Facility</span>
        </div>
      </div>
    </ng-container>

    <div class="footer" *ngIf="popupMode">
      <div class="address" *ngIf="view === 'BREAKDOWN_LOCATION'">
        <ng-container *ngIf="loading || (isSetBreakdownLocationLoading$ | async); else address">
          <app-loading
            width="25"
            height="25"
            class="typeahead_icon-begin"
          ></app-loading>
        </ng-container>
        <ng-template #address>
          <p class="address_line1">
            {{ addressLine1 }}
          </p>
          <p class="address_line2">
            {{ addressLine2 }}
          </p>
        </ng-template>
      </div>

      <div class="done-button">
        <app-button (buttonClick)="breakdownConfirm()"
          *ngIf="view === 'BREAKDOWN_LOCATION'"
          [disabled]="isConfirmLocationDisabled() || loading || (isSetBreakdownLocationLoading$ | async)">
          {{ confirmLocationLabel }}
        </app-button>
      </div>

      <ng-container *ngIf="view === 'TOWING_DESTINATION'">
        <ng-container *ngIf="!displaySelectedTowLocation; else selectFacility">
          <app-button (buttonClick)="closeMapModal()">
            <i class="fa-solid fa-list left-icon-button"></i>
            {{ listButtonLabel }}
          </app-button>
        </ng-container>

        <ng-template #selectFacility>
          <app-button i18n
            (buttonClick)="assignAarLocation()">
            Select this facility
          </app-button>
        </ng-template>
      </ng-container>
    </div>
  </div>

  <ng-container *ngIf="!isMobile && popupMode">
    <div class="backdrop"></div>
  </ng-container>
</ng-container>
