import { createFeatureSelector, createSelector } from '@ngrx/store'
import { AdvisoriesState } from './advisories.reducer'
import { Advisory } from './advisories.types'

const stateKey = 'advisories'

export const selectAdvisoriesState = createFeatureSelector<AdvisoriesState>(stateKey)

export const selectHasSeenClubAdvisory = createSelector(
  selectAdvisoriesState,
  (state): boolean => state.hasSeenAdvisories
)

export const selectAdvisories = createSelector(
  selectAdvisoriesState,
  (state): Array<Advisory> => state.advisories
)
