import { Component } from '@angular/core';

@Component({
  selector: 'app-informative-content',
  templateUrl: './informative-content.component.html',
  styleUrls: ['./informative-content.component.scss']
})
export class InformativeContentComponent {

  title = `Don't worry, we're on it!`
  description = `If you’ve opted-in, 
    we’ll send you TEXT MESSAGE updates including when 
    a service provider has been assigned and is in route 
    to your location.`

  constructor() { }

}
