<span title class="normal-text details-title">
  <span role="heading" aria-level="1" class="bold" i18n>Location details (optional)</span>
</span>
<div body>
  <form>
    <ng-container *ngFor="let list of optionTemplates">
      <app-problem-checklist
        [name]="list?.name"
        [multiSelect]="list?.multiSelect"
        [selectedItems]="details[list?.name]"
        [problems]="list?.values"
        [allowCollapse]="false"
        [open]="true"
        (selectionChanged)="detailSelectionChanged(list?.name, $event)"
        [tabIndexPosition]="tabIndexPosition + 1"
      ></app-problem-checklist>
    </ng-container>
  </form>
</div>
