import { Component, OnInit, OnDestroy } from '@angular/core'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { Observable } from 'rxjs'
import { Store } from '@ngrx/store'

import { AAAStore } from '../../../store/root-reducer'
import { ConfirmMessageDialogComponent } from './message/confirm-message-dialog.component'
import { CustomMessageDialogComponent } from './message/custom-message-dialog.component'
import {
  Dialog,
  ErrorDialogTypes,
  MessageDialogTypes,
  PromptDialogTypes,
} from '../ui.types'
import { GenericErrorDialogComponent } from './errors/generic-error-dialog.component'
import { requestVehicleDelete } from '../../member/member.actions'
import { closeDialog } from '../ui.actions'
import { AbstractComponent } from '../../../shared/abstract.component'
import { CantRememberIdDialogComponent } from './message/cant-remember-id-dialog.component'
import { NotAMemberDialogComponent } from './message/not-a-member-dialog.component'
import { NotifyPoliceDialogComponent } from './message/notify-police-dialog.component'
import { LocationServicesRequiredComponent } from './message/location-services-required.component'
import { AarDetailsComponent } from './prompts/aar-details.component'
import { MembershipIssuesDialogComponent } from './errors/membership-issues-dialog.component'
import { MemberNoMatchesDialogComponent } from './errors/member-no-matches.component'
import { selectActiveDialogsByTime } from '../ui.selectors'
import { take } from 'rxjs/operators'
import { CallCancelDialogComponent } from './message/call-cancel.component'
import { CallCompletedDialogComponent } from './message/call-completed.component'
import { CallDuplicateDialogComponent } from './message/call-duplicate-dialog.component'
import { ComponentType } from '@angular/cdk/portal'
import { AuthAdditionalDataComponent } from './prompts/auth-additional-info/auth-additional-info.component'
import { TowDistanceWarningDialogComponent } from './message/tow-distance-warning-dialog.component'
import { confirmTowDistance } from '../../location/tow-location/tow-location.actions'
import { TowLocationAddressWarningDialogComponent } from './message/tow-location-address-warning-dialog.component'
import { BatteryQuotesDialogComponent } from './prompts/battery-quotes-dialog.component'
import { ShowBatteryQuotesDialogComponent } from './prompts/show-battery-quotes-dialog.component'
import { TowBoundaryMessageDialogComponent } from './message/tow-boundary-message-dialog.component'
import { IframeDialogComponent } from './prompts/iframe-dialog.component'
import { SessionTimeoutDialogComponent } from './message/session-timeout-dialog.component'
import { SameAddressTowBlDialogComponent } from './message/same-address-tow-bl-dialog.component'
import { InvalidMembershipComponent } from './errors/invalid-membership.component'
import { LocationAdditionalInfoComponent } from './prompts/location-additional-info/location-additional-info.component'
import { AdvisoryDialogComponent } from './message/advisories-dialog.component'
import { VinIssuesDialogComponent } from './errors/vin-issues-dialog.component'
import { RentalIssuesDialogComponent } from './errors/rental-issues-dialog.component'
import { VasIssuesDialogComponent } from './errors/vas-issues-dialog.component'
import { RapNotEligibleDialogComponent } from './errors/rap-not-eligible-dialog.component'
import { RapTowMileageMessageDialogComponent } from './message/rap-tow-mileage-message-dialog.component'
import { CallRequestIssueDialogComponent } from './errors/call-request-issue-dialog.component'
import { CustomPhoneMessageDialogComponent } from './message/custom-phone-message-dialog.component'
import { GpsDistanceWarningDialogComponent } from './message/gps-distance-warning-dialog.component'
import { AuthAdditionalInfoSearchComponent } from './prompts/auth-additional-info-search/auth-additional-info-search.component'
import { SpecialAssistanceDialogComponent } from './prompts/special-assistance/special-assistance.component'
import { PartialCallCancelDialogComponent } from './message/partial-call-cancel.component'
import { ShopDetailsComponent } from './prompts/shop-details/shop-details.component'
import { AuthPhoneNumberComponent } from './prompts/auth-phone-number/auth-phone-number.component'
import { CancelFeedbackDialogComponent } from './prompts/cancel-feedback/cancel-feedback.component'
import { CancelFeedbackOptionComponent } from './errors/cancel-feedback-option.component'
import { I18nSwitcherComponent } from './prompts/i18n-switcher/i18n-switcher.component'
import { CloseRequestDialogComponent } from './message/close-request-dialog.component'

export type DialogComponent =
  | GenericErrorDialogComponent
  | CustomMessageDialogComponent
  | CustomPhoneMessageDialogComponent
  | ConfirmMessageDialogComponent
  | CloseRequestDialogComponent
  | CantRememberIdDialogComponent
  | LocationServicesRequiredComponent
  | AarDetailsComponent
  | ShopDetailsComponent
  | NotAMemberDialogComponent
  | MemberNoMatchesDialogComponent
  | CallCancelDialogComponent
  | CallCompletedDialogComponent
  | CallDuplicateDialogComponent
  | AuthAdditionalDataComponent
  | BatteryQuotesDialogComponent
  | ShowBatteryQuotesDialogComponent
  | TowBoundaryMessageDialogComponent
  | IframeDialogComponent
  | SessionTimeoutDialogComponent
  | SameAddressTowBlDialogComponent
  | InvalidMembershipComponent
  | AdvisoryDialogComponent
  | LocationAdditionalInfoComponent
  | SpecialAssistanceDialogComponent
  | GpsDistanceWarningDialogComponent
  | AuthAdditionalInfoSearchComponent
  | AuthPhoneNumberComponent
  | CancelFeedbackDialogComponent
  | CancelFeedbackOptionComponent

@Component({
  selector: 'app-dialog-invoker',
  templateUrl: './dialog-invoker.component.html',
})
export class DialogInvokerComponent
  extends AbstractComponent
  implements OnInit, OnDestroy {
  activeDialogs$: Observable<Array<Dialog>> = this.store$.select(
    selectActiveDialogsByTime
  )
  dialogRef?: MatDialogRef<DialogComponent>
  _dialog?: Dialog
  onOpenMap = {
    [ErrorDialogTypes.AUTH_OEM]: (dialog) =>
      this.onOpen(VinIssuesDialogComponent, dialog),
    [ErrorDialogTypes.AUTH_VAS]: (dialog) =>
      this.onOpen(VasIssuesDialogComponent, dialog),
    [ErrorDialogTypes.AUTH_RENTAL]: (dialog) =>
      this.onOpen(RentalIssuesDialogComponent, dialog),
    [ErrorDialogTypes.AUTH]: (dialog) =>
      this.onOpen(MembershipIssuesDialogComponent, dialog),
    [ErrorDialogTypes.GENERIC]: (dialog) =>
      this.onOpen(GenericErrorDialogComponent, dialog),
    [ErrorDialogTypes.MEMBER_NO_MATCHES]: (dialog) =>
      this.onOpen(MemberNoMatchesDialogComponent, dialog),
    [ErrorDialogTypes.NOT_ELIGIBLE]: (dialog) =>
      this.onOpen(RapNotEligibleDialogComponent, dialog),
    [ErrorDialogTypes.CALL_CANCEL]: (dialog) =>
      this.onOpen(CallRequestIssueDialogComponent, dialog),
    [ErrorDialogTypes.CANCEL_FEEDBACK_OPTION]: (dialog) =>
      this.onOpen(CancelFeedbackOptionComponent, dialog),
    [MessageDialogTypes.CUSTOM]: (dialog) =>
      this.onOpen(CustomMessageDialogComponent, dialog),
    [MessageDialogTypes.CUSTOM_PHONE]: (dialog) =>
      this.onOpen(CustomPhoneMessageDialogComponent, dialog),
    [MessageDialogTypes.CANT_REMEMBER_MY_ID]: (dialog) =>
      this.onOpen(CantRememberIdDialogComponent, dialog),
    [MessageDialogTypes.NOT_MEMBER]: (dialog) =>
      this.onOpen(NotAMemberDialogComponent, dialog),
    [MessageDialogTypes.NOTIFY_POLICE]: (dialog) =>
      this.onOpen(NotifyPoliceDialogComponent, dialog),
    [MessageDialogTypes.LOCATION_SERVICES_REQUIRED]: (dialog) =>
      this.onOpen(LocationServicesRequiredComponent, dialog),
    [MessageDialogTypes.PARTIAL_CALL_CANCELED]: (dialog) =>
      this.onOpen(PartialCallCancelDialogComponent, dialog),
    [MessageDialogTypes.CALL_CANCELLED]: (dialog) =>
      this.onOpen(CallCancelDialogComponent, dialog),
    [MessageDialogTypes.CALL_COMPLETED]: (dialog) =>
      this.onOpen(CallCompletedDialogComponent, dialog),
    [MessageDialogTypes.CALL_DUPLICATE]: (dialog) =>
      this.onOpen(CallDuplicateDialogComponent, dialog),
    [MessageDialogTypes.TOWING_DISTANCE_LIMIT]: (dialog) =>
      this.onOpen(TowDistanceWarningDialogComponent, dialog),
    [MessageDialogTypes.TOW_LOCATION_ADDRESS]: (dialog) =>
      this.onOpen(TowLocationAddressWarningDialogComponent, dialog),
    [MessageDialogTypes.SESSION_TIMEOUT]: (dialog) =>
      this.onOpen(SessionTimeoutDialogComponent, dialog),
    [MessageDialogTypes.SAME_ADDRESS_TOW_BL]: (dialog) =>
      this.onOpen(SameAddressTowBlDialogComponent, dialog),
    [MessageDialogTypes.GPS_LOCATION_DISTANCE_WARNING]: (dialog) =>
      this.onOpen(GpsDistanceWarningDialogComponent, dialog, () => {
        if(dialog.submit) {
          dialog.submit()
        }
      }),
    [MessageDialogTypes.TOW_BOUNDARY_MESSAGE]: (dialog) =>
      this.onOpen(TowBoundaryMessageDialogComponent, dialog),
    [MessageDialogTypes.RAP_TOW_MILEAGE_MESSAGE]: (dialog) =>
      this.onOpen(RapTowMileageMessageDialogComponent, dialog),
    [MessageDialogTypes.INVALID_MEMBERSHIP]: (dialog) =>
      this.onOpen(InvalidMembershipComponent, {
        ...dialog,
        disableClose: true,
      }),
    [MessageDialogTypes.ADVISORY]: (dialog) =>
      this.onOpen(AdvisoryDialogComponent, dialog),
    [PromptDialogTypes.CLOSE_REQUEST_DIALOG]: (dialog) =>
      this.onOpen(CloseRequestDialogComponent, dialog, () => {
        if (dialog.submit) {
          dialog.submit()
        }
      }),
    [PromptDialogTypes.AAR_DETAILS]: (dialog) =>
      this.onOpen(AarDetailsComponent, dialog),
    [PromptDialogTypes.SHOP_DETAILS]: (dialog) =>
      this.onOpen(ShopDetailsComponent, dialog),
    [PromptDialogTypes.CONFIRM_VEHICLE_DELETE]:
      this.onOpenVehicleDeleteConfirmMessage,
    [PromptDialogTypes.TOWING_DISTANCE_WARN]:
      this.onOpenTowingDistanceWarningMessage,
    [PromptDialogTypes.ADDITIONAL_AUTH_INFO]: (dialog) =>
      this.onOpen(AuthAdditionalDataComponent, dialog),
    [PromptDialogTypes.ADDITIONAL_AUTH_SEARCH]: (dialog) =>
      this.onOpen(AuthAdditionalInfoSearchComponent, dialog),
    [PromptDialogTypes.BATTERY_QUOTES]: this.onOpenBatteryQuotesDialog,
    [PromptDialogTypes.SHOW_BATTERY_QUOTES]: (dialog) =>
      this.onOpen(ShowBatteryQuotesDialogComponent, dialog),
    [PromptDialogTypes.IFRAME_DIALOG]: (dialog) =>
      this.onOpen(IframeDialogComponent, dialog),
    [PromptDialogTypes.ADDITIONAL_LOCATION_INFO]: (dialog) =>
      this.onOpen(LocationAdditionalInfoComponent, dialog),
    [PromptDialogTypes.SPECIAL_ASSISTANCE_DIALOG]: (dialog) =>
      this.onOpen(SpecialAssistanceDialogComponent, dialog),
    [PromptDialogTypes.AUTH_PHONE_NUMBER_DIALOG]: (dialog) =>
      this.onOpen(AuthPhoneNumberComponent, dialog),
    [PromptDialogTypes.CANCEL_FEEDBACK_PROMPT]: (dialog) =>
      this.onOpen(CancelFeedbackDialogComponent, dialog),
    [PromptDialogTypes.I18N_SWITCHER]: (dialog) =>
        this.onOpen(I18nSwitcherComponent, dialog),
  }

  constructor(
    private store$: Store<AAAStore>,
    public dialogService: MatDialog
  ) {
    super()
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.activeDialogs$.subscribe((stateDialogs: Array<Dialog>) => {
        const pendingDialogs = stateDialogs.filter(
          (stateDialog) =>
            !this.dialogService.openDialogs.some(
              (activeDialog) =>
                activeDialog.componentInstance.data.type === stateDialog.type
            )
        )
        const closingDialogs = this.dialogService.openDialogs.filter(
          (activeDialog) =>
            !stateDialogs.some(
              (stateDialog) =>
                activeDialog.componentInstance.data.type === stateDialog.type
            )
        )

        if (closingDialogs.length > 0) {
          closingDialogs.map((closingDialog) =>
            closingDialog.componentInstance.data.close()
          )
        }

        if (pendingDialogs.length > 0) {
          pendingDialogs.map((pendingDialog) =>
            this.onOpenMap[pendingDialog.type].call(this, pendingDialog)
          )
        }
      })
    )
  }

  ngOnDestroy(): void {
    super.ngOnDestroy()
    this.dialogRef = null
    this._dialog = null
  }

  onOpen(
    dialogComponent: ComponentType<DialogComponent>,
    dialog?: Dialog,
    onSubmit?: () => void
  ) {
    this._dialog = dialog
    this.dialogRef = this.dialogService.open<DialogComponent, Dialog>(dialogComponent, {
      disableClose: dialog.disableClose || false,
      panelClass: dialog.panelClass || null,
      role: 'alertdialog',
      autoFocus: false,
      restoreFocus: false,
      data: {
        type: dialog.type,
        title: dialog.title,
        content: dialog.content,
        params: dialog.params || {},
        submit: onSubmit,
        close: () => this.onClose(dialog.type),
        closeLabel: dialog.closeLabel,
        isCallAaaButtonShown: dialog?.isCallAaaButtonShown,
      },
    })
    this.dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => this.store$.dispatch(closeDialog({ payload: dialog })))

    this.dialogRef
      .afterOpened()
      .subscribe((e) => {
        const dialogId = this.dialogRef.id;
        const _element = document.getElementById(dialogId);

        if (_element) {
          _element.setAttribute('aria-live', 'assertive')
        }
      })
  }
  onOpenVehicleDeleteConfirmMessage(dialogState?: Dialog) {
    this.onOpen(ConfirmMessageDialogComponent, dialogState, () =>
      this.store$.dispatch(
        requestVehicleDelete({ payload: dialogState.params })
      )
    )
  }

  onOpenTowingDistanceWarningMessage(dialogState?: Dialog) {
    this.onOpen(ConfirmMessageDialogComponent, dialogState, () =>
      this.store$.dispatch(confirmTowDistance())
    )
  }

  onOpenBatteryQuotesDialog(dialogState?: Dialog) {
    this.onOpen(BatteryQuotesDialogComponent, dialogState)
  }

  onClose(type: ErrorDialogTypes | MessageDialogTypes | PromptDialogTypes) {
    this.dialogService.openDialogs
      .find(
        (activeDialog) => activeDialog.componentInstance?.data?.type === type
      )
      ?.close()
  }
}
