import { Component } from '@angular/core'
import { Store } from '@ngrx/store'
import { AAAStore } from '../../../store/root-reducer'
import { addPartialCallRequest } from '../../dashboard/calls.actions'
import { setPaceSetterSituation } from '../issue.actions'
import { PaceSetterSituation } from '../issue.types'
import events from '../../tagging/events'
import { TaggingService } from '../../tagging/tagging.service'
@Component({
  selector: 'app-what-happened',
  templateUrl: './what-happened.component.html',
  styleUrls: ['./what-happened.component.scss'],
})
export class WhatHappenedComponent {

  constructor(
    private store$: Store<AAAStore>,
    private taggingService: TaggingService,
  ) {}

  problemSelected(problem: PaceSetterSituation) {
    this.store$.dispatch(setPaceSetterSituation({ payload: problem }))
    this.store$.dispatch(addPartialCallRequest())

    this.taggingService.setClickEvent(events.issue.ISSUE_PAGE_NEXT_CLICK, events.issue.ISSUE_PAGE_TYPE)
  }

  set911LinkClick() {
    this.taggingService.setClickEvent('Call 911', events.issue.ISSUE_PAGE_TYPE)
  }
}
