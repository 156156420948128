import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-member-name',
  templateUrl: './member-name.component.html',
  styleUrls: ['../minimal-credentials/minimal-credentials.component.scss'],
})
export class MemberNameComponent {
  @Input() controls
  @Output() useMembershipNumberEmitter: EventEmitter<any> = new EventEmitter<any>()
  @ViewChild('firstNameInput') firstNameInputElem: ElementRef<HTMLInputElement>

  constructor() {}

  setUseMembershipNumber(event) {
    this.useMembershipNumberEmitter.emit(event)
  }
}
