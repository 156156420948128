import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { membershipMask } from '../minimal-credentials/minimal-credentails.constants';

@Component({
  selector: 'app-membership-number',
  templateUrl: './membership-number.component.html',
  styleUrls: ['../minimal-credentials/minimal-credentials.component.scss'],
})
export class MembershipNumberComponent {
  @Input() controls
  @Output() useMemberNameEmitter: EventEmitter<any> = new EventEmitter<any>()
  @ViewChild('membershipNumberInput') membershipNumberInputElem: ElementRef<HTMLInputElement>

  mask = membershipMask

  constructor() {}

  setUseMemberName(event) {
    this.useMemberNameEmitter.emit(event)
  }
}
