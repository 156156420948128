<button
  type="button"
  class="borderless-button"
  (click)="handleSummaryClick($event)"
  *ngIf="location"
  [tabIndex]="tabIndex"
>

  <div class="row">
    <div class="name-address">
      <div class="normal-text bold">{{ location.name }}</div>
    </div>
    <div class="small-text distance-to"><app-distance-display [distance]="location.distanceTo" [short]="true"></app-distance-display></div>
  </div>

  <div class="row">
    <ng-container *ngIf="location.ratingSummary?.reviewCount > 0 && location.ratingSummary?.ratingAvg >= 3.5">
      <div class="rating-summary">
        <span class="small-text">{{ location.ratingSummary.ratingAvg | number:'1.0-1' }}</span>
        <div class="rating">
          <app-rating [rate]="location.ratingSummary.ratingAvg"></app-rating>
        </div>
        <span class="small-text">({{ location.ratingSummary.reviewCount }})️</span>
      </div>
    </ng-container>

    <ng-container *ngIf="location.priority">
      <div i18n class="small-text immediately-available">Immediately Available</div>
    </ng-container>
  </div>

  <ng-container *ngIf="showAaaDiscount">
    <div class="row">
      <span class="small-text aaa-discount">
        <i class="fa-solid fa-tag li-icon"></i> <ng-container i18n>10% Off (up to $75)</ng-container>
      </span>
      <span class="promotion-check">
        <icon-check></icon-check>
      </span>
    </div>
  </ng-container>

  <div class="small-text full-address">
    <i class="fa-solid fa-location-dot li-icon"></i>
    {{ location.address.addressLine | titlecase }}, {{ location.address.cityName | titlecase }} {{ location.address.stateProv.code }} {{ location.address.postalCode }}
  </div>

  <div class="row">
    <ng-container *ngIf="location.operatingDays?.length > 0">
      <div class="small-text operating-hours">
        <i class="fa-solid fa-clock li-icon"></i>
        <app-facility-status class="facility-status" [operatingDays]="location.operatingDays"></app-facility-status>
        <app-facility-next-date class="facility-next-date" [operatingDays]="location.operatingDays"></app-facility-next-date>
      </div>
    </ng-container>
    <div class="small-text phone" *ngIf="location.phone?.value as phone">
      <i class="fa-solid fa-phone li-icon"></i>
      <a i18n-aria-label [href]="(phone | phoneConverter: 'href')" appCtaClickEvent="Call Shop" aria-label="Call Shop">
        <span class="phone-number">{{ phone }}</span>
      </a>
    </div>
  </div>

</button>
