<app-select
  [elementId]="fieldName + '-select'"
  [placeholder]="seeOtherLabel() + ' ' + fieldName"
  [items$]="internalItems$ "
  [searchable]="true"
  [dropdownPosition]="dropdownPosition"
  (selectionMade)="handleChange($event)">
</app-select>

<div [hidden]="!otherSelected" class="select-or-text_other">
  <label [for]="fieldName + '-input'">
    <ng-container i18n>Enter your</ng-container> {{ fieldName }}
  </label>
  <input
    #otherInput
    [id]="fieldName + '-input'"
    type="text"
    [placeholder]="typeLabel() + ' ' + fieldName + '...'"
    [(ngModel)]="otherValue"
    [maxLength]="maxLength"
    (keyup)="handleKeyUpText($event)"
  />
</div>
