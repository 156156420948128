export default {
  LOCATION_PAGE_TYPE: 'Breakdown Location',
  LOCATION_PAGE_PROMPT: 'Breakdown location screen displayed',
  LOCATION_SEARCH_CLICK: 'Breakdown location search',
  LOCATION_DETAILS_SELECT: 'Set optional details',
  LOCATION_HOME_CLICK: 'Home breakdown location',
  LOCATION_CURRENT_CLICK: 'Current location',
  LOCATION_FIND_MY_LOCATION: 'Find My Location',
  LOCATION_DROP_PIN: 'Map location moved',
  LOCATION_CLEAR_INPUT: 'Clear input address',
  LOCATION_ZOOM_IN: 'Map zoom increased',
  LOCATION_ZOOM_OUT: 'Map zoom decreased',
  LOCATION_ADDITIONAL_INFO_PROMPT: 'Additional info required - Prompt',
  LOCATION_ADDITIONAL_INFO_CONFIRM_CLICK: 'Additional info required - Confirm',
  LOCATION_5_MILES_AWAY_COORDINATES: 'Location 5 miles away from user coordinates - Prompt',
  LOCATION_5_MILES_AWAY_CONFIRM: 'Location 5 miles away from user coordinates - Confirm',
  LOCATION_NEXT_CLICK: 'Set breakdown location',
  LOCATION_SERVICE_PROMPT: 'Location permission prompt',
  LOCATION_SERVICE_ALLOWED: 'Location permission allowed',
  LOCATION_SERVICE_DENIED: 'Location permission denied',
  OPTIONS_PAGE_TYPE: 'Location Options',
  OPTIONS_CURRENT_CLICK: 'User selected find my location',
  OPTIONS_HOME_CLICK: 'User selected home location',
  OPTIONS_HOME_EDIT_CLICK: 'User selected edit home location',
  SERVICING_CLUB_PAGE_TYPE: 'Servicing Club Config',
  SERVICING_CLUB_DISABLED_PROMPT: 'DRR System Disabled',
  SPECIAL_ASSISTANCE_REQUIRED: 'Special Assistance Required',
  LOCATE_ME: 'Set Locate me',
  LOCATION_ADJUST_LOCATION_CLICK: 'Adjust location',
  LOCATION_INCOMPLETE_BREAKDOWN: 'Location incomplete breakdown',
  LOCATION_HIGHWAY_INPUT: 'Location highway address input',

  PAGE_TYPE: 'Breakdown Location',
  PAGE_NAME_GPS_INSTRUCTION: 'GPS Instruction',
  PAGE_NAME_LOCATION_DEFAULT: 'Location Default',
  PAGE_NAME_ENTER_ADDRESS: 'Enter Address',
  PAGE_NAME_CONFIRM_ADDRESS: 'Confirm Address',
  PAGE_NAME_ADJUST_LOCATION: 'Adjust Location',
}
