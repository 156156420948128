import { Injectable } from '@angular/core'
import { DateTime } from 'luxon';

export function hourToDate(hour: string): Date {
  return convertToDateOrNull(
    `${new Date().toLocaleString('en-us', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    })} ${hour}`
  )
}

export function convertToDateOrNull(date: string | Date): Date {
  const dateObject = new Date(date)
  if (dateObject instanceof Date && !isNaN(dateObject.getTime())) {
    return dateObject
  }
  console.warn(`Invalid date ${date}`)
  return null
}

const DAYS_IN_A_WEEK = 7

@Injectable({
  providedIn: 'root',
})
export class DatetimeUtils {

  getDayOfWeek = (): string => DateTime.local().setLocale('en-us').weekdayLong.toUpperCase()

  getNextWorkingDays = (openAt): string[] =>
    this.getDaysOfWeekFrom(this.isBeforeWorkingHours(openAt) ? 'Today' : 'Tomorrow')

  isBeforeWorkingHours = (openAt: string): boolean => {
    const groups = openAt.match(/([0-9]{1,2}):?([0-9]{0,2})\s*(AM|PM)/i)
    let hour = Number(groups[1])
    const minute = Number(groups[2])
    const period = groups[3]
    if(period === 'PM') {
      hour += 12
    }
    const openingHour = DateTime.local().set({hour, minute})
    return DateTime.local().toMillis() < openingHour.toMillis()
  }

  getDateDifference = (date: Date): number => {
    const now = DateTime.local();
    const then = DateTime.fromJSDate(date);
    return then.diff(now, ['hours', 'minutes']).valueOf();
  };

  getDaysOfWeekFrom = (when: 'Today' | 'Tomorrow'): string[] => {
    const daysOfWeek = []
    let pointer = DateTime.local().setLocale('en-us')
    if (when === 'Tomorrow') {
      pointer = pointer.plus({day: 1})
    }
    for (let i = 0; i < DAYS_IN_A_WEEK; i++) {
      daysOfWeek.push(pointer.weekdayLong.toUpperCase())
      pointer = pointer.plus({day: 1})
    }
    return daysOfWeek
  }

}
