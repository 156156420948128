import { Component } from '@angular/core'
import { Observable } from 'rxjs'
import { AAAStore } from '../../../store/root-reducer'
import { select, Store } from '@ngrx/store'
import {
  selectActiveCallStatus,
  selectCallPaceSetterCode,
  selectCallTowLocation,
  selectCallVehicle,
} from '../calls-statuses/call-status.selectors'
import { AAACallStatus } from '../calls.types'
import { TowLocation } from '../../location/location.types'
import { VehicleData } from '../../member/member.types'
import { PaceSetterCode } from '../../issue/issue.types'

@Component({
  selector: 'app-service-summary',
  templateUrl: './service-summary.component.html',
  styleUrls: ['./service-summary.component.scss'],
})
export class ServiceSummaryComponent {
  call$: Observable<AAACallStatus> = this.store$.pipe(
    select(selectActiveCallStatus)
  )

  paceSetterCode$: Observable<PaceSetterCode['paceSetterCode']> =
    this.store$.pipe(select(selectCallPaceSetterCode))

  towDestination$: Observable<TowLocation> = this.store$.pipe(
    select(selectCallTowLocation)
  )

  vehicle$: Observable<VehicleData> = this.store$.pipe(
    select(selectCallVehicle)
  )

  constructor(private store$: Store<AAAStore>) {}
}
