import { Component, OnInit } from '@angular/core'
import { combineLatest, Observable } from 'rxjs'
import { AAAStore } from '../../../store/root-reducer'
import { select, Store } from '@ngrx/store'
import {
  selectActiveCallStatus,
  selectCallTowLocation,
  selectCanCancelActiveCall,
  selectIsStatusUpdateSupported,
} from '../calls-statuses/call-status.selectors'
import { AAACallStatus } from '../calls.types'
import { TowLocation } from '../../location/location.types'
import { VehicleData } from '../../member/member.types'
import { PaceSetterCode } from '../../issue/issue.types'
import { AbstractComponent } from '../../../shared/abstract.component'
import { map } from 'rxjs/operators'

@Component({
  selector: 'app-service-summary-improvement',
  templateUrl: './service-summary-improvement.component.html',
  styleUrls: ['./service-summary-improvement.component.scss'],
})
export class ServiceSummaryImprovementComponent extends AbstractComponent implements OnInit {
  call$: Observable<AAACallStatus> = this.store$.pipe(
    select(selectActiveCallStatus)
  )

  towDestination$: Observable<TowLocation> = this.store$.pipe(
    select(selectCallTowLocation)
  )

  canCancel$: Observable<boolean> = combineLatest([
    this.store$.select(selectCanCancelActiveCall),
    this.store$.select(selectIsStatusUpdateSupported)
  ]).pipe(
    map(([canCancelActiveCall, isStatusUpdateSupported]) => canCancelActiveCall && isStatusUpdateSupported)
  )

  vehicle: VehicleData;
  paceSetterCode: PaceSetterCode['paceSetterCode']

  constructor(private store$: Store<AAAStore>) {
    super()
  }

  ngOnInit() {
    this.subscriptions.push(
      this.call$.subscribe((data) => {
        this.vehicle = data?.vehicle
        this.paceSetterCode = data?.pacesetterCode
      })
    )
  }

}
