import { Component, EventEmitter, Output } from '@angular/core'

@Component({
  selector: 'app-see-more-button',
  templateUrl: './see-more-button.component.html',
  styleUrls: ['./see-more-button.component.scss'],
})
  export class SeeMoreButtonComponent {
  @Output() buttonClick: EventEmitter<any> = new EventEmitter<any>()

  constructor() {}

  handleClick() {
    this.buttonClick.emit()
  }
}
