<ng-container *ngIf="displayEta$ | async">
  <div class="green-round"
    (click)="toggleTime()">
    <app-img
      src="assets/drrweb-lib/images/icons/time.svg"
      alt="Estimated Time of Arrival"
      i18n-alt
    ></app-img>
    <ng-container *ngIf="isArrivalTimeDisplayed; else etaLabel">
      <span class="arrival-time">{{ displayArrivalTime$ | async | timeRemaining }}*</span>
      <span i18n>until arrival</span>
    </ng-container>
  </div>
  <ng-template #etaLabel>
    <span class="eta" i18n>ETA</span>
    <span class="eta-time">{{ displayEta$ | async | date: 'hh:mm aa' }}*</span>
  </ng-template>
</ng-container>
