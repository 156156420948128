<app-button
  *ngIf="member"
  [disabled]="isLoading"
  inverted="true"
  [classNames]="singleLine ? 'small-pad' : 'first-focusable-element'"
  noBorder="true"
  (buttonClick)="handleClick()"
  [tabIndex]="tabIndex"
>
  <div class="data-group" >
    <icon-home 
      class="data-group_icon" 
      aria-label="Use your home location"
      colorName="red">
    </icon-home>
    <div class="data-group_data">
      <label>{{label}}<span *ngIf="singleLine">:&nbsp;</span></label>
      <div [ngClass]="{ large: !singleLine }">
        {{ member.basicAddress }}, {{ member.city }},
        {{ member.stateProvince }}
        {{ member.postalCode }}
      </div>
    </div>
  </div>
</app-button>
