import { AbstractIconComponent } from './abstract.icon'
import { Component } from '@angular/core'

@Component({
  selector: 'icon-edit-outlined',
  templateUrl: './edit-outlined.icon.html',
})
export class EditOutlinedIconComponent extends AbstractIconComponent {
  static icon = 'edit-outlined'
}
