import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { openPromptDialog } from '../../modules/ui/ui.actions';
import { PromptDialogTypes } from '../../modules/ui/ui.types';
import { select, Store } from '@ngrx/store';
import { AAAStore } from '../../store/root-reducer';
import { VendorConfigurationMode } from '../../modules/auth/mode-configuration.types';
import { Observable } from 'rxjs';
import { selectModeConfiguration } from '../../modules/auth/auth.selectors';
import { WindowObject } from '../utils/window-object';
import { IUrlService } from './index.types'

const DRR_LINK = () => $localize`https://www.aaa.com/privacy`
const ARIA_LABEL = () => $localize`Privacy policy content`

@Injectable({
  providedIn: 'root'
})
export class PrivacyPolicyService implements IUrlService {

  rapConfig: VendorConfigurationMode
  modeConfiguration$: Observable<VendorConfigurationMode> = this.store$.pipe(
    select(selectModeConfiguration)
  )

  constructor(
    private store$: Store<AAAStore>,
    @Inject(LOCALE_ID) private locale: string,
    private windowObject: WindowObject,
  ) {
    this.modeConfiguration$.subscribe((configData: VendorConfigurationMode) => this.rapConfig = configData)
  }

  open() {
    return this.rapConfig ? this.openRap() : this.openDrr()
  }
  getUrl() {
    if (this.rapConfig) {
      const lang = this.locale.split('-')[0]
      return this.rapConfig.privacyPolicy[lang] || this.rapConfig.privacyPolicy
    }
    return DRR_LINK()
  }

  private openDrr() {
    this.windowObject.getWindow().open(this.getUrl())
  }

  private openRap() {
    this.store$.dispatch(
      openPromptDialog({
        payload: {
          type: PromptDialogTypes.IFRAME_DIALOG,
          panelClass: 'iframe-dialog',
          params: {
            ariaLabel: ARIA_LABEL(),
            url: this.getUrl(),
          },
        },
      })
    )
  }
}
