import { Component, Input } from '@angular/core'
import { isElementEmpty } from '../layout-utilities'
import { select, Store } from '@ngrx/store'
import { AAAStore } from '../../../store/root-reducer'
import { Observable } from 'rxjs'
import { selectHasMemberData } from '../../../modules/member/member.selectors'
import { AbstractComponent } from '../../abstract.component'
import { selectIsAgent } from '../../../modules/auth/auth.selectors'

@Component({
  selector: 'app-split-panel',
  templateUrl: './split-panel.component.html',
  styleUrls: ['./split-panel.component.scss'],
})
export class SplitPanelComponent extends AbstractComponent {
  constructor(private store$: Store<AAAStore>) {
    super()
  }
  @Input()
  showSecondaryOnMobile = true

  @Input()
  noPadding = false

  toggleOpenClass = false

  showMemberData$: Observable<boolean> = this.store$.pipe(
    select(selectHasMemberData)
  )

  // start ARR:POC - Handles the rendering of ARR POC components on the right panel.
  isAgent$: Observable<boolean> = this.store$.pipe(select(selectIsAgent))
  // end ARR:POC

  handleDisplayServiceHistory(e: boolean) {
    this.toggleOpenClass = e
  }

  isEmpty(node): boolean {
    return isElementEmpty(node, 'panel-default-secondary')
  }
}
