import { Injectable } from '@angular/core'

import { Store} from '@ngrx/store'
import { AAAStore } from '../../store/root-reducer'
import { GoogleCoordinates } from './google-geocode/types'
import { setUserDefaultCoords } from '../ui/ui.actions'

@Injectable({
  providedIn: 'root',
})
export class LibLocationService {
  constructor(private store$: Store<AAAStore>) {
  }

  async setCoordinates(
    {
      lat,
      lng,
      accuracy
    }: GoogleCoordinates
  ) {
    const payload = { lat, lng }
    if (accuracy > 0 ) {
      payload['accuracy'] = accuracy
    }
    this.store$.dispatch(setUserDefaultCoords({ payload }))
  }
}
