<div class="what-happened">
  <div class="what-happened_primary-title">
    <h1 i18n role="heading" aria-level="2">
      Now, please select your vehicle’s Issue
    </h1>
  </div>
  <div class="what-happened_container">
    <div class="what-happened_left">
      <app-problem-tooltip (selectIssue)="problemSelected($event)"></app-problem-tooltip>
      <app-issue-selection></app-issue-selection>

      <div class="emergency-dialog">
        <a
          href="tel: 911"
          appCtaClickEvent="911 Click-To-Call"
          [tabindex]="100"
          (click)="set911LinkClick()"
        >
          <app-img
            class="red-triangle"
            src="assets/drrweb-lib/images/exclaim-red-triangle.svg"
            alt="Exclaim danger"
            i18n-alt
          ></app-img>
          <span class="set-text show-mobile">
            <ng-container i18n>If you're unsafe, tap here to dial</ng-container>
            &nbsp;
            <b class="underline-this">9-1-1</b>
          </span>
          <span class="set-text show-desktop">
            <ng-container i18n>If you're in an unsafe situation, dial</ng-container>
            &nbsp;
            <b>9-1-1</b>
          </span>
        </a>
      </div>
    </div>
  </div>
</div>
