import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { dashboardComponents } from './dashboard.component.declarations'
import { SharedModule } from '../../shared'
import { DashboardRoutingModule } from './dashboard-routing/dashboard-routing.module'
import { ServiceDeliveryComponent } from './service-delivery/service-delivery.component'
import { LocationModule } from '../location/location.module'

@NgModule({
  declarations: [...dashboardComponents(), ServiceDeliveryComponent],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    SharedModule,
    LocationModule,
  ],
})
export class DashboardModule {}
