<div class="call-status-timeline">
  <icon-timeline-bullet *ngIf="timeline?.length"></icon-timeline-bullet>
  <div *ngFor="let status of timeline">
    <ng-container [ngSwitch]="status">
      <ng-container *ngSwitchCase="'COMPLETED'">
        <div class="call-status">
          <icon-timeline-filled-line></icon-timeline-filled-line>
          <icon-timeline-bullet></icon-timeline-bullet>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'ACTIVE'">
        <div class="call-status">
          <div class="call-status-active">
            <icon-timeline-active-line></icon-timeline-active-line>
            <img
              src="../../../../../assets/drrweb-lib/images/icons/timeline-tow-truck.svg"
              alt="timeline truck"
              i18n-alt
              class="timeline-tow-truck"
              [@moveDown]="animationState"
            />
          </div>
          <icon-timeline-bullet opacity="0.5"></icon-timeline-bullet>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'PENDING'">
        <div class="call-status">
          <img
            src="../../../../../assets/drrweb-lib/images/icons/timeline-dotted-line.svg"
            alt="timeline disabled"
            i18n-alt
          />
          <icon-timeline-bullet disabled="true" opacity="0.5"></icon-timeline-bullet>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
