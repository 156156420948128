import { Component, Input } from '@angular/core'
import { PaceSetterDetails } from '../issue.types'
import { constructDetailsSummary } from '../issue.utils'

@Component({
  selector: 'app-situation-summary',
  templateUrl: './situation-summary.component.html',
  styleUrls: ['./situation-summary.component.scss'],
})
export class SituationSummaryComponent {
  @Input() details: PaceSetterDetails
  @Input() isRapUser: boolean

  get summary(): string {
    return constructDetailsSummary(this.details, this.isRapUser)
  }

  constructor() {}
}
