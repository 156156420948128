import { Component, EventEmitter, Input, Output } from '@angular/core'
import { AARData } from '../aar/aar.types'

@Component({
  selector: 'app-tow-location-item',
  templateUrl: './tow-location-item.component.html',
  styleUrls: ['./tow-location-item.component.scss'],
})
export class TowLocationItemComponent {
  @Input() location: AARData
  @Output() summaryClick = new EventEmitter()
  @Output() clearSelectedShop = new EventEmitter()
  @Input() tabIndex = 0
  @Input() type: 'summary' | 'full' = 'full'
  @Input() showAaaDiscount = true
  @Input() closable = true

  constructor() {
  }

  handleClearSelectedShop() {
    this.clearSelectedShop.emit()
  }

  handleSummaryClick($event) {
    if($event.target instanceof SVGElement) {
      $event.preventDefault()
      return
    }
    this.summaryClick.emit()
  }

}
