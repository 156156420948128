import { Component, Input } from '@angular/core'
import { Advisory } from '../../advisories/advisories.types'

@Component({
  selector: 'app-status-update',
  templateUrl: './status-update.component.html',
  styleUrls: ['./status-update.component.scss'],
})
export class StatusUpdateComponent {
  @Input() update: Advisory

  constructor() {}
}
