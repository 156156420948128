import { animate, style, transition, trigger } from '@angular/animations'
import { AfterContentInit, Component, EventEmitter, OnInit, Output } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { combineLatest } from 'rxjs'
import { AbstractComponent } from '../../../shared/abstract.component'
import { AAAStore } from '../../../store/root-reducer'
import { AAA_DEFAULT_PHONE_NUMBER, selectDefaultPhoneNumber } from '../../servicing-club/servicing-club.selectors'
import { AdobeMemberValidationService } from '../../tagging/adobe/member-validation-adobe.service'
import events from '../../tagging/events'
import { TaggingService } from '../../tagging/tagging.service'
import { AdobeEventTypes, MemberValidationType } from '../../tagging/tagging.types'
import { openErrorDialog } from '../../ui/ui.actions'
import { ErrorDialogTypes } from '../../ui/ui.types'
import { setAuthMethod, setClickedUseAnotherForm } from '../auth.actions'
import { selectAuthErrorCount, selectAuthMethod, selectIsUseAnotherFormClickedAfterError } from '../auth.selectors'
import { AuthMethods, LoginFailAssistantError } from '../auth.types'

const MESSAGE_LFA_CREDENTIALS_DONT_MATCH = () => $localize`We're sorry. It seems that your login credentials don't match your membership card.<br>
      Please try different variations of your full name, such as the <strong>FIRST</strong> and <strong>LAST NAME ONLY</strong>, without hyphens or middle names.`
const MESSAGE_LFA_TROUBLE_VERIFYING_DETAILS = () => $localize`It looks like we are having trouble verifying your information details.`
const MESSAGE_LFA_PROBLEM_PERSISTS = () => $localize`If the problem persists,`
const MESSAGE_LFA_TRY_AGAIN_OR = () => $localize`Try again, or use your`
const LINK_LFA_FIRST_LAST_NAME = () => $localize`First and Last Name`
const LINK_LFA_MEMBERSHIP_NUMBER = () => $localize`AAA Membership Number`
const MESSAGE_LFA_TROUBLE_VERIFYING = () => $localize`Sorry, we're having trouble verifying your AAA Membership. Make sure you are entering the numbers<strong> displayed on your card and home postal code</strong>.`
const MESSAGE_LFA_LOOKS_LIKE_TROUBLE_VERIFYING_NUMBER = () => $localize`It looks like we are having trouble verifying your AAA Membership Number. Please try again using your <strong>primary account holder's</strong> details into the first name, last name, and home postal code.`
const MESSAGE_LFA_LOOKS_LIKE_TROUBLE_VERIFYING_NAME = () => $localize`It looks like we are having trouble verifying your name and home postal code. Please try again using your <strong>AAA Membership Number and home postal code.</strong>`
const MESSAGE_LFA_LOOKS_LIKE_TROUBLE_VERIFYING_NUMBER_MAKE_SURE = () => $localize`It looks like we are having trouble verifying your AAA Membership Number. Make sure you are entering the <strong>AAA Membership Number displayed on your card and home postal code</strong>.`
const MESSAGE_LFA_TRY_USING = () => $localize`Or try using your`
const MESSAGE_LFA_DONT_HAVE_AAA_NUMBER = () => $localize`Don't have your AAA Membership Number?`
const MESSAGE_LFA_NAME_AND_ZIP_LINK = () => $localize`Name and Home Postal Code`
const LINK_LFA_CALL_AAA = () => $localize`Call AAA`

@Component({
  selector: 'app-login-fail-assistant',
  templateUrl: './login-fail-assistant.component.html',
  styleUrls: ['./login-fail-assistant.component.scss'],
  animations: [
    trigger('slideInAnimation', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({'max-height':0, opacity: 0}),
        animate(500, style({'max-height':100, opacity: 1}))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(500, style({'max-height':0}))
      ])
    ])
  ]
})
export class LoginFailAssistantComponent extends AbstractComponent implements OnInit, AfterContentInit {
  @Output() hasError: EventEmitter<any> = new EventEmitter<any>()

  authMethod$ = this.store$.pipe(select(selectAuthMethod))
  errorCount$ = this.store$.pipe(select(selectAuthErrorCount))
  isUseAnotherFormClickedAfterError$ = this.store$.pipe(select(selectIsUseAnotherFormClickedAfterError))
  assistantInput$ = combineLatest([
    this.authMethod$,
    this.errorCount$,
  ])
  defaultPhoneNumber$ = this.store$.pipe(select(selectDefaultPhoneNumber))

  constructor(
    private store$: Store<AAAStore>,
    private taggingService: TaggingService,
    private adobeMemberValidationService: AdobeMemberValidationService,
  ) {
    super()
  }
  authMethod: AuthMethods
  show = false
  autoSwitch = false
  useAnotherFormClickedAfterError = false
  dialogErrors: LoginFailAssistantError
  previousErrorCount = 0
  defaultPhoneNumber = AAA_DEFAULT_PHONE_NUMBER.replace(/\D/g,'')

  hasChanged = () => this.authMethod === undefined

  ngOnInit(): void {
    this.subscriptions.push(
      this.isUseAnotherFormClickedAfterError$.subscribe((clicked) => this.useAnotherFormClickedAfterError = clicked),
      this.defaultPhoneNumber$.subscribe((number) => this.defaultPhoneNumber = number.replace(/\D/g,''))
    )
  }

  ngAfterContentInit(): void {
    this.subscriptions.push(
      this.assistantInput$.subscribe(([method, count]) => {
        // when a refresh happens the previousErrorCount is reset, but the count value is kept
        // for the first 2 attempts it should keep the error messages in page, even on refresh
        // after the 2 attempt the message is a popup that shouldn't be displayed on refresh
        const clearOnRefreshAfterThirdAttempt = count - this.previousErrorCount > 2
        this.previousErrorCount = count

        this.show = !clearOnRefreshAfterThirdAttempt && count > 0 && (
          (this.autoSwitch && !this.useAnotherFormClickedAfterError) || // show the message on auto switch
          this.authMethod === method || // prevent to show the message when form is changing
          (this.hasChanged() && !this.useAnotherFormClickedAfterError) // prevent to show the message when user manually switches
        );

        // keep prompt analytic event for other issues not using LFA
        if (this.show && ![AuthMethods.MEMBERSHIP_NUMBER, AuthMethods.MEMBER_NAME].includes(method)) {
          this.callPromptTag()
          return
        }

        if (this.show) {
          if (count === 1) {
            // analytics event
            this.callPromptTag()
            // assistant info
            if (method === AuthMethods.MEMBER_NAME) {
              this.dialogErrors = this.getFirstNameError()
            }
            if (method === AuthMethods.MEMBERSHIP_NUMBER) {
              this.dialogErrors = this.getFirstNumberError()
            }
          } else if (count === 2) {
            // analytics event
            !this.autoSwitch && this.callPromptTag() //avoid prompt analytic event for auto switch
            // assistant info
            if (method === AuthMethods.MEMBER_NAME) {
              this.dialogErrors = this.getSecondNameError()
            }
            if (method === AuthMethods.MEMBERSHIP_NUMBER) {
              this.dialogErrors = this.getSecondNumberError()
            }
          } else {
            // analytics event
            this.callPromptTag()
            // assistant info
            this.store$.dispatch(
              openErrorDialog({
                payload: {
                  type: ErrorDialogTypes.MEMBER_NO_MATCHES,
                },
              })
            )
            this.show = false
          }
        }
        // emit flag to parent in order to highlight and clear the inputs on errors
        this.hasError.emit(this.show)

        // special condition to automatically show NUMBER/NAME form after second error at NAME/NUMBER when the user didn't switch after the first error 😅
        if (
          [AuthMethods.MEMBER_NAME, AuthMethods.MEMBERSHIP_NUMBER].includes(this.authMethod)
          && count === 2 && !this.useAnotherFormClickedAfterError && !this.autoSwitch
        ) {
          this.store$.dispatch(
            setAuthMethod({
              payload: this.authMethod === AuthMethods.MEMBER_NAME ? AuthMethods.MEMBERSHIP_NUMBER : AuthMethods.MEMBER_NAME
            })
          )
          this.autoSwitch = true
        }
        this.authMethod = method
      }),
    )
  }

  callPromptTag = () => {
    this.taggingService.setPageEvent(
      events.auth.MEMBER_ISSUE_PROMPT,
      events.auth.MEMBER_ISSUE_PAGE_TYPE,
    )
  }

  getFirstNameError = () => ({
    message: MESSAGE_LFA_CREDENTIALS_DONT_MATCH(),
    cta: MESSAGE_LFA_TRY_USING(),
    link: LINK_LFA_MEMBERSHIP_NUMBER(),
    secondLink: null,
    event: () => {
      this.store$.dispatch(setClickedUseAnotherForm())
      this.store$.dispatch(setAuthMethod({payload: AuthMethods.MEMBERSHIP_NUMBER}))
      this.taggingService.setClickEvent(
        events.auth.USE_FORM_MEMBERSHIP_CLICK,
        events.auth.PAGE_TYPE
      )
      this.adobeMemberValidationService.sendEvent(AdobeEventTypes.MEMBER_VALIDATION_TOGGLE, MemberValidationType.MEMBER_NUMBER)
    },
    secondEvent: () => null,
    href: null,
    icon: 'dialog',
    cssClass: 'warn-box',
  })

  getFirstNumberError = () => ({
    message: MESSAGE_LFA_TROUBLE_VERIFYING(),
    cta: MESSAGE_LFA_TRY_USING(),
    link: MESSAGE_LFA_NAME_AND_ZIP_LINK(),
    secondLink: null,
    href: null,
    event: () => {
      this.store$.dispatch(setClickedUseAnotherForm())
      this.store$.dispatch(setAuthMethod({payload: AuthMethods.MEMBER_NAME}))
      this.taggingService.setClickEvent(
        events.auth.USE_FORM_NAME_CLICK,
        events.auth.PAGE_TYPE
      )
      this.adobeMemberValidationService.sendEvent(AdobeEventTypes.MEMBER_VALIDATION_TOGGLE, MemberValidationType.MEMBER_NAME)
    },
    secondEvent: () => null,
    icon: 'phone',
    cssClass: 'warn-box',
  })

  getFirstPhoneError = () => ({
    message: MESSAGE_LFA_TROUBLE_VERIFYING_DETAILS(),
    cta: MESSAGE_LFA_TRY_AGAIN_OR(),
    link: LINK_LFA_MEMBERSHIP_NUMBER(),
    secondLink: LINK_LFA_FIRST_LAST_NAME(),
    event: () => {
      this.store$.dispatch(setAuthMethod({payload: AuthMethods.MEMBERSHIP_NUMBER}))
      this.taggingService.setClickEvent(
        events.auth.USE_FORM_MEMBERSHIP_CLICK,
        events.auth.PAGE_TYPE
      )
      this.adobeMemberValidationService.sendEvent(AdobeEventTypes.MEMBER_VALIDATION_TOGGLE, MemberValidationType.MEMBER_NUMBER)
      this.show = false
    },
    secondEvent: () => {
      this.store$.dispatch(setAuthMethod({payload: AuthMethods.MEMBER_NAME}))
      this.taggingService.setClickEvent(
        events.auth.USE_FORM_NAME_CLICK,
        events.auth.PAGE_TYPE
      )
      this.adobeMemberValidationService.sendEvent(AdobeEventTypes.MEMBER_VALIDATION_TOGGLE, MemberValidationType.MEMBER_NAME)
      this.show = false
    },
    href: null,
    icon: 'dialog',
    cssClass: 'warn-box',
  })

  getSecondNameError = () => ({
    message: this.autoSwitch || this.hasChanged() ?
      MESSAGE_LFA_LOOKS_LIKE_TROUBLE_VERIFYING_NUMBER() :
      MESSAGE_LFA_LOOKS_LIKE_TROUBLE_VERIFYING_NAME(),
    cta: MESSAGE_LFA_DONT_HAVE_AAA_NUMBER(),
    link: LINK_LFA_CALL_AAA(),
    secondLink: null,
    href: `tel:+1${this.defaultPhoneNumber}`,
    event: null,
    secondEvent: null,
    icon: 'phone',
    cssClass: 'error-box',
  })

  getSecondNumberError = () => ({
    message: this.autoSwitch || this.hasChanged() ?
      MESSAGE_LFA_LOOKS_LIKE_TROUBLE_VERIFYING_NAME() :
      MESSAGE_LFA_LOOKS_LIKE_TROUBLE_VERIFYING_NUMBER_MAKE_SURE(),
    cta: MESSAGE_LFA_DONT_HAVE_AAA_NUMBER(),
    link: LINK_LFA_CALL_AAA(),
    secondLink: null,
    href: `tel:+1${this.defaultPhoneNumber}`,
    event: null,
    secondEvent: null,
    icon: 'phone',
    cssClass: 'error-box',
  })

  getSecondPhoneError = () => ({
    message: MESSAGE_LFA_TROUBLE_VERIFYING_DETAILS(),
    cta: MESSAGE_LFA_PROBLEM_PERSISTS(),
    link: LINK_LFA_MEMBERSHIP_NUMBER(),
    secondLink: LINK_LFA_FIRST_LAST_NAME(),
    event: () => {
      this.store$.dispatch(setAuthMethod({payload: AuthMethods.MEMBERSHIP_NUMBER}))
      this.taggingService.setClickEvent(
        events.auth.USE_FORM_MEMBERSHIP_CLICK,
        events.auth.PAGE_TYPE
      )
      this.adobeMemberValidationService.sendEvent(AdobeEventTypes.MEMBER_VALIDATION_TOGGLE, MemberValidationType.MEMBER_NUMBER)
      this.show = false
    },
    secondEvent: () => {
      this.store$.dispatch(setAuthMethod({payload: AuthMethods.MEMBER_NAME}))
      this.taggingService.setClickEvent(
        events.auth.USE_FORM_NAME_CLICK,
        events.auth.PAGE_TYPE
      )
      this.adobeMemberValidationService.sendEvent(AdobeEventTypes.MEMBER_VALIDATION_TOGGLE, MemberValidationType.MEMBER_NAME)
      this.show = false
    },
    href: `tel:+1${this.defaultPhoneNumber}`,
    icon: 'dialog',
    cssClass: 'error-box',
  })
}

