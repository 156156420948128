import { Component, OnInit } from '@angular/core'
import { AAAStore } from '../../../store/root-reducer'
import { Store } from '@ngrx/store'
import { AbstractComponent } from '../../../shared/abstract.component'
import { selectVehiclesByPlate } from '../vehicle.selectors'
import { EditService } from '../../../shared/services/edit.service'
import { setVehicle } from '../../member/member.actions'
import { Vehicle } from '../vehicle.types'
import { ActivatedRoute } from '@angular/router'
import { VehicleSections } from '../../ui/ui.types'
import { AdobeEventTypes } from '../../tagging/tagging.types'
import { AdobeEventService } from '../../tagging/adobe/event-adobe.service'
import events from '../../tagging/events'

@Component({
  selector: 'app-vehicle-confirm',
  templateUrl: './vehicle-confirm.component.html',
  styleUrls: ['./vehicle-confirm.component.scss'],
})
export class VehicleConfirmComponent extends AbstractComponent implements OnInit {

  vehiclesByPlate$ = this.store$.select(selectVehiclesByPlate)

  constructor(
    private store$: Store<AAAStore>,
    public editService: EditService,
    private route: ActivatedRoute,
    private adobeEventService: AdobeEventService
  ) {
    super()
  }

  ngOnInit() {
    this.subscriptions.push(
      this.vehiclesByPlate$.subscribe((vehicles) => {
          const vehicleSection = this.route.snapshot.queryParams['section']
          if (vehicleSection === 'confirm' && (!vehicles || vehicles.length === 0)) {
            this.editService.editVehicleSection(true, '', VehicleSections.PLATE)
          }
        }
      ))
  }

  handleSelectVehicle(vehicle: Vehicle) {
    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: events.vehicle.PLATE_TO_VIN_CONFIRM_VEHICLE
    })
    this.store$.dispatch(setVehicle({
      payload: {
        make: vehicle.make,
        model: vehicle.model,
        year: vehicle.year,
        color: vehicle.color,
        vin: vehicle.vin,
        licensePlate: vehicle.tag,
        state: vehicle.state,
        // fuelType: vehicle.fuelType,
        // driveType: vehicle.driveType,
      }
    }))
  }

  handleVehicleNotListed() {
    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: events.vehicle.PLATE_TO_VIN_VEHICLE_NOT_LISTED
    })
    this.editService.editVehicleSection(false, '', VehicleSections.PLATE)
  }

}
