<h2
  role="heading"
  aria-level="1"
  *ngIf="isCreatingNew && isMember && (allowAddVehicle$ | async)"
>
  Vehicle not listed?
</h2>
<div *ngIf="isCreatingNew && (allowAddVehicle$ | async)" class="title-line">
  <div class="plus-container">
    <div class="minus"></div>
    <div class="plus minus"></div>
  </div>
</div>

<div
  *ngIf="(allowAddVehicle$ | async) || (isVehicleYearsStep$ | async)"
  class="loading-holder"
>
  <h3 i18n role="heading" aria-level="2" class="large-title">
    Select your vehicle's year
  </h3>
  <app-vehicle-years
    (selected)="yearChange($event)"
    [tabIndexPosition]="tabIndexPosition"
  >
  </app-vehicle-years>

  <ng-container *ngIf="isLoading$ | async">
    <app-loading
      width="25"
      height="25"
      class="typeahead_icon-begin"
    ></app-loading>
  </ng-container>
</div>
