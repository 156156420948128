import { Injectable } from '@angular/core'

declare const VERSION

@Injectable({
  providedIn: 'root',
})
export class AppVersion {

  get = () => VERSION

}
