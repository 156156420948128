import { OEMBranding } from '../../modules/auth/auth.types';
import { getCookie } from './cookies';

export const OEMLogoData = {
  [OEMBranding.HYUNDAI]: {
    width: '120',
    className: `${OEMBranding.HYUNDAI.toLowerCase()}-logo`,
    fileName: 'hyundai_logo',
    alt: OEMBranding.HYUNDAI
  },
  [OEMBranding.GENESIS]: {
    width: '120',
    className: `${OEMBranding.GENESIS.toLowerCase()}-logo`,
    fileName: 'genesis_logo',
    alt: OEMBranding.GENESIS
  },
  [OEMBranding.HONDA]: {
    width: '120',
    className: `${OEMBranding.HONDA.toLowerCase()}-logo`,
    fileName: 'honda_logo',
    alt: OEMBranding.HONDA
  },
  [OEMBranding.ACURA]: {
    width: '120',
    className: `${OEMBranding.ACURA.toLowerCase()}-logo`,
    fileName: 'acura_logo',
    alt: OEMBranding.ACURA
  },
  [OEMBranding.BLUESKYNM]: {
    width: '55',
    className: `${OEMBranding.BLUESKYNM.toLowerCase()}-logo`,
    fileName: 'blueskynm_logo',
    alt: OEMBranding.BLUESKYNM
  },
  [OEMBranding.GMC]: {
    width: '120',
    className: `${OEMBranding.GMC.toLowerCase()}-logo`,
    fileName: 'gmc_logo',
    alt: OEMBranding.GMC
  },
  [OEMBranding.BUICK]: {
    width: '120',
    className: `${OEMBranding.BUICK.toLowerCase()}-logo`,
    fileName: 'buick_logo',
    alt: OEMBranding.BUICK
  },
  [OEMBranding.ONSTAR]: {
    width: '40',
    className: `${OEMBranding.ONSTAR.toLowerCase()}-logo`,
    fileName: 'onstar_logo',
    alt: OEMBranding.ONSTAR
  },
  [OEMBranding.CHEVROLET]: {
    width: '100',
    className: `${OEMBranding.CHEVROLET.toLowerCase()}-logo`,
    fileName: 'chevrolet_logo',
    alt: OEMBranding.CHEVROLET
  },
  [OEMBranding.MAZDA]: {
    width: '40',
    className: `${OEMBranding.MAZDA.toLowerCase()}-logo`,
    fileName: 'mazda_logo',
    alt: OEMBranding.MAZDA
  },
  [OEMBranding.INFINITI]: {
    width: '120',
    className: `${OEMBranding.INFINITI.toLowerCase()}-logo`,
    fileName: 'infiniti_logo',
    alt: OEMBranding.INFINITI
  },
  [OEMBranding.NISSAN]: {
    width: '120',
    className: `${OEMBranding.NISSAN.toLowerCase()}-logo`,
    fileName: 'nissan_logo',
    alt: OEMBranding.NISSAN
  },
}

export const getOemBrandingId = (url: string): string => {
  let oemBrandingAppId: string

  const appId = getCookie('AAA_AppId');
  if (appId && appId.toUpperCase() in OEMBranding) {
    oemBrandingAppId = appId
  }

  if (!oemBrandingAppId) {
    for (let key in OEMBranding) {
      if (url.toUpperCase().indexOf(key) > -1) {
        oemBrandingAppId = key
      }
    }
  }

  return oemBrandingAppId;
}
