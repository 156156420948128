<!-- Fullscreen selection -->
<ng-container *ngIf="focused || !isMobile">
  <div
    class="custom-autocomplete-container"
    [ngClass]="{
    'fullscreen': focused
  }"
  >
    <div
      #locationAutoContainer
      class="focus-container"
      [tabIndex]="tabIndex - 1"
    >
      <app-auto-complete
        #autocomplete
        [model]="model"
        [placeHolder]="placeholder"
        [readonly]="readonly"
        [isLoading]="isLoading"
        [tabIndex]="tabIndex"
        [customElement]="customElement"
        [hasUseCurrentLocationOption]="hasUseCurrentLocationOption"
        [editing]="focused"
        (addressSelected)="handleAddressSelected($event)"
        [view]="view"
        (isValidChange)="handleIsValidChange($event)"
        [lastSearchLocation]="lastSearch"
        (cancelSearch)="cancelSearch()"
        [enableAutofocus]="isMobile"
        [topTemplate]="suggestedShopTemplate"
      >
      </app-auto-complete>
    </div>
  </div>
</ng-container>

<!-- Placeholder / info display -->
<ng-container *ngIf="!focused && isMobile">
  <div class="address-info">
    <icon-location *ngIf="!isLoading"
      [colorName]="pinIconColor"
      class="icon">
    </icon-location>
    <app-loading width="25"
      height="25"
      *ngIf="isLoading"
      class="typeahead_icon-begin">
    </app-loading>

    <div class="address"
      [ngClass]="{empty: !formatAddress()}"
      [innerHTML]="formatAddress() || placeholder">
    </div>

    <button
      i18n-aria-label
      (click)="handleAddressSelected({});"
      *ngIf="model !== '' && model !== null"
      class="clear-action inverted"
      name="clearLocation"
      id="address-clear"
      [tabIndex]="tabIndex + 1"
      aria-label="close"
    >
      <icon-close
        [attr.aria-hidden]="!(model !== '' && model !== null)"
      ></icon-close>
    </button>

    <button
      i18n-aria-label
      class="search-action inverted"
      *ngIf="model === '' || model === null"
      [tabIndex]="tabIndex + 1"
      aria-label="search"
    >
      <icon-magnifier></icon-magnifier>
    </button>
  </div>
</ng-container>
