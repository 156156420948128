import { Component, Input, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core'
import { VehicleEditState } from '../vehicle.reducer'
import { AAAStore } from '../../../store/root-reducer'
import { Store, select } from '@ngrx/store'
import { editVehicle, setVehicleStep } from '../vehicle.actions'
import { VehicleData } from '../../member/member.types'
import { Observable } from 'rxjs/internal/Observable'
import { selectIsVehicleYearEditing } from '../vehicle.selectors'
import { clearActiveVehicle } from '../../member/member.actions'
import { getModelDescription } from '../vehicle.utils'
import { EditTagComponent } from '../edit-tag/edit-tag.component'

@Component({
  selector: 'app-vehicle-edit-options',
  templateUrl: './vehicle-edit-options.component.html',
  styleUrls: ['./vehicle-edit-options.component.scss'],
})
export class VehicleEditOptionsComponent {

  @ViewChildren(EditTagComponent) editTags: QueryList<EditTagComponent>

  @Input() vehicle: VehicleEditState = {}
  @Input() isActive = false
  @Input() tabIndexPosition = 0

  @Input() invertedCancel = false

  @Output() clickCancel: EventEmitter<any> = new EventEmitter<any>()

  yearDisabled$: Observable<boolean> = this.store$.pipe(
    select(selectIsVehicleYearEditing)
  )

  constructor(private store$: Store<AAAStore>) {}

  ngAfterViewInit() {
    this.focusFirstChild()
  }

  editVehicleSection(section: string) {
    this.store$.dispatch(clearActiveVehicle())
    this.store$.dispatch(setVehicleStep({
      payload: {
        step: section
      }
    }))
    this.store$.dispatch(
      editVehicle({
        payload: {
          vehicle: this.vehicle as VehicleData,
          isActive: this.isActive,
          section,
        },
      })
    )

    //handle canceling button
    if (!section) {
      this.clickCancel.emit()
    }
  }

  getModel(vehicle: VehicleEditState) {
    return getModelDescription(vehicle)
  }

  cancelEditVehicle() {
    this.store$.dispatch(setVehicleStep({
      payload: {
        step: null
      }
    }))
    this.clickCancel.emit()
  }

  private focusFirstChild() {
    const first = this.editTags.first
    if(first) {
      first.setFocus();
    }
  }
}
