import { Make } from './vehicle.types';

export const CommonMakes: Make[] = [
  {
    label: 'Ford',
    icon: 'Ford@2x.png'
  },
  {
    label: 'Toyota',
    icon: 'Toyota@2x.png'
  },
  {
    label: 'Chevrolet',
    icon: 'Chevrolet@2x.png'
  },
  {
    label: 'Honda',
    icon: 'Honda@2x.png'
  },
  {
    label: 'Nissan',
    icon: 'Nissan@2x.png'
  },
  {
    label: 'Jeep',
    icon: 'Jeep@2x.png'
  },
  {
    label: 'Ram',
    icon: 'Ram@2x.png'
  },
  {
    label: 'Hyundai',
    icon: 'Hyundai@2x.png'
  },
  {
    label: 'Subaru',
    icon: 'Subaru@2x.png'
  },
  {
    label: 'Kia',
    icon: 'Kia@2x.png'
  },
  {
    label: 'GMC',
    icon: 'GMC@2x.png'
  },
  {
    label: 'Mercedes-Benz',
    icon: 'Mercedes-Benz@2x.png'
  },
  {
    label: 'Volkswagen',
    icon: 'Volkswagen@2x.png'
  },
  {
    label: 'Tesla',
    icon: 'Tesla@2x.png'
  },
  {
    label: 'BMW',
    icon: 'Bmw@2x.png'
  },
]

export const ExtraMakes: Make[] = [
  {
    label: 'Volvo',
    icon: 'Volvo@2x.png'
  },
  {
    label: 'Renault',
    icon: 'Renault@2x.png'
  },
  {
    label: 'Citroen',
    icon: 'Citroen@2x.png'
  },
  {
    label: 'Fiat',
    icon: 'Fiat@2x.png'
  },
  {
    label: 'Suzuki',
    icon: 'Suzuki@2x.png'
  },
  {
    label: 'Peugeot',
    icon: 'Peugeot@2x.png'
  },
  {
    label: 'Ferrari',
    icon: 'Ferrari@2x.png'
  },
  {
    label: 'Land Rover',
    icon: 'LandRover@2x.png'
  },
  {
    label: 'Audi',
    icon: 'Audi@2x.png'
  },
  {
    label: 'Saab',
    icon: 'Saab@2x.png'
  },
  {
    label: 'Mini',
    icon: 'Mini@2x.png'
  },
  {
    label: 'Mazda',
    icon: 'Mazda@2x.png'
  },
  {
    label: 'Jaguar',
    icon: 'Jaguar@2x.png'
  },
  {
    label: 'Mitsubishi',
    icon: 'Mitsubishi@2x.png'
  },
  {
    label: 'Dodge',
    icon: 'Dodge@2x.png'
  },
  {
    label: 'Lamborghini',
    icon: 'Lamborghini@2x.png'
  },
  {
    label: 'Lexus',
    icon: 'Lexus@2x.png'
  },
  {
    label: 'Porsche',
    icon: 'Porsche@2x.png'
  },
  {
    label: 'Rolls-Royce',
    icon: 'RollsRoyce@2x.png'
  },
  {
    label: 'Bentley',
    icon: 'Bentley@2x.png'
  },
  {
    label: 'Alfa Romeo',
    icon: 'AlfaRomeo@2x.png'
  },
  {
    label: 'Smart',
    icon: 'Smart@2x.png'
  },
  {
    label: 'Chery',
    icon: 'Chery@2x.png'
  },
  {
    label: 'Bugatti',
    icon: 'Bugatti@2x.png'
  },
  {
    label: 'Maserati',
    icon: 'Maserati@2x.png'
  },
  {
    label: 'Mercury',
    icon: 'Mercury@2x.png'
  },
  {
    label: 'Cadillac',
    icon: 'Cadillac@2x.png'
  },
  {
    label: 'Acura',
    icon: 'Acura@2x.png'
  },

  {
    label: 'Plymouth',
    icon: 'Plymouth@2x.png'
  },
  {
    label: 'Auburn',
    icon: 'Auburn@2x.png'
  },
  {
    label: 'Pontiac',
    icon: 'Pontiac@2x.png'
  },
  {
    label: 'Cleveland',
    icon: 'Cleveland@2x.png'
  },
  {
    label: 'Cole',
    icon: 'Cole@2x.png'
  },
  {
    label: 'Davis',
    icon: 'Davis@2x.png'
  },
  {
    label: 'Du pont',
    icon: 'Dupont@2x.png'
  },
  {
    label: 'Elcar',
    icon: 'Elcar@2x.png'
  },
  {
    label: 'Lotus',
    icon: 'Lotus@2x.png'
  },
  {
    label: 'Saturn',
    icon: 'Saturn@2x.png'
  },
  {
    label: 'Essex',
    icon: 'Essex@2x.png'
  },
  {
    label: 'Franklin',
    icon: 'Franklin@2x.png'
  },
  {
    label: 'Gardner',
    icon: 'Gardner@2x.png'
  },
  {
    label: 'Haynes',
    icon: 'Haynes@2x.png'
  },
  {
    label: 'Hcs',
    icon: 'Hcs@2x.png'
  },
  {
    label: 'Biddle',
    icon: 'Biddle@2x.png'
  },
  {
    label: 'Buick',
    icon: 'Buick@2x.png'
  },
  {
    label: 'Hispano-Suiza',
    icon: 'HispanoSuiza@2x.png'
  },
  {
    label: 'Hupmobile',
    icon: 'Hupmobile@2x.png'
  },
  {
    label: 'Jordan',
    icon: 'Jordan@2x.png'
  },
  {
    label: 'Lincoln',
    icon: 'Lincoln@2x.png'
  },
  {
    label: 'Locomobile',
    icon: 'Locomobile@2x.png'
  },
  {
    label: 'Marmon',
    icon: 'Marmon@2x.png'
  },
  {
    label: 'Maxwell',
    icon: 'Maxwell@2x.png'
  },
  {
    label: 'Moon',
    icon: 'Moon@2x.png'
  },
  {
    label: 'Geo',
    icon: 'Geo@2x.png'
  },
  {
    label: 'Eagle',
    icon: 'Eagle@2x.png'
  },
  {
    label: 'Infinity',
    icon: 'Infinity@2x.png'
  },
  {
    label: 'Isuzu',
    icon: 'Isuzu@2x.png'
  },
  {
    label: 'Scion',
    icon: 'Scion@2x.png'
  },
  {
    label: 'Spyker',
    icon: 'Spyker@2x.png'
  },
  {
    label: 'Mayback',
    icon: 'Mayback@2x.png'
  },
]

export const ALL_MAKES: Make[] = [
  ...CommonMakes,
  ...ExtraMakes,
]
