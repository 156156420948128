import {Injectable} from '@angular/core'
import { IndexedCollection } from '../../../shared/types'
import { indexCollection } from '../../../shared/utils'
import {XHRService} from '../../../shared/services/xhr.service';
import { AARData, AarDataApiModel } from './aar.types';
import {AARSchema} from './aar.model';
import { AARDataAdapter } from '../../../shared/adapters/AARDataAdapter';

const RADIUS_EV_STATIONS = 10

@Injectable({
  providedIn: 'root',
})
export class EvStationService {

  constructor(
    private xhr: XHRService,
    private aarDataAdapter: AARDataAdapter,
  ) {
  }

  async search(latitude, longitude, limit?, radius = RADIUS_EV_STATIONS): Promise<IndexedCollection<AARData>> {
    try {
      const aars = await this.xhr.authRequest<Array<AarDataApiModel>>({
        url: 'bff/tow-destinations/evsearch',
        params: {
          latitude,
          longitude,
          radius,
          ...(limit ? { limit } : {}),
        },
      })
      await AARSchema.validate({ aars })
      const aarsAdapted: AARData[] = aars.map(aar => this.aarDataAdapter.adapt(aar))
      return indexCollection<AARData, 'id'>(aarsAdapted, 'id')
    } catch (error) {
      throw error
    }
  }
}
