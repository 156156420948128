<div class="dialog">
  <h1 mat-dialog-title i18n>Ongoing Request</h1>

  <div id="dialog-content" mat-dialog-content>
    <p i18n>We are unable to complete this request because you may already have an active service request,</p>
    <p *ngIf="!isRapUser" i18n>please call AAA for assistance.</p>
    <p *ngIf="isRapUser" i18n>please call us for assistance:</p>
    <div>
      <app-call-aaa-button
        [clickEventPage]="pageType"
        (buttonClick)="onCallAAAClick()">
      </app-call-aaa-button>
    </div>
  </div>

  <div mat-dialog-actions>
    <app-button
      id="dialog-dismiss-submit"
      name="dialogConfirmCancelSubmit"
      type="button"
      inverted="true"
      (click)="onClose()"
      aria-label="dismiss this message"
      i18n-aria-label
      i18n
    >
      Dismiss
    </app-button>
    <app-button
      id="dialog-confirm-submit"
      name="dialogConfirmConfirmSubmit"
      type="button"
      (click)="onSubmit()"
      aria-label="Load Request"
      i18n-aria-label
      i18n
    >
      Load Request
    </app-button>
  </div>
  <button mat-dialog-close class="mat-dialog-close" aria-label="dismiss this message" i18n-aria-label>
    <icon-close></icon-close>
  </button>
</div>
