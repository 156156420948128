<ng-container *ngIf="(seeFacilitiesBanner$ | async) as banner">
  <div *ngIf="isBannerVisible"
    [ngClass]="{ applied: !banner.isSeeButtonVisible }"
    class="discount-banner">
    <p id="discount-text"
      [innerHTML]="banner.text"></p>
    <app-button i18n
      *ngIf="banner.isSeeButtonVisible"
      (buttonClick)="seeFacilities()"
      [color]="'primary-no-uppercase'"
      id="discount-button">See Facilities</app-button>
    <ng-template #discountApplied>
      <i i18n-alt
        [hidden]="!banner.hasFacilities"
        class="fa-solid fa-check discount-check"
        alt="Discount Applied"></i>
    </ng-template>
  </div>
</ng-container>
