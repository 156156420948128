<app-split-panel
  [ngClass]="{'mobile-wrap': !showMenu}"
  [showSecondaryOnMobile]="!!(step$ | async)"
>
  <ng-container *ngIf="isAbTestLoaded$ | async" primary [ngSwitch]="(step$ | async)">
    <ng-container *ngSwitchCase="'years'">
      <app-create-vehicle
        [isCreatingNew]="!(editYear$ | async)"
        [isMember]="isSecure$ | async"
      ></app-create-vehicle>
    </ng-container>
    <ng-container *ngSwitchCase="'makes'">
      <app-makes></app-makes>
    </ng-container>
    <ng-container *ngSwitchCase="'models'">
      <app-models></app-models>
    </ng-container>
    <ng-container *ngSwitchCase="'details'">
      <app-vehicle-details></app-vehicle-details>
    </ng-container>
    <ng-container *ngSwitchCase="'plate'">
      <app-add-plate></app-add-plate>
    </ng-container>
    <ng-container *ngSwitchCase="'confirm'">
      <app-vehicle-confirm></app-vehicle-confirm>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <ng-container *ngIf="(isPlateToVin$ | async) && !(memberVehicles$ | async).length; else defaultVehicle">
        <app-add-plate></app-add-plate>
      </ng-container>
      <ng-template #defaultVehicle>
        <app-select-vehicle></app-select-vehicle>
      </ng-template>
    </ng-container>
  </ng-container>

  <div secondary class="show-mobile">
    <app-vehicle-header ></app-vehicle-header>
  </div>

</app-split-panel>
