<h1 i18n role="heading" aria-level="1">Which vehicle needs service?</h1>

<ng-container *ngIf="((memberVehicles$ | async) || []); let vehicles">
  <ng-container *ngFor="let vehicle of vehicles; trackBy: trackByMethod; let index = index">
    <app-vehicle-summary
      *ngIf="!(editYear$ | async) || (workingVehicle$ | async).id === vehicle.id"
      [vehicle]="vehicle"
      (selectVehicle)="assignVehicle($event)"
      [tabIndexPosition]="300 + (index + 1)"
      [first]="index === 0"
    >
    </app-vehicle-summary>
  </ng-container>
  <div class="divider"></div>

  <app-loading
    *ngIf="isLoading$ | async"
    width="25"
    height="25"
    class="typeahead_icon-begin"
    inverted="true">
  </app-loading>
</ng-container>
<ng-container *ngIf="(memberVehicles$ | async).length; else createVehicle">
  <app-button class="create-vehicle-btn" [color]="'secondary'" (buttonClick)="addVehicle()" >
    <icon-add [color]="'white'"></icon-add> <ng-container i18n>Add a vehicle</ng-container>
  </app-button>
</ng-container>

<ng-template #createVehicle>
  <app-create-vehicle
    [isCreatingNew]="!(editYear$ | async)"
    [isMember]="isSecure$ | async"
    [tabIndexPosition]="400"
  >
  </app-create-vehicle>
</ng-template>

