import { NgModule } from '@angular/core'

import { AuthRoutingModule } from './auth-routing.module'

import { SharedModule } from '../../shared'
import { NgrxFormsModule } from 'ngrx-forms'
import { authComponents } from './auth.component.declarations'
import { IonicModule } from '@ionic/angular';
import { ButtonComponent, TextComponent } from '@aaa-mobile/shared-components';

@NgModule({
  declarations: [...authComponents()],
  imports: [
    NgrxFormsModule,
    AuthRoutingModule,
    SharedModule,
    IonicModule,
    ButtonComponent,
    TextComponent,
  ],
})
export class AuthModule {}
