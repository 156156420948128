<button
  #mainButton
  type="button"
  class="multiple-requests-button_container first-focusable-element"
  [attr.aria-label]="ariaLabel"
  [attr.aria-expanded]="isExpanded"
  (click)="toggleDropdown()"
  tabindex="1"
  i18n-aria-label
>
  <div class="multiple-requests-button_info">
    <app-img
      src="assets/drrweb-lib/images/icons/tow-small-blue.svg"
      classNames="icon"
      alt="Opened Requests"
      i18n-alt
    ></app-img>
    <label>{{ requests ? requests.length : '' }}</label>
  </div>
  <label i18n>Requests</label>
</button>
<ul
  class="multiple-requests-button_dropdown"
  [ngClass]="{ 'multiple-requests-button_dropdown-open': isExpanded }"
  [attr.aria-expanded]="isExpanded"
>
  <li *ngFor="let request of requests; trackBy: requestTrackByFn">
    <button type="button"
      (click)="select($event, request); mainButton.focus()"
      [attr.aria-label]="request?.pacesetterCode | issue: 'title'">
      <app-img
        src="{{ request.pacesetterCode | issue: 'icon' }}"
        alt="{{ request?.pacesetterCode | issue: 'title' }}"
      ></app-img>
      <div class="multiple-requests-button_details">
        <label>{{ request?.pacesetterCode | issue: 'title' }}</label>
        <p>ID: {{ request.callId }}</p>
        <p>Date: {{ request.callDate | customDate: 'yyyyMMdd':'MM/dd/yyyy' }}</p>
        <p>
          {{ request?.vehicle?.year }} {{ request?.vehicle?.make }}
          {{ request?.vehicle?.model }} ({{ request?.vehicle?.color }})
        </p>
      </div>
      <icon-arrow
        direction="right"
        *ngIf="requests && requests.length > 1"
      ></icon-arrow>
    </button>
  </li>
</ul>
