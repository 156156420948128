export interface CallDetailParams {
  callId: string,
  callDate: string,
  callToken: string,
  servicingClub: string
}

export enum EXTERNAL_SERVICES {
  GLYMPSE = 'GLYMPSE',
  BRINGG = 'BRINGG',
  APPOINTMENT_ASSISTANT = 'APPOINTMENT ASSISTANT',
  XPERIGO = 'XPERIGO',
}

export interface MessageStatus {
  title: string,
  description: string,
  active?: boolean,
}
