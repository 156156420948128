<section class="section-container">
  <div class="bottom-drawer-container">
    <app-dragger class="show-mobile"
                 [inverted]="true"
                 [target]="draggerTarget"
                 [collapsed]="isAnExternalServiceAndEnRoute$ | async">
    </app-dragger>
    <ng-container *ngIf="(isStatusUpdateSupported$ | async) && (selectMessageByCallStatus$ | async) as message">
      <div class="container-on-our-way">
        <div class="eta-container">
          <h3>{{ message.title }}</h3>
        </div>
        <p class="status-message">{{ message.description }}</p>
      </div>
    </ng-container>

    <app-call-status-tracker *ngIf="isStatusUpdateSupported$ | async" [statuses]="selectMessageByCallStatusTimeline$"></app-call-status-tracker>

    <div class="container-on-our-way">
      <app-service-summary-improvement></app-service-summary-improvement>
    </div>
    <div class="container-on-our-way">
      <p class="small-text color-darkgrey mb-10" *ngIf="isStatusUpdateSupported$ | async" i18n>*ETA is only an estimate. A real time ETA will be provided once your service provider is in route.</p>
      <ng-container *ngIf="advisories$ | async as advisories">
        <ng-container *ngIf="!(isRapUser$ | async)">
          <app-status-update *ngFor="let advisory of advisories"
                             [update]="advisory"></app-status-update>
        </ng-container>
      </ng-container>
    </div>
  </div>
</section>
