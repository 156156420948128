import { Component } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { AAAStore } from '../../../../store/root-reducer'
import { selectMobileHeaderText } from '../../auth.selectors'

@Component({
  selector: 'app-credentials-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.scss'],
})
export class MobileHeaderComponent {
  constructor(private store$: Store<AAAStore>) {}

  applicationText$ = this.store$.pipe(select(selectMobileHeaderText))
}
