import { Component, Input, Output, EventEmitter } from '@angular/core'
import { MemberInfo } from '../../../modules/member/member.types'

@Component({
  selector: 'app-home-location-button',
  templateUrl: './home-location-button.component.html',
  styleUrls: ['./home-location-button.component.scss'],
})
export class HomeLocationButtonComponent {
  @Input() member: MemberInfo
  @Input() isLoading = false
  @Input() singleLine = false
  @Output() locationClick: EventEmitter<MemberInfo> =
    new EventEmitter<MemberInfo>()
  @Input() tabIndex = 0
  @Input() label = 'HOME'

  handleClick() {
    this.locationClick.emit(this.member)
  }
}
