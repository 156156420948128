<h2 i18n role="heading" aria-level="1" class="large-title" aria-live="assertive">Select your vehicle's color</h2>

<div class="color_scroll-container">
  <ng-container *ngIf="!isScrollAtBegin && !isMobile()">
    <icon-arrow
    class="left-arrow"
    direction="left"
    colorName="white"
    (click)="scrollLeft()"
    (keypress)="scrollLeft()"
    tabindex="1">
  </icon-arrow>
</ng-container>
<div class="color_container" aria-live="assertive" #colorsContainer>
  <ng-container *ngFor="let colors of colorRows; let rowIndex = index">
    <div class="color_row" [ngClass]="{ last_row: rowIndex > 0 }">
      <ng-container *ngFor="let color of colors; let index = index;">
        <button *ngIf="color.code !== 'other'; else other"
          [tabindex]="400 + index"
          type="button"
          [title]="color.label"
          [attr.aria-label]="selectVehicleColorLabel + ' ' +  color.label"
          class="color_tile"
          [ngClass]="{
            'first-focusable-element': index === 0
          }"
          (click)="selectColor(color.label)">
          <div class="color_circle" [ngClass]="{ 'selected': color.label === selectedColor }">
            <div class="base-{{ color.code | lowercase }}">
              <div class="shade-{{ color.code | lowercase }}">
              </div>
            </div>
          </div>
          <div class="tiny-text color_label">{{ color.label | uppercase }}</div>
        </button>
      </ng-container>
    </div>
  </ng-container>
  <ng-template #other>
    <app-expanding-input class="color_circle other_circle"
      ariaLabel="Select other color"
      [tabIndex]="400 + maxTabIndex"
      (textChange)="selectColor($event)"
      [ngClass]="{ 'selected': otherColor === selectedColor }"
      [maxChars]="maxChars">
      <div class="tiny-text color_label other_label">
        <div class="inner_label">{{ otherColor || 'OTHER' | uppercase}}</div>
      </div>
  </app-expanding-input>
</ng-template>
</div>
<ng-container *ngIf="!isScrollAtEnd && !isMobile()">
  <icon-arrow
    class="right-arrow"
    direction="right"
    colorName="white"
    (click)="scrollRight()"
    (keypress)="scrollRight()"
    tabindex="1">
  </icon-arrow>
</ng-container>
</div>
