<div class="input-zip-code">
  <input #inputElement name="zipCode"
         i18n-placeholder
         id="{{id}}"
         type="{{type}}"
         placeholder="Enter Your Postal Code"
         [mask]="mask"
         [ngrxFormControlState]="controls.zipCode"
         aria-required="true"
         autocomplete="postal-code"
         required/>
  <span class="keyboard" (click)="switchKeyboard()" *ngIf="type === 'tel' else numpad">
    <icon-keyboard></icon-keyboard>
  </span>
  <ng-template #numpad>
    <span class="numpad" (click)="switchKeyboard()">
      <icon-numpad></icon-numpad>
    </span>
  </ng-template>
</div>

<app-form-error i18n
                [control]="controls?.zipCode"
                name='zipCode'
                type='pattern'> Postal Code is invalid.
</app-form-error>
<app-form-error i18n
                [control]="controls.zipCode"
                name='zipCode'
                type='required'> Postal Code is required.
</app-form-error>
