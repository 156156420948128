import { Component, Input, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations'
import { MessageStatus } from '../calls-statuses/call-status.types'
import { CALL_STATUS_ITEM_STATE } from '../calls.types'
import { AbstractComponent } from '../../../shared/abstract.component'
import { Observable } from 'rxjs'

@Component({
  selector: 'app-call-status-timeline',
  templateUrl: './call-status-timeline.component.html',
  styleUrls: ['./call-status-timeline.component.scss'],
  animations: [
    trigger('moveDown', [
      state('start', style({
        transform: 'translateY(-57px)'
      })),
      state('end', style({
        transform: 'translateY(0)'
      })),
      transition('start => end', animate('1500ms ease-in-out'))
    ])
  ]
})
export class CallStatusTimelineComponent extends AbstractComponent implements OnInit {

  @Input() statuses: Observable<MessageStatus[]>
  timeline: CALL_STATUS_ITEM_STATE[]

  animationState: 'start' | 'end' = 'start';

  constructor() {
    super()
  }

  ngOnInit(): void {
    if (this.statuses) {
      this.subscriptions.push(
        this.statuses.subscribe(statuses => {
          this.updateTimeline(statuses)
        })
      )
    }
  }

  updateTimeline(statuses: MessageStatus[]) {
    if (!statuses?.length) {
      return
    }
    this.timeline = []
    this.animationState = 'start'
    let beforeActive = true
    statuses.forEach((status, index) => {
      if (index < statuses.length - 1) {
        if (status.active) {
          beforeActive = false
          this.timeline.push(CALL_STATUS_ITEM_STATE.ACTIVE)
        } else {
          this.timeline.push(beforeActive ? CALL_STATUS_ITEM_STATE.COMPLETED : CALL_STATUS_ITEM_STATE.PENDING)
        }
      }
    })
    setTimeout(() => {
      this.animationState = 'end'
    }, 0)
  }
}
