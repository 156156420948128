import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-call-status-item',
  templateUrl: './call-status-item.component.html',
  styleUrls: ['./call-status-item.component.scss']
})
export class CallStatusItemComponent {

  @Input() title: string;
  @Input() description: string;
  @Input() active: boolean;

  constructor() {}

}
