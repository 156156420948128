import { createFeatureSelector, createSelector, defaultMemoize, MemoizedProjection } from '@ngrx/store'

import { SelectorFactory } from '../../shared/types'
import { indexCollection } from '../../shared/utils'
import { selectModeConfiguration } from '../auth/auth.selectors'
import { VendorConfigurationMode } from '../auth/mode-configuration.types'
import { PACE_SETTER_SITUATION_TYPES, PaceSetterCode, PRIORITY_CODES } from '../issue/issue.types'
import { selectLocationClub } from '../location/location.selectors'
import { TOW_DISTANCE } from '../location/location.types'
import { selectMemberShipLevel } from '../member/member.selectors'
import { MembershipLevelsSettings } from '../member/member.types'
import { ServicingClubState } from './servicing-club.reducer'
import {
  ClubMembershipTowing,
  OverrideOptions,
  PACE_SETTER_OVERRIDE_OPTIONS,
  PACE_SETTER_OVERRIDE_TYPES,
  ServiceConfigurationSetting,
  ServicesConfiguration,
  ServicingClubConfig
} from './servicing-club.types'

export const AAA_DEFAULT_PHONE_NUMBER = '800-222-4357'
export const CONFIG_IS_ENABLED = 'DRRENABLED'
export const DEFAULT_MEMBER_LEVELS = Object.values(TOW_DISTANCE)
  .filter((value) => typeof value === 'number')
  .map(Number)

const stateKey = 'servicingClub'

export const selectServicingClubState = createFeatureSelector<ServicingClubState>(stateKey)

export const createSelectConfigByServicingClub = createSelector(
  selectServicingClubState,
  (state): SelectorFactory<ServicesConfiguration> =>
    defaultMemoize((club: string) => state.club[club])
)

export const selectIsEnabled = createSelector(
  createSelectConfigByServicingClub,
  selectLocationClub,
  (selectConfigByServicingClub, club): boolean => {
    const config =
      selectConfigByServicingClub.memoized(club)?.configurationGroup
        ?.configurationItem

    if (!config) {
      return true
    }

    const isEnabledConfig = config.find(
      (item) => item.name.toUpperCase() === CONFIG_IS_ENABLED
    )

    return typeof isEnabledConfig?.enabled === 'boolean'
      ? isEnabledConfig?.enabled
      : true
  }
)

export const selectIssueConfig = createSelector(
  createSelectConfigByServicingClub,
  selectLocationClub,
  (selectConfigByServicingClub, club)  =>
    (issueType: PACE_SETTER_SITUATION_TYPES) => {
    const config =
      selectConfigByServicingClub.memoized(club)?.configurationGroup
        ?.configurationItem

    if (!config) {
      return null
    }

    return config.find(
      (item) => item.name.toUpperCase() === issueType
    )
  }
)

export const selectTowClubSettings = createSelector(
  createSelectConfigByServicingClub,
  selectLocationClub,
  (
    selectConfigByServicingClub,
    club
  ): Array<ServiceConfigurationSetting> | null => {
    const configByClub = selectConfigByServicingClub.memoized(club)

    if (!configByClub) {
      return null
    }

    return configByClub.settings?.setting?.filter((setting) =>
      setting.name.startsWith('tow')
    )
  }
)

export const selectClubTowSettingLevels = createSelector(
  selectTowClubSettings,
  (towSettings): Array<number> =>
    towSettings
      ? Object.keys(
          indexCollection<ServiceConfigurationSetting, 'value'>(
            towSettings.filter((setting) => Number(setting.value)),
            'value'
          )
        ).map(Number)
      : Object.values(TOW_DISTANCE)
          .filter((value) => typeof value === 'number')
          .map(Number)
)

export const selectHasTowClubSettings = createSelector(
  selectTowClubSettings,
  selectClubTowSettingLevels,
  (towClubSettings,towClubSettingLevels): boolean =>
    Boolean(towClubSettings?.length)
    && Boolean(towClubSettingLevels?.length)
)

export const selectClubTowSettingByMemberLevel = createSelector(
  selectTowClubSettings,
  selectMemberShipLevel,
  (towClubSettings, memberShipLevel): ClubMembershipTowing | null => {
    if (!towClubSettings || !memberShipLevel) {
      return null
    }

    const level = memberShipLevel.replace(/\s/g, '').toUpperCase()
    const memberLevel: MembershipLevelsSettings =
      MembershipLevelsSettings[level]
    const settingsByLevel = towClubSettings
      .filter((settings: ServiceConfigurationSetting) =>
        settings.name.match(
          new RegExp(`^tow(Mileage|Description)${memberLevel}$`, 'g')
        )
      )
      .reduce((towingLimit, setting) => {
        if (setting.name === `towMileage${memberLevel}` && setting.value) {
          return {
            ...towingLimit,
            distance: Number(setting.value),
          }
        }

        if (setting.name === `towDescription${memberLevel}` && setting.value) {
          return {
            ...towingLimit,
            message: setting.value,
          }
        }

        return towingLimit
      }, {})

    return Object.keys(settingsByLevel).length ? settingsByLevel : null
  }
)

export const selectServicingClubHighwayPriorityCode = createSelector(
  selectServicingClubState,
  selectLocationClub,
  (state: ServicingClubState, club: string): string | null => {
    if (!club) {
      return null
    }
    const configs = state.club[club]?.settings?.setting?.filter(
      (settings: ServiceConfigurationSetting) =>
        settings.name === ServicingClubConfig.HIGHWAY_PRIORITY_CODE_NAME
    )
    return configs && configs[0]?.value
  }
)

export const selectServicingClubAccidentPriorityCode = createSelector(
  selectServicingClubState,
  selectLocationClub,
  (state: ServicingClubState, club: string): string | null => {
    if (!club) {
      return null
    }
    const configs = state.club[club]?.settings?.setting?.filter(
      (settings: ServiceConfigurationSetting) =>
        settings.name === ServicingClubConfig.ACCIDENT_PRIORITY_CODE
    )
    return configs && configs[0]?.value || PRIORITY_CODES.P6
  }
)

export const selectIsPacesetterOverridden = createSelector(
  selectServicingClubState,
  selectLocationClub,
  (state: ServicingClubState, club: string) =>
    (code: string, pacesetterCode: PACE_SETTER_OVERRIDE_TYPES): boolean => {
    if (!club) {
      return null
    }
    return !!(state.club[club]?.settings?.setting?.find(
      ({name, value}) => name === pacesetterCode && value === code
    ))
  }
)

export const selectServicingClubPacesetterAccident = createSelector(
  selectServicingClubState,
  selectLocationClub,
  (state: ServicingClubState, club: string): string | null => {
    if (!club) {
      return null
    }
    return state.club[club]?.settings?.setting?.find(
      ({name}) => name === PACE_SETTER_OVERRIDE_TYPES.ACCIDENT
    )?.value || null
  }
)

export const selectDefaultPhoneNumber = createSelector(
  selectModeConfiguration,
  (configMode: VendorConfigurationMode): string => {
    if (configMode) {
      const localeId = localStorage.getItem('locale')
      const i18nPhoneNumber = configMode.contactPhones?.[localeId]
      return i18nPhoneNumber || configMode.contactPhone
    }
    return AAA_DEFAULT_PHONE_NUMBER
  })

export const selectServicingClubRoadServicePhoneNumber = createSelector(
  selectServicingClubState,
  selectLocationClub,
  selectDefaultPhoneNumber,
  selectModeConfiguration,
  (state: ServicingClubState, club: string, defaultPhoneNumber: string, config: VendorConfigurationMode): string => {
    if (!club || config) {
      return defaultPhoneNumber
    }
    const configs = state.club[club]?.settings?.setting?.filter(
      (settings: ServiceConfigurationSetting) =>
        settings.name === ServicingClubConfig.ROAD_SERVICE_PHONE_NUMBER
    )
    return (configs && configs[0]?.value) || defaultPhoneNumber
  }
)

export const selectIsServicingClubConfigsSet = createSelector(
  createSelectConfigByServicingClub,
  selectLocationClub,
  (selectConfigByServicingClub: MemoizedProjection, club: string): boolean =>
    Boolean(selectConfigByServicingClub.memoized(club))
)

export const selectIsBatteryQuoteEnabled = createSelector(
  selectServicingClubState,
  selectLocationClub,
  (state: ServicingClubState, club: string): boolean => {
    if (!club) {
      return null
    }

    const config = state.club[club]?.settings?.setting?.find(
      (settings: ServiceConfigurationSetting) =>
        settings.name === ServicingClubConfig.ROAD_SERVICE_BATTERY_QUOTE
    )

    return config && config?.value.toLowerCase() === 'y'
  }
)

export const selectClubOverridePaceSetterCode = createSelector(
  selectServicingClubState,
  selectLocationClub,
  (state: ServicingClubState, club: string) =>
    defaultMemoize((paceSetterCode: PaceSetterCode): OverrideOptions => {

      if (!club || !paceSetterCode) {
        return null
      }

      const activePaceSetterCode = paceSetterCode.paceSetterCode
      const clubSettings = state.club[club]?.settings?.setting || []
      const paceSetterOverrideKeys = Object.keys(PACE_SETTER_OVERRIDE_OPTIONS)

      const activePaceSetterCodeOverrideKey = paceSetterCode.paceSetterOverrideKey ||
        paceSetterOverrideKeys.find(
        (key) => {
          const setting = PACE_SETTER_OVERRIDE_OPTIONS[key]
          return (
            setting?.parentCode?.toLowerCase() ===
            activePaceSetterCode?.toLowerCase()
          )
        }
      )

      if (!activePaceSetterCodeOverrideKey) {
        return null
      }

      const activePaceSetterCodeOverride = clubSettings.find(
        (setting) =>
          activePaceSetterCodeOverrideKey.toLowerCase() ===
          setting.name.toLowerCase()
      )

      if (!activePaceSetterCodeOverride) {
        return null
      }

      const validIndicators = ['yes', 'y']
      const paceSetterOverride =
        PACE_SETTER_OVERRIDE_OPTIONS[activePaceSetterCodeOverrideKey]

      const towingSetting = clubSettings.find(
        (setting) =>
          paceSetterOverride.towIndicator.toLowerCase() ===
          setting.name.toLowerCase()
      )
      const towingEnabled = towingSetting?.value ?
        validIndicators.indexOf(towingSetting.value.toLowerCase()) !== -1 :
        activePaceSetterCodeOverrideKey === PACE_SETTER_OVERRIDE_TYPES.ACCIDENT

      return {
        requiresTowing: towingEnabled,
        paceSetterCode: activePaceSetterCodeOverride.value,
        overrideType: activePaceSetterCodeOverrideKey,
      }
    })
)

export const selectTowAlertRV = createSelector(
  selectServicingClubState,
  selectLocationClub,
  (state: ServicingClubState, club: string): ServiceConfigurationSetting => {
    if (!club) {
      return null
    }

    return state.club[club]?.settings?.setting?.find(
      (settings: ServiceConfigurationSetting) =>
        settings.name === 'towAlertMessageRV'
    )
  }
)
