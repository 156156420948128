<ng-container *ngIf="(step$ | async) === 'passengers'; else tow">
  <div class="passengers-section">
    <app-indicate-passengers primary></app-indicate-passengers>
  </div>
</ng-container>

<ng-template #tow>

  <div class="tow-location-container">
    <!-- Selected address - Map and Action holders -->
    <ng-container *ngIf="!isMobile || isAddressValid">
      <div class="tow-location-map">
        <div class="map-container">
          <ng-container *ngIf="!isMobile && !isAddressValid; else mapTowSelected">
            <app-map-modal
              id="desktop-map-modal"
              [popupMode]="false"
              [useSearchArea]="!isRapUser"
              [facilitiesDisplay]="facilitiesDisplay$ | async"
              [selectedShop]="towLocation"
              [userCoords]="breakdownCoordinates$ | async"
              view='TOWING_DESTINATION'
              [isOpen]="true">
            </app-map-modal>
          </ng-container>
          <ng-template #mapTowSelected>
            <ng-container *ngIf="!selectedShop && !isRapUser && !isEV">
              <app-tow-location-banner [towLocation]="towLocation"
                (seeFacilitiesClicked)="handleSeeFacilitiesClick($event)">
              </app-tow-location-banner>
              <ng-container *ngIf="!isRapUser && !isEV && (facilitiesDisplay$ | async).aarData.length > 0">
                <div class="pin-legend__container">
                  <div class="pin-legend__item">
                    <icon-aar-destination-pin></icon-aar-destination-pin>
                    <span i18n>AAA Approved Facility</span>
                  </div>
                  <div class="pin-legend__item">
                    <icon-aar-destination-pin [isCOR]="true"></icon-aar-destination-pin>
                    <span i18n>AAA Owned Facility</span>
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <app-map #map
              view='TOWING_DESTINATION'
              [static]="true"
              [preventTouch]="false"
              [breakdownMarker]="breakdownMarker$ | async"
              [facilitiesDisplay]="facilitiesDisplayToMap$ | async"
              [center]="breakdownMarker$ | async"
              [(towDestinationMarker)]="towLocation"
              (addressSelected)="handleAddressSelected($event)"
              [enableTowDestination]="true"
              [tabIndexPosition]="401"
              [mapBoundsPadding]="{ top: 130, bottom: 50 }"
              [isLoading]="isLoading$ | async"
              (onShopDetailsClose)="sendPageLoadEvent(true)"
            >
            </app-map>
          </ng-template>
        </div>

        <div class="tow-location-map-options" [ngClass]="{'desktop-margin': !isMobile}" *ngIf="isMobile || isAddressValid && !selectedShop && !(aarPreview$ | async)">
          <ng-container *ngIf="!selectedShop">
            <div class="tow-location-header_search">
              <ng-template [ngTemplateOutlet]="searchAutoComplete"></ng-template>
            </div>
          </ng-container>

          <ng-container *ngIf="!!selectedShop">
            <div class="tow-location-selected">
              <app-tow-location-selected [tabIndex]="432"
                (applyDiscountBanner)="handleAddressSelected('')"
                (onShopDetailsClose)="sendPageLoadEvent(true)">
              </app-tow-location-selected>
            </div>
          </ng-container>

          <ng-container *ngIf="isAddressValid">
            <app-button
              i18n
              id="tow-location-next-btn"
              class="tow-location-next-btn"
              (buttonClick)="handleNext()"
              [color]="'primary-yellow'"
              [hasShadow]="true"
              [disabled]="isLoading$ | async"
              type="button">TOW TO THIS LOCATION</app-button>
          </ng-container>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!isAddressValid || !isMobile">
      <div class="tow-location-header"
        [ngClass]="{ 'remove-bg-color': isAddressValid }"
        #towLocationHeader>
        <ng-container *ngIf="!isMobile && (aarPreview$ | async); else searchLocation">
          <div class="desktop-selected-facility">
            <app-tow-location-selected (onShopDetailsClose)="sendPageLoadEvent(true)">
            </app-tow-location-selected>
          </div>
          <app-button
            i18n
            id="tow-location-next-btn"
            class="tow-location-next-btn"
            (buttonClick)="handleNext()"
            [color]="'primary-yellow'"
            [hasShadow]="true"
            [disabled]="isLoading$ | async"
            type="button">TOW TO THIS LOCATION</app-button>
        </ng-container>
        <ng-template #searchLocation>
          <h1 *ngIf="!isAddressValid"
            role="heading"
            aria-level="1"
            class="tow-location-header_title"
            [innerHtml]="getHeadingTitle()">
          </h1>
          <ng-container *ngIf="!isEV && !isAddressValid && !isRapUser">
            <div class="check-list__container">
              <ul class="check-list">
                <li *ngFor="let item of getShopItems()">
                  <icon-check></icon-check>
                  <span>{{ item }}</span>
                </li>
              </ul>
              <app-img i18n-alt
                src="assets/drrweb-lib/images/tow-truck_x2.png"
                class="battery-quotes-banner__icon"
                alt="Battery Quotes"></app-img>
            </div>
          </ng-container>
        </ng-template>
      </div>

      <!-- Promo Banner -->
      <ng-container *ngIf="(facilitiesDisplay$ | async)?.aarData.length && !isRapUser && !isEV && !isAddressValid">
        <section class="tow-location-coupon"
          [ngClass]="{'desktop-margin-promo-banner': !isMobile }">
          <app-tow-location-promo-v2 [promoView]="'COUPON'"></app-tow-location-promo-v2>
        </section>
      </ng-container>


      <div [ngClass]="{ 'spacer': isAddressValid }"></div>

      <ng-container *ngIf="!selectedShop && isMobile || !isAddressValid">
        <section class="tow-location-body"
          [ngClass]="{'desktop-margin-list-items': !isMobile}"
          #towLocationBody>
          <div class="map-btn-container"
            *ngIf="isMobile && ((shouldDisplayMapBtn$ | async) || !isAddressValid)">
            <app-button (buttonClick)="showMap(true)"
              id="tow-location-map-btn"
              [color]="'secondary-no-uppercase'"
              [hasShadow]="true">
              <i i18n-alt class="fa-solid fa-map left-icon-button" alt="Map View"></i>
              <ng-container i18n>Map</ng-container>
            </app-button>
          </div>

          <!-- Filters -->
          <ng-container *ngIf="!(isRapUser) && !(isEV)">
            <app-tow-location-filters>
            </app-tow-location-filters>
          </ng-container>

          <!-- No Facilities / Loading -->
          <ng-container *ngIf="isLoading$ | async">
            <div class="tow-location-body_no-results">
              <app-location-skeleton-item [items]="5"></app-location-skeleton-item>
            </div>
          </ng-container>

          <!-- No Shops / Custom message -->
          <ng-container *ngIf="(facilitiesDisplay$ | async)?.aarData.length && (aarsCustomMessage$ | async)">
            <div i18n class="tow-location-body_no-results">
              Please verify the selected shop services your vehicle
            </div>
          </ng-container>

          <!-- Facilities -->
          <ng-container *ngIf="(facilitiesDisplay$ | async)?.aarData.length &&  !(isLoading$ | async)">
            <!-- List -->
            <ng-container *ngFor="let aar of (facilitiesDisplay$ | async).aarData; let aarIndex = index">
              <app-location-list-item [location]="aar"
                [tabIndex]="435 + aarIndex"
                (onShopDetailsClose)="sendPageLoadEvent(true)"
                *ngIf="!showSeeMoreButton || aarIndex < 5">
              </app-location-list-item>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!(facilitiesDisplay$ | async)?.aarData.length &&  !(isLoading$ | async)">
            <p i18n>No Nearby Facilities</p>
          </ng-container>

          <ng-container *ngIf="showSeeMoreButton">
            <app-see-more-button (buttonClick)="handleSeeMoreList()"
              [ngClass]="{ 'extra-bottom-padding': !allowCustomTowDestination }"></app-see-more-button>
          </ng-container>
        </section>
        <ng-container *ngIf="allowCustomTowDestination">
          <section class="tow-location-footer">
            <h2 *ngIf="!isAddressValid"
              role="heading"
              aria-level="2"
              class="tow-location-header_subtitle"
              [innerHtml]="getHeadingSubtitle()">
            </h2>
            <ng-container *ngIf="!isAddressValid && allowCustomTowDestination">
              <div class="tow-location-header_search">
                <ng-template [ngTemplateOutlet]="searchAutoComplete"></ng-template>
              </div>
            </ng-container>
          </section>
        </ng-container>
      </ng-container>
    </ng-container>

    </div>

  <!-- Map Modal -->
  <ng-container>
    <app-map-modal
      [useSearchArea]="!isRapUser"
      (closeMapModalClick)="showMap(false)"
      [facilitiesDisplay]="facilitiesDisplay$ | async"
      [selectedShop]="towLocation"
      [userCoords]="breakdownCoordinates$ | async"
      view='TOWING_DESTINATION'
      (onShopDetailsClose)="sendPageLoadEvent(true)"
      [isOpen]="showMapModal">
    </app-map-modal>
  </ng-container>

  <!-- Reusable autocomplete search template -->
  <ng-template #searchAutoComplete>
    <app-location-auto-complete
      i18n-placeholder
      [view]="SearchViewsType.TOWING_DESTINATION"
      [pinIconColor]="isAddressValid ? 'green' : 'red'"
      [model]="towLocation?.address"
      placeholder="Search for a location..."
      [isLoading]="false"
      (addressSelected)="handleAddressSelected($event)"
      [tabIndex]="431"
      [customElement]="true"
      [lastSearch]="(lastSearchLocation$ | async)"
      [suggestedShopTemplate]="(suggestedShop$ | async) ? suggestedShop : null"
      (onBackButton)="autoCompleteBackButton()"
    >
    </app-location-auto-complete>
  </ng-template>

</ng-template>

<ng-template #suggestedShop>
  <div class="suggested-shop-template">
    <p i18n class="promotion-text small-text">AAA Shop near to you:</p>
    <app-tow-location-item
      [location]="suggestedShop$ | async"
      type="summary"
      [showAaaDiscount]="true"
      [closable]="false"
      (summaryClick)="handleSuggestedShopClick()"
    >
    </app-tow-location-item>
    <div class="promotion-see-more" (click)="handleSeeMore($event)">
      <p i18n>See more</p>
    </div>
  </div>
</ng-template>
