import { Injectable } from '@angular/core'

import {
  MemberNumberAuthRequestParams,
  SecureAuthRequestParams,
  BaseAuthRequestParams,
  AuthResponse,
  SearchSigninParams,
  MembershipNumberAuthRequestParams,
  SearchInfoSigninParams, RapAuthResponse, SearchIdPhoneNumberParams
} from './auth.types';
import { XHRService } from '../../shared/services/xhr.service'
import { TokenSchema } from './auth.model'

export const DEFAULT_VENDOR = 'RSOWeb'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private xhr: XHRService) {
  }

  async secureSignin({
                       captchaToken,
                       memId,
                       ets,
                       vendor = DEFAULT_VENDOR,
                     }: BaseAuthRequestParams & SecureAuthRequestParams) {
    try {
      const response = await this.xhr.request<AuthResponse>({
        url: 'v2/member/verification',
        data: {
          captchaToken,
          memberNumber: memId,
          ets,
          vendor,
        },
        method: 'POST',
      })
      await TokenSchema.validate(response)
      return response
    } catch (error) {
      throw error
    }
  }

  async secureMembershipNumberSignin(
    {
      memberNumber,
    }: MembershipNumberAuthRequestParams) {
    try {
      const response = await this.xhr.request<AuthResponse>({
        url: 'v2/member/verification/partner',
        data: {
          memberNumber,
        },
        method: 'POST',
      })
      await TokenSchema.validate(response)
      return response
    } catch (error) {
      throw error
    }
  }

  async unsecureSignin({
                         captchaToken,
                         memberNumber,
                         zipCode,
                       }: BaseAuthRequestParams & MemberNumberAuthRequestParams) {
    try {
      const response = await this.xhr.request<AuthResponse>({
        url: 'v2/member/validation',
        data: {
          captchaToken,
          memberNumber,
          zipCode,
        },
        method: 'POST',
      })
      await TokenSchema.validate(response)
      return response
    } catch (error) {
      throw error
    }
  }

  async searchSignin({searchId, resultId}: SearchSigninParams) {
    try {
      const response = await this.xhr.request<AuthResponse>({
        method: 'POST',
        url: `v2/member/search/${searchId}/${resultId}`,
        data: {
          searchId,
          resultId,
        },
      })
      await TokenSchema.validate(response)
      return response
    } catch (error) {
      throw error
    }
  }

  async searchSigninInfo({searchId, streetNumber, firstName, lastName}: SearchInfoSigninParams) {
    try {
      const response = await this.xhr.request<AuthResponse>({
        method: 'POST',
        url: `v2/member/searchByInfo/${searchId}`,
        data: {
          streetNumber,
          firstName,
          lastName
        },
      })
      await TokenSchema.validate(response)
      return response
    } catch (error) {
      throw error
    }
  }

  async selectBySearchIdAndPhoneNumber(data: SearchIdPhoneNumberParams) {
    try {
      const response = await this.xhr.request<AuthResponse>({
        method: 'POST',
        url: `v2/member/selectByPhoneNumber`,
        data,
      })
      await TokenSchema.validate(response)
      return response
    } catch (error) {
      throw error
    }
  }

  async oemValidation(
    captchaToken,
    vin: string,
    mileage: number,
    manufacturer: string,
    referenceId: string,
    country: string,
  ): Promise<RapAuthResponse> {
    try {
      return await this.xhr.request<RapAuthResponse>({
        url: `/rap/validation/oem`,
        data: {
          captchaToken,
          vin,
          odometer: mileage,
          manufacturer,
          referenceId,
          ...(country ? {country} : {}),
        },
        method: 'POST',
      })
    } catch (error) {
      throw error
    }
  }

  async rentalValidation(
    captchaToken,
    rentalAgreement: string,
    referenceId: string
  ) {
    try {
      const rentalValidation = await this.xhr.request<AuthResponse>({
        url: `/rap/validation/rental`,
        data: {
          captchaToken,
          rentalAgreement,
          referenceId
        },
        method: 'POST',
      })
      return {rentalValidation, captchaToken}
    } catch (error) {
      throw error
    }
  }

  async vasValidation(
    captchaToken,
    subscriberId: string,
    referenceId: string
  ) {
    try {
      const vasValidation = await this.xhr.request<AuthResponse>({
        url: `/rap/validation/vas`,
        data: {
          captchaToken,
          subscriberId,
          referenceId
        },
        method: 'POST',
      })
      return {vasValidation, captchaToken}
    } catch (error) {
      throw error
    }
  }

  async rapVerification(
    access_token,
    odometer?: number
  ): Promise<RapAuthResponse> {
    try {
      return await this.xhr.request<RapAuthResponse>({
        url: `/rap/verification`,
        data: odometer !== undefined ? {odometer} : {},
        method: 'POST',
        headers: {
          Authorization: `Bearer ${access_token}`
        },
      })
    } catch (error) {
      throw error
    }
  }


}
