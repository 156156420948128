import { InjectionToken } from '@angular/core'

export interface SharedConfig {
  drrBaseHref: string
  aaaConfigurationServicesUrl: string
  aaaQuoteServicesUrl: string
  additionalCookieDomain: boolean
  association: string
  baseURL: string
  clientId: string
  configTools: boolean
  description: string | boolean
  displayVersion: boolean
  errorReportingID: string
  errorReportingSource: string
  errorReportingToken: string
  errorReportingURL: string
  googleCaptchaKey: string
  googleMapsApiKey: string
  rapConfigurationBaseUrl: string
  rapGoogleMapsApiKey: string
  thirdPartyCookiesUrl: string
  unsecureClub: string
  nativeAppView: boolean,
  homeBackButtonRedirectTo: string,
  optimize: boolean,
  log: boolean,
  openAARsURL: string,
  ipInterrogateURL: string,
  hotjar: string,
  datastreamId: string,
  orgId: string
}

export const SHARED_CONFIG = new InjectionToken<SharedConfig>(
  'This is a configuration object for the shared library'
)

export const DRR_BASE_HREF = new InjectionToken<string>(
  'This is the root of the drr web application'
)
