<div class="dialog">
  <h1 mat-dialog-title i18n>Alert</h1>

  <div class="dialog-content">
    <p class="text-message">
      {{ data?.params?.message }}
    </p>
    <div class="dialog-actions">
      <app-call-aaa-button
        [clickEventPage]="pageType">
      </app-call-aaa-button>

      <app-link-button
        class="dismiss-button"
        (clickLink)="onClose()"
        size="condensed"
        fontSize="14px"
        [ariaLabel]="'Continue'"
        i18n
      >
        Continue
      </app-link-button>
    </div>
  </div>

  <button mat-dialog-close class="mat-dialog-close" aria-label="dismiss this message" i18n-aria-label>
    <icon-close></icon-close>
  </button>
</div>
