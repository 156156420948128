import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AAAStore } from '../../../store/root-reducer';
import { selectIsRapUser } from '../../auth/auth.selectors';

@Component({
  selector: 'app-tracking-info',
  templateUrl: './tracking-info.component.html',
  styleUrls: ['./tracking-info.component.scss']
})
export class TrackingInfoComponent {
  isRapUser$: Observable<boolean> = this.store$.pipe(select(selectIsRapUser))

  constructor(
    private store$: Store<AAAStore>
  ) { }

}
