import { Component, Input } from '@angular/core'
import { RapService } from '../../rap/rap.service'
import { Store, select } from '@ngrx/store'
import { AAAStore } from '../../../store/root-reducer'
import { selectIsCouponApplied } from '../../ui/ui.selectors'
import { Observable } from 'rxjs'
import { setIsCouponApplied } from '../../ui/ui.actions'
import { TaggingService } from '../../tagging/tagging.service'
import events from '../../tagging/events'
import { AdobeEventService } from '../../tagging/adobe/event-adobe.service'
import { AdobeEventTypes } from '../../tagging/tagging.types'

export enum promoViews {
  DEFAULT = 'DEFAULT',
  DOLLAR = 'DOLLAR',
  COUPON = 'COUPON',
}

@Component({
  selector: 'app-tow-location-promo-v2',
  templateUrl: './tow-location-promo-v2.component.html',
  styleUrls: ['./tow-location-promo-v2.component.scss'],
})
export class TowLocationPromoV2Component {
  isRapUser = this.rapService.isRapUser()
  isLoading = false

  isCouponApplied$: Observable<boolean> = this.store$.pipe(
    select(selectIsCouponApplied)
  )

  @Input() promoView: promoViews = promoViews.DEFAULT

  constructor(
    private rapService: RapService,
    private store$: Store<AAAStore>,
    private tagging: TaggingService,
    private adobeEventService: AdobeEventService
  ) {}

  applyCoupon(payload: boolean) {
    this.isLoading = payload
    setTimeout(() => {
      this.isLoading = false
      this.store$.dispatch(setIsCouponApplied({ payload }))
      this.tagging.setClickEvent(
        events.towTo.DESTINATION_COUPON_APPLIED,
        events.towTo.DESTINATION_PAGE_TYPE,
      )
      this.adobeEventService.sendEvent({
        eventName: AdobeEventTypes.CTA,
        eventValue: events.towTo.DESTINATION_COUPON_APPLIED,
      })
    }, 1000)
  }
}
