import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Params } from '@angular/router'
import { ROUTER_NAVIGATION, RouterNavigatedAction } from '@ngrx/router-store'
import { getRouterActionQueryParams, getURLQueryParams, } from '../../shared/utils/query-params'
import { Action } from '@ngrx/store'
import { setCookie } from '../../shared/utils/cookies'
import { AppId, AppWhiteLabelId, AuthMethods } from '../auth/auth.types'
import { from, of } from 'rxjs'
import { ErrorReportingService } from '../../shared/services/error-reporting.service'
import { PayloadedAction } from '../../shared/types'
import { catchError, concatMap, map, switchMap, take } from 'rxjs/operators'
import {
  setABTestParam,
  setChannel,
  setCountry,
  setDistanceUnit,
  setFirstTimeAccess,
  setMockCaptcha,
  setPreProdEnv,
  setQueryParamsVehicleData,
  setShowMenu,
  setTelematics,
  setUserDefaultCoords,
  setUserDefaultZipCode
} from '../ui/ui.actions'
import {
  setAlertSessionTimeoutInterval,
  setCallStatusInterval,
  setCheckSessionTimeoutInterval,
  setCreateCallTimeout,
  setFirstCallStatusTimeout,
  setGetActiveCallsTimeout,
  setSessionTimeoutInterval,
} from '../ui/timeout/timeout.actions'
import {
  assignEncryptedMemberNumberParams,
  assignSecureParams,
  setAgent,
  setAgentFirstName,
  setAgentLastName,
  setAgentSettings,
  setAuth,
  setAuthMethod
} from '../auth/auth.actions'
import { AppConfigParamsKeys, AuthConfigParamsKeys, UIConfigParamsKeys } from './config.types'
import { SET_APP_ID, setAppId } from './config.actions'
import { Vehicle } from '../member/member.types'
import { ABTestParams } from '../ui/ui.types'
import { DISTANCE_UNIT } from '../../shared/i18n/i18n.types'

@Injectable()
export class ConfigEffects {

  private defaultTruthyValues = ['yes', 'y']
  private defaultFalsyValues = ['no', 'n']

  defaultQueryParams = {
    [AppConfigParamsKeys.APP_ID]: '',
    [AppConfigParamsKeys.DEFAULT_LATITUDE]: '',
    [AppConfigParamsKeys.DEFAULT_LONGITUDE]: '',
    [AppConfigParamsKeys.DEFAULT_ACCURACY]: '',
    [AppConfigParamsKeys.PREPROD]: '',
    [AppConfigParamsKeys.ZIP_CODE]: '',
    [AppConfigParamsKeys.AGENT_ID]: '',
    [AppConfigParamsKeys.AGENT_FIRST_NAME]: '',
    [AppConfigParamsKeys.AGENT_LAST_NAME]: '',
    [AppConfigParamsKeys.AGENT_VER]: '',
    [AppConfigParamsKeys.CALL_STATUS_INTERVAL]: '',
    [AppConfigParamsKeys.SESSION_TIMEOUT_INTERVAL]: '',
    [AppConfigParamsKeys.CHECK_SESSION_TIMEOUT_INTERVAL]: '',
    [AppConfigParamsKeys.ALERT_SESSION_TIMEOUT_INTERVAL]: '',
    [AppConfigParamsKeys.FIRST_CALL_STATUS_TIMEOUT]: '',
    [AppConfigParamsKeys.CREATE_CALL_TIMEOUT]: '',
    [AppConfigParamsKeys.GET_ACTIVE_CALLS_TIMEOUT]: '',
    [AppConfigParamsKeys.MOCK_CAPTCHA]: '',
    [AppConfigParamsKeys.TELEMATICS]: '',
    [AppConfigParamsKeys.COUNTRY]: '',
    [AppConfigParamsKeys.MAKE]: '',
    [AppConfigParamsKeys.MODEL]: '',
    [AppConfigParamsKeys.YEAR]: '',
    [AppConfigParamsKeys.FIRST_TIME_ACCESS]: '',
    [AppConfigParamsKeys.CHANNEL]: '',
    [AppConfigParamsKeys.DISTANCE_UNIT]: '',
    [UIConfigParamsKeys.MENU]: '',
    [UIConfigParamsKeys.AB_TEST]: '',
    [AuthConfigParamsKeys.NAME_VALIDATE]: '',
    [AuthConfigParamsKeys.MEM_ID]: '',
    [AuthConfigParamsKeys.ENCRYPTED_MEMBER_NUMBER]: '',
    [AuthConfigParamsKeys.ETS]: '',
    [AuthConfigParamsKeys.TOKEN]: '',
    [AuthConfigParamsKeys.CLUB]: '',
  }

  // Grab all query params and map it into a single object
  checkQueryParams$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RouterNavigatedAction>(ROUTER_NAVIGATION),
      take(1),
      map((action) => getRouterActionQueryParams(action)),
      map(getURLQueryParams(this.defaultQueryParams)),
      switchMap((params) =>
        of(params).pipe(
          concatMap(() => {
            const stream = []
            stream.push(
              ...this.AppConfigParamsActions(params),
              ...this.UiConfigParamsActions(params).reduce(
                (previousValue, currentValue) => {
                  if (Array.isArray(currentValue)) {
                    currentValue = currentValue.reduce(
                      (previousValue1, currentValue1) =>
                        previousValue1.concat(currentValue1),
                      []
                    )
                  }

                  return previousValue.concat(currentValue)
                },
                []
              ),
              ...this.AuthConfigParamsActions(params)
            )

            return stream
          }),
          catchError((error) => from(this.errorReportingService.notifyError(error))
          )
        )
      )
    )
  )

  setAppId$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SET_APP_ID),
        map((action: PayloadedAction) => {
          const appId = action.payload
          setCookie('AAA_AppId', appId.toUpperCase())
        })
      ),
    { dispatch: false }
  )

  /**
   * Map each key and handle all params related to auth configs
   *
   * @param params Query params object
   */
  AuthConfigParamsActions(
    params: Params
  ): Array<PayloadedAction | Action | void> {
    return Object.keys(params)
      .filter((paramKey) => {
        switch (paramKey) {
          case AuthConfigParamsKeys.NAME_VALIDATE:
            const nameValidateValue =
              params[AuthConfigParamsKeys.NAME_VALIDATE].toLowerCase()
            return this.defaultFalsyValues.indexOf(nameValidateValue) !== -1

          case AuthConfigParamsKeys.MEM_ID:
            return (
              Boolean(params[AuthConfigParamsKeys.MEM_ID]) &&
              Boolean(params[AuthConfigParamsKeys.ETS])
            )

          case AuthConfigParamsKeys.TOKEN:
            return (
              Boolean(params[AuthConfigParamsKeys.TOKEN]) &&
              Boolean(params[AuthConfigParamsKeys.CLUB])
            )

          case AuthConfigParamsKeys.ENCRYPTED_MEMBER_NUMBER:
            return (
              Boolean(params[AuthConfigParamsKeys.ENCRYPTED_MEMBER_NUMBER]) &&
              Boolean(params[AppConfigParamsKeys.APP_ID])
            )
        }
      })
      .map((paramKey) => this.executeActions(paramKey, params))
  }

  /**
   * Map each key and handle all params related to ui configs
   *
   * @param params Query params object
   */
  UiConfigParamsActions(
    params: Params
  ): Array<PayloadedAction | Action | void> {
    return Object.keys(params)
      .filter((paramKey) => {
        switch (paramKey) {
          case UIConfigParamsKeys.MENU:
            const menuValue = params[UIConfigParamsKeys.MENU].toLowerCase()
            return this.defaultFalsyValues.indexOf(menuValue) !== -1

          case UIConfigParamsKeys.AB_TEST:
            const abTestValues = params[UIConfigParamsKeys.AB_TEST]
              .toLowerCase()
              .split(':')
            return Boolean(
              abTestValues.find(
                (value: ABTestParams) => Object.values(ABTestParams).indexOf(value) >= 0
              )
            )
        }
      })
      .map((paramKey) => this.executeActions(paramKey, params))
  }

  /**
   * Map each key and handle all params related to custom app configs
   *
   * @param params Query params object
   */
  AppConfigParamsActions(
    params: Params
  ): Array<PayloadedAction | Action | void> {
    return Object.keys(params)
      .filter((paramKey) => {
        switch (paramKey) {
          case AppConfigParamsKeys.APP_ID:
            const appId = params[AppConfigParamsKeys.APP_ID]

            return Boolean(
              appId && (
                Object.values(AppId).includes(appId.toUpperCase())
                || Object.values(AppWhiteLabelId).includes(appId.toUpperCase())
              )
            )

          case AppConfigParamsKeys.AGENT_ID:
            const agentid = params[AppConfigParamsKeys.AGENT_ID]
            return Boolean(agentid)

          case AppConfigParamsKeys.AGENT_FIRST_NAME:
            return Boolean(params[AppConfigParamsKeys.AGENT_FIRST_NAME])

          case AppConfigParamsKeys.AGENT_LAST_NAME:
            return Boolean(params[AppConfigParamsKeys.AGENT_LAST_NAME])

          case AppConfigParamsKeys.AGENT_VER:
            const agentver = params[AppConfigParamsKeys.AGENT_VER]
            return Boolean(agentver)

          case AppConfigParamsKeys.DEFAULT_LATITUDE:
            const lat = params[AppConfigParamsKeys.DEFAULT_LATITUDE]
            const lng = params[AppConfigParamsKeys.DEFAULT_LONGITUDE]

            return Boolean(lat) && Boolean(lng)

          case AppConfigParamsKeys.PREPROD:
            const preProdValue =
              params[AppConfigParamsKeys.PREPROD].toLowerCase()
            return this.defaultTruthyValues.indexOf(preProdValue) !== -1

          case AppConfigParamsKeys.ZIP_CODE:
            const zipCode = params[AppConfigParamsKeys.ZIP_CODE].toLowerCase()
            return Boolean(zipCode)

          case AppConfigParamsKeys.CALL_STATUS_INTERVAL:
            const callStatusValue =
              params[AppConfigParamsKeys.CALL_STATUS_INTERVAL]
            return Boolean(callStatusValue) && callStatusValue > 0

          case AppConfigParamsKeys.SESSION_TIMEOUT_INTERVAL:
            const sti = params[AppConfigParamsKeys.SESSION_TIMEOUT_INTERVAL]
            return Boolean(sti) && sti > 0

          case AppConfigParamsKeys.CHECK_SESSION_TIMEOUT_INTERVAL:
            const csti = params[AppConfigParamsKeys.CHECK_SESSION_TIMEOUT_INTERVAL]
            return Boolean(csti) && csti > 0

          case AppConfigParamsKeys.ALERT_SESSION_TIMEOUT_INTERVAL:
            const asti = params[AppConfigParamsKeys.ALERT_SESSION_TIMEOUT_INTERVAL]
            return Boolean(asti) && asti > 0

          case AppConfigParamsKeys.FIRST_CALL_STATUS_TIMEOUT:
            const fsct = params[AppConfigParamsKeys.FIRST_CALL_STATUS_TIMEOUT]
            return Boolean(fsct) && fsct > 0

          case AppConfigParamsKeys.CREATE_CALL_TIMEOUT:
            const cct = params[AppConfigParamsKeys.CREATE_CALL_TIMEOUT]
            return Boolean(cct) && cct > 0

          case AppConfigParamsKeys.GET_ACTIVE_CALLS_TIMEOUT:
            const gact = params[AppConfigParamsKeys.GET_ACTIVE_CALLS_TIMEOUT]
            return Boolean(gact) && gact > 0

          case AppConfigParamsKeys.MOCK_CAPTCHA:
            const mockCaptchaValue =
              params[AppConfigParamsKeys.MOCK_CAPTCHA]
            return this.defaultTruthyValues.indexOf(mockCaptchaValue) !== -1

          case AppConfigParamsKeys.TELEMATICS:
            const telematicsValue =
              params[AppConfigParamsKeys.TELEMATICS]
            return Boolean(telematicsValue)

          case AppConfigParamsKeys.COUNTRY:
            return Boolean(params[AppConfigParamsKeys.COUNTRY])

          case AppConfigParamsKeys.MAKE:
            const makeValue = params[AppConfigParamsKeys.MAKE]
            return Boolean(makeValue)

          case AppConfigParamsKeys.FIRST_TIME_ACCESS:
            const ftValue = params[AppConfigParamsKeys.FIRST_TIME_ACCESS]
            return this.defaultTruthyValues.indexOf(ftValue) !== -1

          case AppConfigParamsKeys.CHANNEL:
            return Boolean(params[AppConfigParamsKeys.CHANNEL])

          case AppConfigParamsKeys.DISTANCE_UNIT:
            const distanceUnit = params[AppConfigParamsKeys.DISTANCE_UNIT]
            return Boolean(
            distanceUnit && (
              Object.values(DISTANCE_UNIT).includes(distanceUnit.toUpperCase())
            )
          )
        }
      })
      .map((paramKey) => this.executeActions(paramKey, params))
  }

  /**
   * Return each action depending on passed query param key
   *
   * @param paramKey query param key
   * @param params query params object
   */
  executeActions(
    paramKey: string,
    params: object
  ): PayloadedAction | Action | void {
    switch (paramKey) {
      case AuthConfigParamsKeys.NAME_VALIDATE:
        return setAuthMethod({ payload: AuthMethods.MEMBERSHIP_NUMBER })

      case AuthConfigParamsKeys.MEM_ID:
        const memId = decodeURI(
          encodeURI(params[AuthConfigParamsKeys.MEM_ID])
        ).replace(/\s/g, '+')
        const ets = decodeURI(
          encodeURI(params[AuthConfigParamsKeys.ETS])
        ).replace(/\s/g, '+')

        return assignSecureParams({ payload: { memId, ets } })

      case AuthConfigParamsKeys.ENCRYPTED_MEMBER_NUMBER:
        const memberNumber = decodeURI(
          encodeURI(params[AuthConfigParamsKeys.ENCRYPTED_MEMBER_NUMBER])
        ).replace(/\s/g, '+')
        const appIdParam = params[AuthConfigParamsKeys.ENCRYPTED_MEMBER_NUMBER]
        if (appIdParam.toUpperCase() === AppId.WALLET) {
          setCookie('AAA_AppId', AppId.WALLET)
        }
        return assignEncryptedMemberNumberParams({ payload: { memberNumber } })

      case AuthConfigParamsKeys.TOKEN:
        const token = params[AuthConfigParamsKeys.TOKEN]
        const club = params[AuthConfigParamsKeys.CLUB]

        return setAuth({ payload: { access_token: token, club } })

      case UIConfigParamsKeys.MENU:
        return setShowMenu({ payload: false })

      case UIConfigParamsKeys.AB_TEST:
        const abTests = params[UIConfigParamsKeys.AB_TEST]
          .toLowerCase()
          .split(':')

        return abTests.map((abTestParam: ABTestParams) =>
          setABTestParam({ payload: [abTestParam] })
        )

      case AppConfigParamsKeys.APP_ID:
        const appId = params[AppConfigParamsKeys.APP_ID].toUpperCase()
        return setAppId({ payload: appId.toUpperCase() })

      case AppConfigParamsKeys.PREPROD:
        return setPreProdEnv()

      case AppConfigParamsKeys.DEFAULT_LATITUDE:
        const lat = Number(params[AppConfigParamsKeys.DEFAULT_LATITUDE])
        const lng = Number(params[AppConfigParamsKeys.DEFAULT_LONGITUDE])
        const accuracy = Number(params[AppConfigParamsKeys.DEFAULT_ACCURACY])

        const payload = { lat, lng }

        if (accuracy > 0 ) {
          payload[AppConfigParamsKeys.DEFAULT_ACCURACY] = accuracy
        }

        return setUserDefaultCoords({ payload })

      case AppConfigParamsKeys.ZIP_CODE:
        return setUserDefaultZipCode({
          payload: params[AppConfigParamsKeys.ZIP_CODE],
        })

      case AppConfigParamsKeys.AGENT_ID:
        const agentName = params[paramKey]
        return setAgentSettings({ payload: { agentName } })

      case AppConfigParamsKeys.AGENT_FIRST_NAME:
        return setAgentFirstName({ payload: params[paramKey] })

      case AppConfigParamsKeys.AGENT_LAST_NAME:
        return setAgentLastName({ payload: params[paramKey] })

      case AppConfigParamsKeys.AGENT_VER:
        const isAgent = params[paramKey].toUpperCase() === 'V2'
        return setAgent({
          payload: isAgent,
        })

      case AppConfigParamsKeys.CALL_STATUS_INTERVAL:
        return setCallStatusInterval({
          payload: Number(params[AppConfigParamsKeys.CALL_STATUS_INTERVAL]),
        })

      case AppConfigParamsKeys.SESSION_TIMEOUT_INTERVAL:
        return setSessionTimeoutInterval({
          payload: Number(params[AppConfigParamsKeys.SESSION_TIMEOUT_INTERVAL]),
        })

      case AppConfigParamsKeys.CHECK_SESSION_TIMEOUT_INTERVAL:
        return setCheckSessionTimeoutInterval({
          payload: Number(params[AppConfigParamsKeys.CHECK_SESSION_TIMEOUT_INTERVAL]),
        })

      case AppConfigParamsKeys.ALERT_SESSION_TIMEOUT_INTERVAL:
        return setAlertSessionTimeoutInterval({
          payload: Number(params[AppConfigParamsKeys.ALERT_SESSION_TIMEOUT_INTERVAL]),
        })

      case AppConfigParamsKeys.FIRST_CALL_STATUS_TIMEOUT:
        return setFirstCallStatusTimeout({
          payload: Number(params[AppConfigParamsKeys.FIRST_CALL_STATUS_TIMEOUT]),
        })

      case AppConfigParamsKeys.CREATE_CALL_TIMEOUT:
        return setCreateCallTimeout({
          payload: Number(params[AppConfigParamsKeys.CREATE_CALL_TIMEOUT]),
        })

      case AppConfigParamsKeys.GET_ACTIVE_CALLS_TIMEOUT:
        return setGetActiveCallsTimeout({
          payload: Number(params[AppConfigParamsKeys.GET_ACTIVE_CALLS_TIMEOUT]),
        })

      case AppConfigParamsKeys.MOCK_CAPTCHA:
        return setMockCaptcha({ payload: true })

      case AppConfigParamsKeys.TELEMATICS:
        return setTelematics({
          payload: params[AppConfigParamsKeys.TELEMATICS],
        })

      case AppConfigParamsKeys.COUNTRY:
        return setCountry({
          payload: params[AppConfigParamsKeys.COUNTRY],
        })

      case AppConfigParamsKeys.MAKE:
        const make = params[AppConfigParamsKeys.MAKE]
        const model = params[AppConfigParamsKeys.MODEL]
        const year = params[AppConfigParamsKeys.YEAR]

        // Required fields
        if (make && model && year) {
          const vehicle = {
            make,
            model,
            year,
          } as Vehicle
          return setQueryParamsVehicleData({
            payload: vehicle,
          })
        }
        break;

      case AppConfigParamsKeys.FIRST_TIME_ACCESS:
        return setFirstTimeAccess({ payload: true })

      case AppConfigParamsKeys.CHANNEL:
        return setChannel({
          payload: params[AppConfigParamsKeys.CHANNEL],
        })

      case AppConfigParamsKeys.DISTANCE_UNIT:
        return setDistanceUnit({ payload: params[AppConfigParamsKeys.DISTANCE_UNIT].toUpperCase() })
    }
  }

  constructor(
    private actions$: Actions,
    private errorReportingService: ErrorReportingService,
  ) { }
}
