import * as fromRouter from '@ngrx/router-store'
import { routerReducer } from '@ngrx/router-store'
import { AdvisoriesState, reducer as advisories } from '../modules/advisories/advisories.reducer'
import { AuthState, reducer as auth } from '../modules/auth/auth.reducer'
import {
  AAACallsStatusesState,
  reducer as callsStatuses
} from '../modules/dashboard/calls-statuses/call-status.reducer'
import { AAACallState, reducer as call } from '../modules/dashboard/calls.reducer'
import { ClubState, reducer as clubs } from '../modules/dashboard/clubs/clubs.reducer'
import { PaceSetterState, reducer as paceSetter } from '../modules/issue/issue.reducer'
import { AARState, reducer as aar } from '../modules/location/aar/aar.reducer'
import { reducer as review, ReviewState } from '../modules/location/aar/review/review.reducer'
import { LocationState, reducer as location } from '../modules/location/location.reducer'
import { reducer as towLocation, TowLocationState } from '../modules/location/tow-location/tow-location.reducer'
import { MemberState, reducer as member } from '../modules/member/member.reducer'
import { AAAQuotesServicesState, reducer as quotes } from '../modules/quotes/quotes.reducer'
import { reducer as routesDistance, RouteDistancesState } from '../modules/route-distance/route-distance.reducer'
import { reducer as servicingClub, ServicingClubState } from '../modules/servicing-club/servicing-club.reducer'
import { reducer as submit, SubmitState } from '../modules/submit/submit.reducer'
import { reducer as tagging, TaggingState } from '../modules/tagging/tagging.reducer'
import { FormsState, reducer as forms } from '../modules/ui/forms/forms.reducer'
import { reducer as loading } from '../modules/ui/loading/loading.reducer'
import { reducer as ui, UIState } from '../modules/ui/ui.reducer'
import { reducer as vehicle, VehicleState } from '../modules/vehicle/vehicle.reducer'
import { reducer as wizard, WizardState } from '../modules/wizard/wizard.reducer'
import { reducer as lastInteraction } from './last-interaction.reducer'
import { reducer as session, SessionState } from '../modules/session/session.reducer'
import { reducer as timeout, TimeoutState } from '../modules/ui/timeout/timeout.reducer'
import { RESET_STATE } from '../shared/actions/shared.actions';

export const reducers = {
  aar,
  review,
  auth,
  clubs,
  member,
  paceSetter,
  loading,
  location,
  ui,
  timeout,
  forms,
  submit,
  towLocation,
  vehicle,
  lastInteraction,
  call,
  callsStatuses,
  router: routerReducer,
  tagging,
  wizard,
  quotes,
  servicingClub,
  session,
  advisories,
  routesDistance,
}

export interface AAAStore {
  aar: AARState
  review: ReviewState
  auth: AuthState
  clubs: ClubState
  member: MemberState
  paceSetter: PaceSetterState
  loading: any
  location: LocationState
  ui: UIState
  timeout: TimeoutState
  forms: FormsState
  submit: SubmitState
  towLocation: TowLocationState
  vehicle: VehicleState
  session: SessionState
  router: fromRouter.RouterReducerState<any>
  call: AAACallState
  callsStatuses: AAACallsStatusesState
  tagging: TaggingState
  wizard: WizardState
  quotes?: AAAQuotesServicesState
  servicingClub?: ServicingClubState
  advisories?: AdvisoriesState
  routesDistance: RouteDistancesState
}

export function clearState(reducer) {
  return function(state, action) {
    if (action.type === RESET_STATE) {
      state = undefined
    }
    return reducer(state, action)
  };
}
