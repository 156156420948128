import { Component } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { EditService } from '../services/edit.service'
import { VehicleSections } from '../../modules/ui/ui.types'

@Component({
  selector: 'app-vehicle-header-back',
  templateUrl: './vehicle-header-back.component.html',
  styleUrls: ['./vehicle-header-back.component.scss'],
})
export class VehicleHeaderBackComponent {

  constructor(
    private route: ActivatedRoute,
    public editService: EditService,
  ) {}

  back() {
    const vehicleSection = this.route.snapshot.queryParams['section']
    if (vehicleSection === VehicleSections.CONFIRM) {
      this.editService.editVehicleSection(false, '', VehicleSections.PLATE)
    } else if (vehicleSection === VehicleSections.PLATE) {
      this.editService.editVehicleSection(false, '', '')
    }
  }
}
