import { createAction, props } from '@ngrx/store'

import { createAsyncConstants } from '../../shared/utils'
import { TowLocation } from '../location/location.types'
import {
  CallCancelParams,
  CallCancelSuccess,
  CallSubmission, ServiceDeliveryCallStatus
} from './calls.types'

export const SET_COMMENT = 'SET_COMMENT'
export const SEEN_ADVISORY = 'SEEN_ADVISORY'
export const RESET_ADVISORIES = 'RESET_ADVISORIES'
export const CALL_RESET = 'CALL_RESET'
export const SET_TOWING = 'SET_TOWING'
export const ADD_CALL = createAsyncConstants('ADD_CALL')
export const ADD_PARTIAL_CALL = createAsyncConstants('ADD_PARTIAL_CALL')
export const CALL_CANCEL = createAsyncConstants('CALL_CANCEL')
export const CALL_STATUS_ERROR = 'CALL_STATUS_ERROR'
export const RESET_CALL_STATUS_ERROR = 'RESET_CALL_STATUS_ERROR'
export const SERVICE_DELIVERY_CALL_STATUS = createAsyncConstants(
  'SERVICE_DELIVERY_CALL_STATUS'
)
export const RESET_ACTIVE_CALLS_CALLED = 'RESET_ACTIVE_CALLS_CALLED'

export const resetCall = createAction(CALL_RESET)

export const addCallRequest = createAction(ADD_CALL.REQUEST)

export const setServiceDeliveryCallStatusRequest = createAction(
  SERVICE_DELIVERY_CALL_STATUS.REQUEST
)

export const setServiceDeliveryCallStatusFailure = createAction(
  SERVICE_DELIVERY_CALL_STATUS.FAILURE
)

export const setServiceDeliveryCallStatusSuccess = createAction(
  SERVICE_DELIVERY_CALL_STATUS.SUCCESS,
  props<{ payload: ServiceDeliveryCallStatus }>()
)

export const addCallSuccess = createAction(
  ADD_CALL.SUCCESS,
  props<{ payload: CallSubmission }>()
)

export const notifyCallFailure = createAction(ADD_CALL.FAILURE)

export const addPartialCallRequest = createAction(ADD_PARTIAL_CALL.REQUEST)

export const addPartialCallSuccess = createAction(ADD_PARTIAL_CALL.SUCCESS)

export const notifyPartialCallFailure = createAction(ADD_PARTIAL_CALL.FAILURE)

export const callCancelRequest = createAction(
  CALL_CANCEL.REQUEST,
  props<{ payload: CallCancelParams }>()
)

export const callCancelSuccess = createAction(
  CALL_CANCEL.SUCCESS,
  props<{ payload: CallCancelSuccess }>()
)

export const notifyCallCancelFailure = createAction(CALL_CANCEL.FAILURE)

export const setCallTowing = createAction(
  SET_TOWING,
  props<{ payload: TowLocation }>()
)

export const setComment = createAction(
  SET_COMMENT,
  props<{ payload: string }>()
)

export const setCallStatusError = createAction(CALL_STATUS_ERROR)

export const resetCallStatusError = createAction(RESET_CALL_STATUS_ERROR)

export const resetActiveCallsCalled = createAction(RESET_ACTIVE_CALLS_CALLED)
