<h2 i18n
    i18n-aria-label
    role="heading"
    aria-level="1"
    class="large-title"
    aria-live="polite"
    aria-label="Select your vehicle's model">Select your vehicle's model</h2>

<section class="models_first" aria-live="assertive" #modelsContainer>
    <button
      *ngFor="let model of (firstModels$ | async); let index = index;"
      [attr.aria-label]="'Select vehicle model - ' +  model"
      class="models_item"
      type="button"
      triggerable-element
      (click)="modelChange({value: model, other: false})"
      [ngClass]="{
        selected: getIsSelectedModel(model),
        'first-focusable-element': index === 0
      }"
    >
        <div class="normal-text">
          {{ model | uppercase }}
        </div>
        <div class="models_arrow"></div>
    </button>
</section>

<div class="loading-holder" aria-live="polite">
  <app-select-or-text
    i18n-fieldName
    [keyupText]="handleInputChange"
    [items$]="notListedModels$"
    (selectionMade)="onOtherInput($event)"
    [maxLength]="36"
    fieldName="models"
    [dropdownPosition]="'top'"
  ></app-select-or-text>
  <ng-container *ngIf="isLoading$ | async">
    <app-loading width="25" height="25" class="typeahead_icon-begin" ></app-loading>
  </ng-container>
</div>
<section class="bounce-in" *ngIf="hasFourWheelDriveTypes && !(isLoadingVehicleModelTypes$ | async)" aria-live="assertive">
  <h2 i18n-aria-label
      class="large-title"
      aria-label="Select model details">
    <ng-container i18n>Model Details</ng-container>&nbsp;
    <span i18n class="small-title">(Required)</span>
  </h2>
  <div i18n class="drive-type-label">I have a 4WD or AWD Vehicle:</div>
  <div class="checkbox-container">
    <app-checkbox
      i18n
      name="awd-yes"
      (checkedChange)="driveTrainChange($event, true)"
      [checked]="isAWD === true"
    >
      Yes
    </app-checkbox>
    <app-checkbox
      i18n
      name="awd-no"
      (checkedChange)="driveTrainChange($event, false)"
      [checked]="isAWD === false"
    >
      No
    </app-checkbox>
  </div>
</section>
<span
  aria-label="Model error message"
  class="form-error text-error model-error"
  [ngClass]="{ hidden: isPristine || !errorMessage }"
  [attr.aria-hidden]="isPristine || !errorMessage" aria-live="polite"
>
  {{ errorMessage }}
</span>

<app-button
  i18n
  id="models-next-button"
  [inverted]="true"
  (buttonClick)="onNext()"
  classNames="base-btn-size"
  [tabIndex]="0"
  [loading]="(isLoadingVehicleModelTypes$ | async) || (isLoading$ | async)">Next</app-button>
