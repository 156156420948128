import { Component } from '@angular/core'

import { Store, select } from '@ngrx/store'
import { AAAStore } from '../../store/root-reducer'
import {
  MemberInfo,
  MemberEligibility,
} from '../../modules/member/member.types'
import { Observable } from 'rxjs'
import {
  selectMemberEligibility,
  selectMemberData,
} from '../../modules/member/member.selectors'

// ARR:POC - Displays the out of territory member details as well as eligibility i.e. tow usage
@Component({
  selector: 'app-member-eligibility',
  templateUrl: './member-eligibility.component.html',
  styleUrls: ['./member-eligibility.component.scss'],
})
export class MemberEligibilityComponent {
  memberEligibility$: Observable<MemberEligibility> = this.store$.pipe(
    select(selectMemberEligibility)
  )

  memberData$: Observable<MemberInfo> = this.store$.pipe(
    select(selectMemberData)
  )

  constructor(private store$: Store<AAAStore>) {}
}
