import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core'
import { AAAStore } from '../../../store/root-reducer'
import { select, Store } from '@ngrx/store'
import { selectVehicleYears } from '../vehicle.selectors'
import { Observable, of } from 'rxjs'
import { requestVehicleYears, VEHICLE_YEARS } from '../vehicle.actions'
import { AbstractComponent } from '../../../shared/abstract.component'
import { selectIsLoading } from '../../ui/loading/loading.selectors'
import { ZoneEventEmitter } from '../../../zone-event-emitter'


const VEHICLE_YEARS_LABEL = () => $localize`Vehicle year`

@Component({
  selector: 'app-vehicle-years',
  templateUrl: './vehicle-years.component.html',
  styleUrls: ['./vehicle-years.component.scss'],
})
export class VehicleYearsComponent extends AbstractComponent implements OnInit {
  recentYears: string[] = []
  years: string[] = []
  vehicleYearLabel = VEHICLE_YEARS_LABEL()

  @Input() tabIndexPosition = 0;

  isLoading$: Observable<any> = this.store$.pipe(
    select(selectIsLoading(VEHICLE_YEARS.ACTION))
  )
  years$: Observable<string[]> = this.store$.pipe(select(selectVehicleYears))
  notListedYears$: Observable<string[]>

  @Output() selected: EventEmitter<number>

  constructor(
    private store$: Store<AAAStore>,
    private ngZone: NgZone,
  ) {
    super()
    this.selected = new ZoneEventEmitter(ngZone)
  }

  ngOnInit() {
    this.store$.dispatch(requestVehicleYears())
    this.subscriptions.push(
      this.years$.subscribe((years) => {
        this.recentYears = []
        this.years = []
        for (let i = 0; i < years.length; i++) {
          if (i < 12) {
            this.recentYears.push(years[i])
          } else {
            this.years.push(years[i])
          }
        }
        this.notListedYears$ = of(this.years)
      })
    )
  }

  yearChange(year) {
    this.selected.emit(year)
  }

}
