import { Component, Input, Output, EventEmitter } from '@angular/core'
import { BreakdownLocationDetails } from '../location.types'
import { BREAKDOWN_LOCATION_OPTIONS } from '../breakdown-location/breakdown-location.constants'

@Component({
  selector: 'app-location-details',
  templateUrl: './breakdown-location-details.component.html',
  styleUrls: ['./breakdown-location-details.component.scss'],
})
export class BreakdownLocationDetailsComponent {
  @Input() details: BreakdownLocationDetails
  @Input() tabIndexPosition = 0

  constructor() { }

  optionTemplates = [
    {
      name: 'options',
      multiSelect: false,
      values: [
        { name: BREAKDOWN_LOCATION_OPTIONS().AT_HOME_IN_DRIVEWAY },
        { name: BREAKDOWN_LOCATION_OPTIONS().IN_PARKING_GARAGE },
        { name: BREAKDOWN_LOCATION_OPTIONS().IN_PARKING_LOT },
        { name: BREAKDOWN_LOCATION_OPTIONS().ON_SIDE_ROAD },
        { name: BREAKDOWN_LOCATION_OPTIONS().ON_INTERSTATE_HIGHWAY },
      ],
    },
  ]
  @Output() detailsChange: EventEmitter<any> = new EventEmitter<any>()

  detailSelectionChanged(entry, selection) {
    const newDetails = { ...this.details }
    newDetails[entry] = selection
    this.details = newDetails
    this.detailsChange.emit(newDetails)
  }

}
