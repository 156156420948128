import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectDisplayEta } from '../calls-statuses/call-status.selectors';
import { AAAStore } from '../../../store/root-reducer';

@Component({
  selector: 'app-eta-label',
  templateUrl: './eta-label.component.html',
  styleUrls: ['./eta-label.component.scss']
})
export class EtaLabelComponent {

  constructor(
    private store$: Store<AAAStore>
  ) {
  }

  displayEta$: Observable<Date> = this.store$.pipe(select(selectDisplayEta))

}
