import { array, object, string, boolean, number } from 'yup'
import { MembersResult, SearchMemberResult } from './member.types'

export const MemberVehiclesSchema = object({
  vehicles: array().of(
    object({
      make: string(),
      model: string(),
      year: number(),
      vin: string(),
      id: string(),
    })
  ),
})

export const MemberSchema = object({
  basicAddress: string(),
  city: string(),
  countryCode: string().nullable(),
  eligible: boolean(),
  ersAbuser: boolean(),
  expirationDate: string(),
  firstName: string().required(),
  lastName: string().required(),
  memberVehicles: array().of(MemberVehiclesSchema),
  membershipNumber: string(),
  noteText: string().nullable(),
  plusIndicator: string(),
  postalCode: string(),
  preferredAARs: string().nullable(),
  stateProvince: string(),
  statusCode: string(),
  supplementalAddress: string().nullable(),
  supplementalTelephoneNumber: string().nullable(),
  telephoneNumber: string(),
})

export const MemberBasicSchema = object({
  basicAddress: string().nullable(),
  city: string().nullable(),
  countryCode: string().nullable(),
  eligible: boolean(),
  ersAbuser: boolean(),
  expirationDate: string().nullable(),
  firstName: string().required(),
  lastName: string().required(),
  memberVehicles: array().of(MemberVehiclesSchema).nullable(),
  membershipNumber: string(),
  noteText: string().nullable(),
  plusIndicator: string(),
  postalCode: string().nullable(),
  preferredAARs: string().nullable(),
  stateProvince: string().nullable(),
  statusCode: string(),
  supplementalAddress: string().nullable(),
  supplementalTelephoneNumber: string().nullable(),
  telephoneNumber: string().nullable(),
})

const MembersResultSchema = object<MembersResult>().shape({
  resultId: string(),
  streetNumber: string(),
})

export const SearchMemberResultSchema = object<SearchMemberResult>().shape({
  searchId: string(),
  members: array().of(MembersResultSchema),
})

// start ARR:POC - Schemas for service history and tow usage
export const MemberEligibilitySchema = object({
  currentCallCount: number(),
  maxCallsAllowed: number(),
}).nullable()

export const ServiceItemSchema = object({
  code: number().nullable(),
  callId: string().nullable(),
  callDate: string().nullable(),
  callStatus: string().nullable(),
  firstName: string().nullable(),
  lastName: string().nullable(),
  legacyTrouble1: string().nullable(),
  numberOfPassengers: number().nullable(),
  pacesetterCode1: string().nullable(),
  receivedTime: string().nullable(),
  serviceProvider: string().nullable(),
  breakdownLocation: object({
    city: string().nullable(),
    state: string().nullable(),
    latitude: string().nullable(),
    longitude: string().nullable(),
    streetName: string().nullable(),
    streetNumber: string().nullable(),
  }).nullable(),
  towDestination: object({
    city: string().nullable(),
    state: string().nullable(),
    latitude: string().nullable(),
    longitude: string().nullable(),
    streetName: string().nullable(),
    locationName: string().nullable(),
    shopId: number().nullable(),
  }).nullable(),
  vehicle: object({
    make: string(),
    model: string(),
    year: string(),
    vin: string(),
    id: string(),
  }).nullable(),
})

export const ServiceListSchema = array().of(ServiceItemSchema).nullable()
// end ARR:POC
