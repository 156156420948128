import { Component, OnInit } from '@angular/core'
import { select, Store } from '@ngrx/store'

import { Observable } from 'rxjs'
import { AbstractComponent } from '../../../shared/abstract.component'
import { AAAStore } from '../../../store/root-reducer'
import {
  requestMinimumAars,
  setSortingType,
  showAllAARs,
  showOpenAARsOnly,
} from '../aar/aar.actions'

import { selectAarHasRatings, selectAAROpenOnly, selectAarSortingOrder, selectArrSortingType } from '../aar/aar.selectors'
import { AAR_FILTER_TYPES, AAR_SORTING_ORDER, AAR_SORTING_TYPES } from '../location.types'
import { AdobeEventTypes } from '../../tagging/tagging.types'
import { AdobeEventService } from '../../tagging/adobe/event-adobe.service'
import events from '../../tagging/events'

@Component({
  selector: 'app-tow-location-filters',
  templateUrl: './tow-location-filters.component.html',
  styleUrls: ['./tow-location-filters.component.scss'],
})
export class TowLocationFiltersComponent extends AbstractComponent implements OnInit {
  showOpenOnly$: Observable<boolean> = this.store$.pipe(
    select(selectAAROpenOnly)
  )

  selectSorting$: Observable<AAR_SORTING_TYPES> = this.store$.pipe(
    select(selectArrSortingType)
  )
  selectOrder
  selectOrder$: Observable<AAR_SORTING_ORDER> = this.store$.pipe(
    select(selectAarSortingOrder)
  )

  selectAarHasRatings$: Observable<boolean> = this.store$.pipe(
    select(selectAarHasRatings)
  )

  constructor(
    private store$: Store<AAAStore>,
    private adobeEventService: AdobeEventService
  ) {
    super()
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.selectOrder$.subscribe(data => this.selectOrder = data)
    )
  }

  handleFilter(type) {
    switch (type) {
      case AAR_FILTER_TYPES.OPEN:
        this.store$.dispatch(showOpenAARsOnly())
        this.store$.dispatch(requestMinimumAars())
        break
      case AAR_FILTER_TYPES.ALL:
        this.store$.dispatch(showAllAARs())
        break
    }
  }

  handleSorting(type) {
    switch (this.selectOrder) {
      case AAR_SORTING_ORDER.ASCENDING:
        this.store$.dispatch(setSortingType({ payload: {type, order: AAR_SORTING_ORDER.DESCENDING} }))
        this.adobeEventService.sendEvent({
          eventName: AdobeEventTypes.CTA,
          eventValue: `${events.towTo.DESTINATION_FACILITIES_SORTING} ${type} - Ascending`
        })
        break

      default:
        this.store$.dispatch(setSortingType({ payload: {type, order: AAR_SORTING_ORDER.ASCENDING} }))
        this.adobeEventService.sendEvent({
          eventName: AdobeEventTypes.CTA,
          eventValue: `${events.towTo.DESTINATION_FACILITIES_SORTING} ${type} - Descending`
        })
    }
  }
}
