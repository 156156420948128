import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Vehicle } from '../../member/member.types'
import { AAAStore } from '../../../store/root-reducer'
import { select, Store } from '@ngrx/store'
import { ABTestParams, PromptDialogTypes } from '../../ui/ui.types'
import { openPromptDialog } from '../../ui/ui.actions'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { selectMemberActiveVehicle } from '../../member/member.selectors'
import { formatVehicle } from '../../../shared/pipes/vehicle.pipe'
import { AbstractResponsiveComponent } from '../../../shared/abstract-responsive.component'
import { selectAllowEditVehicle } from '../../auth/auth.selectors'
import { selectIsAbTestParam } from '../../ui/ui.selectors'

const TITLE_DELETE_VEHICLE = () => $localize`Deleting Vehicle`
const CONTENT_DELETE_VEHICLE = () => $localize`Please confirm deletion of the vehicle:`

@Component({
  selector: 'app-vehicle-summary',
  templateUrl: './vehicle-summary.component.html',
  styleUrls: ['./vehicle-summary.component.scss'],
})
export class VehicleSummaryComponent extends AbstractResponsiveComponent {
  @Input() vehicle: Vehicle
  @Input() isActive = false
  @Input() tabIndexPosition = 0;
  @Input() first: Boolean = false

  @Output() selectVehicle: EventEmitter<Vehicle> = new EventEmitter<Vehicle>()

  isEditing = false
  activeVehicle$: Observable<any> = this.store$.pipe(
    select(selectMemberActiveVehicle),
    map((vehicle) => formatVehicle(vehicle))
  )

  allowEditVehicle$: Observable<boolean> = this.store$.pipe(
    select(selectAllowEditVehicle)
  )

  isLicensePlateToVin$ = this.store$.pipe(
    select(selectIsAbTestParam, ABTestParams.LICENSE_PLATE_TO_VIN)
  )

  constructor(private store$: Store<AAAStore>) {
    super()
  }

  vehicleClick() {
    this.selectVehicle.emit(this.vehicle)
  }

  toggleVehicleEditor() {
    this.isEditing = !this.isEditing
  }

  deleteVehicle() {
    const vehicle = this.vehicle
    this.store$.dispatch(
      openPromptDialog({
        payload: {
          type: PromptDialogTypes.CONFIRM_VEHICLE_DELETE,
          title: TITLE_DELETE_VEHICLE(),
          content: `
            ${CONTENT_DELETE_VEHICLE()}
            ${vehicle.year} ${vehicle.make} ${vehicle.model} (${vehicle.color})
          `,
          params: vehicle.id,
        },
      })
    )
  }

}
