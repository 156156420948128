import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { AAAStore } from '../../../store/root-reducer'
import { select, Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import {
  requestVehicleModels,
  requestVehicleModelTypes,
  setVehicleModelAndType,
  VEHICLE_MODEL_TYPES,
  VEHICLE_MODELS,
} from '../vehicle.actions'
import {
  createGetVehicleTypesByModel,
  selectEditOrAddVehicleDesc,
  selectVehicleModels,
  selectWorkingVehicle
} from '../vehicle.selectors'
import { TaggingService } from '../../tagging/tagging.service'
import { selectIsLoading } from '../../ui/loading/loading.selectors'
import events from '../../tagging/events'
import { AbstractComponent } from '../../../shared/abstract.component'
import { VehicleEditState } from '../vehicle.reducer'
import { ModelType, VehicleDriveTypes } from '../vehicle.types'
import { Selection } from '../../../shared/controls/select-or-text/select-or-text.component';
import { AdobeEventTypes } from '../../tagging/tagging.types'
import { AdobeEventService } from '../../tagging/adobe/event-adobe.service'

const ERROR_SELECT_A_MODEL = () => $localize`Please, select a model.`
const ERROR_INFORM_MODEL_DETAILS = () => $localize`Please, inform the model details.`

@Component({
  selector: 'app-models',
  templateUrl: './models.component.html',
  styleUrls: ['./models.component.scss'],
})
export class ModelsComponent extends AbstractComponent implements OnInit {

  @ViewChild('modelsContainer', { static: false }) modelsContainer: ElementRef
  isLoading$: Observable<any> = this.store$.pipe(
    select(selectIsLoading(VEHICLE_MODELS.ACTION))
  )

  // TODO Consider using a ngrx-form for the entire vehicle creation
  isAWD: Boolean = null
  isPristine = true
  selectedModel: Selection = {
    value: null,
    other: false,
  }
  workingVehicle: VehicleEditState
  workingVehicle$: Observable<VehicleEditState> = this.store$.pipe(select(selectWorkingVehicle))

  getVehicleTypesByModel: (model: string) => ModelType[]
  hasFourWheelDriveTypes = false
  selectedModelDriveType: VehicleDriveTypes

  models$: Observable<string[]> = this.store$.pipe(select(selectVehicleModels))

  createGetVehicleTypesByModel$ = this.store$.pipe(
    select(createGetVehicleTypesByModel)
  )

  notListedModels$: Observable<string[]>
  firstModels$: Observable<string[]>

  isLoadingVehicleModelTypes$: Observable<boolean> =
    this.store$.pipe(select(selectIsLoading(VEHICLE_MODEL_TYPES.ACTION)))

  editOrAddVehicleDesc$ = this.store$.pipe(select(selectEditOrAddVehicleDesc))
  editOrAddVehicleDesc: string

  errorMessage: string = null

  constructor(
    private store$: Store<AAAStore>,
    private taggingService: TaggingService,
    private adobeEventService: AdobeEventService
  ) {
    super()
  }

  ngOnInit() {
    this.store$.dispatch(requestVehicleModels({ payload: null }))

    this.subscriptions.push(
      this.models$.subscribe((models) => {
        this.firstModels$ = of(models.slice(0, 6))
        this.notListedModels$ = of(models.slice(6))

        setTimeout(() => {
          this.focusFirstElement()
        }, 0);
      }),
      // TODO Consider using a ngrx-form for the entire vehicle creation
      this.createGetVehicleTypesByModel$.subscribe((getVehicleTypesByModel) => {
        this.getVehicleTypesByModel = getVehicleTypesByModel.memoized
        this.updateHasFourWheelDriveTypes(this.selectedModel.value)
      }),
      // TODO Consider using a ngrx-form for the entire vehicle creation
      this.workingVehicle$.subscribe((vehicle) => {
        this.workingVehicle = vehicle
        this.selectedModel.value = vehicle.model
        this.updateHasFourWheelDriveTypes(vehicle.model)
        this.isAWD = vehicle.driveType !== undefined ? (
          vehicle.driveType === VehicleDriveTypes.ALL_WHEEL_DRIVE ||
          vehicle.driveType === VehicleDriveTypes.FOUR_WHEEL_DRIVE
        ) : null
      }),
      this.editOrAddVehicleDesc$.subscribe(
        editOrAdd => this.editOrAddVehicleDesc = editOrAdd
      )
    )
  }

  onOtherInput(selection: Selection) {
    this.selectedModel = selection
    if (!selection.other) {
      this.modelChange(selection)
    }
  }

  modelChange(selection: Selection) {
    this.isAWD = null
    this.selectedModel = selection
    if (!this.selectedModel.value) {
      return
    } else {
      this.errorMessage = null
    }

    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: `${this.editOrAddVehicleDesc} ${events.vehicle.VEHICLE_MODEL_SELECT}`
    })

    this.taggingService.setClickEvent(
      events.vehicle.VEHICLE_MODEL_SELECT,
      events.vehicle.VEHICLE_PAGE_TYPE
    )

    const slug = this.getWorkingVehicleSlug(this.selectedModel.value)
    const modelTypes = this.getVehicleTypesByModel(slug)

    if (!modelTypes) {
      this.store$.dispatch(requestVehicleModelTypes({
        payload: {
          year: Number(this.workingVehicle.year),
          make: this.workingVehicle.make,
          model: this.selectedModel.value
        }
      }))
    } else {
      this.hasFourWheelDriveTypes = this.getHasFourWheelDriveTypes(modelTypes)
    }
  }

  updateHasFourWheelDriveTypes(model: string) {
    if (model) {
      const slug = this.getWorkingVehicleSlug(model)
      const modelTypes = this.getVehicleTypesByModel(slug)

      this.hasFourWheelDriveTypes = this.getHasFourWheelDriveTypes(modelTypes)
    }
  }

  getIsSelectedModel(model: string) {
    return this.selectedModel.value === model
  }

  getHasFourWheelDriveTypes(modelTypes: Array<ModelType> = []): boolean {
    const driveTypes = modelTypes.filter(
      (modelType) => modelType.driveType === VehicleDriveTypes.ALL_WHEEL_DRIVE
        || modelType.driveType === VehicleDriveTypes.FOUR_WHEEL_DRIVE
    )
    if (driveTypes?.length > 0) {
      this.selectedModelDriveType = driveTypes[0].driveType
      return true
    } else {
      return false
    }
  }

  getWorkingVehicleSlug(model: string) {
    return `${this.workingVehicle.year}-${this.workingVehicle.make}-${model}`
  }

  driveTrainChange(selection: boolean, isAWD: boolean) {
    if (!selection) {
      this.isAWD = null
      return
    }

    this.isAWD = isAWD

    const yesOrNo = isAWD ? 'Yes' : 'No'
    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: `${this.editOrAddVehicleDesc} ${events.vehicle.VEHICLE_SELECT_AWD_4WD} ${yesOrNo}`
    })
  }

  onNext() {
    if (this.selectedModel.other) {
      this.modelChange(this.selectedModel)
    }

    this.isPristine = false
    this.errorMessage = null
    if (!this.selectedModel.value) {
      this.errorMessage = ERROR_SELECT_A_MODEL()
      return null
    } else if (!this.selectedModel.other && this.hasFourWheelDriveTypes && this.isAWD === null) {
      this.errorMessage = ERROR_INFORM_MODEL_DETAILS()
      return null
    }

    const payload = {
      model: this.selectedModel.value,
      ...(this.isAWD ? {driveType: this.selectedModelDriveType} : {})
    }

    if (this.selectedModel.other) {
      this.adobeEventService.sendEvent({
        eventName: AdobeEventTypes.CTA,
        eventValue: events.vehicle.VEHICLE_OTHER_MODEL_SELECT
      })
      this.taggingService.setClickEvent(
        events.vehicle.VEHICLE_OTHER_MODEL_SELECT,
        events.vehicle.VEHICLE_PAGE_TYPE
      )
    }
    this.store$.dispatch(setVehicleModelAndType({ payload }))
  }

  handleInputChange(event) {
    return event.target.value.replace(/[^a-zA-Z0-9- ]/g, '')
  }

  private focusFirstElement() {

    if(!this.modelsContainer) {
      return
    }

    const container = this.modelsContainer.nativeElement
    const childs = container.getElementsByClassName('models_item')

    if(childs && childs.length) {
      childs[0].focus();
    }
  }
}
