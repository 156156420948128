<ng-container *ngIf="(memberData$ | async ) as memberData">
  <h4 class="eligibility-title">Member Info</h4>
  <div class="eligibility">
    <div class="eligibility-row">
      <p class="eligibility-row-title small-text bold uppercase">
        Status:
        <span class="eligibility-row-text small-text">
          {{ memberData?.statusCode === "A" ? "Active" : "Not Active" }}
        </span>
      </p>
      <p class="eligibility-row-title small-text bold uppercase">
        ERS Abuse:
        <span class="eligibility-row-text small-text">
          {{ memberData?.ersAbuser ? "Y" : "N" }}
        </span>
      </p>
    </div>
    <div class="eligibility-row">
      <p class="eligibility-row-title small-text bold uppercase">
        Motorcycle:
        <span class="eligibility-row-text small-text">
          {{ memberData?.noteText?.toLowerCase().includes("motorcycle coverage") ? "Y" : "N" }}
        </span>
      </p>
      <p class="eligibility-row-title small-text bold uppercase">
        COD:
        <span class="eligibility-row-text small-text">
          {{ memberData?.codIndicator ? "Y" : "N" }}
        </span>
      </p>
    </div>
    <div class="eligibility-row">
      <p class="eligibility-row-title small-text bold uppercase">
        Long Tow:
        <span class="eligibility-row-text small-text">
          {{ memberData?.longTow ? "Y" : "" }}
        </span>
      </p>
      <p class="eligibility-row-title small-text bold uppercase">
        Call Club:
        <span class="eligibility-row-text small-text">
          {{ memberData?.callClubIndicator ? "Y" : "N" }}
        </span>
      </p>
    </div>
    <ng-container>
      <div class="eligibility-row">
        <p class="eligibility-row-title small-text bold uppercase">
          Tows Used:
          <span *ngIf="(memberEligibility$ | async) as eligibility" class="eligibility-row-text small-text">{{ eligibility.currentCallCount || "" }}</span>
        </p>
        <p class="eligibility-row-title small-text bold uppercase">
          Total Tows:
          <span *ngIf="(memberEligibility$ | async) as eligibility" class="eligibility-row-text small-text">{{ eligibility.maxCallsAllowed || "" }}</span>
        </p>
      </div>
    </ng-container>
    <div class="eligibility-row">
      <p class="eligibility-row-title small-text bold uppercase">
        Message:
        <span class="eligibility-row-text small-text">
          {{ memberData?.messages }}
        </span></p>
    </div>
  </div>
</ng-container>
