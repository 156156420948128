import { Component } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { AAAStore } from '../../store/root-reducer'
import { TaggingService } from '../tagging/tagging.service'
import events from '../tagging/events'
import { buildTitle } from '../../shared/utils/title'
import { Title } from '@angular/platform-browser'
import { RapService } from '../rap/rap.service'
import { AbstractResponsiveComponent } from '../../shared/abstract-responsive.component'
import { selectAuthErrorCount } from '../auth/auth.selectors'

@Component({
  templateUrl: './location.component.html',
  selector: 'app-location',
  styleUrls: ['./location.component.scss'],
})
export class LocationComponent extends AbstractResponsiveComponent {
  hasFailedAttemptBeforeSucceed$ = this.store$.pipe(select(selectAuthErrorCount))

  constructor(
    private store$: Store<AAAStore>,
    private taggingService: TaggingService,
    private titleService: Title,
    private rapService: RapService
  ) {
    super()
    this.titleService.setTitle(
      buildTitle('Set Breakdown Location', this.rapService.isRapUser())
    )
    this.taggingService.setPageEvent(
      events.location.LOCATION_PAGE_PROMPT,
      events.location.LOCATION_PAGE_TYPE
    )

    this.subscriptions.push(
      this.hasFailedAttemptBeforeSucceed$.subscribe((hasErrorAttempted) => {
        if (hasErrorAttempted) {
          this.taggingService.setAutomatedEvent(events.auth.AUTH_SUCCESS_AFTER_FAILED_ATTEMPT, events.location.LOCATION_PAGE_TYPE)
        }
      })
    )
  }
}
