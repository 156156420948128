export const TITLE_SIGN_IN = () => $localize`Sign In`
export const TITLE_SUBMIT = () => $localize`Review and submit`
export const TITLE_SERVICE_TRACKING = () => $localize`Service Tracking`
export const MESSAGE_WE_ARE_SORRY = () => $localize`We're Sorry!`
export const CLOSED_LABEL = () => $localize`Closed`
export const DEFAULT_DISCOUNT_DESCRIPTION = () => $localize`AAA Members receive a 10% labor discount on repairs performed at this facility. The maximum labor discount is $75.`
export const DEFAULT_DISCOUNT_RESTRICTION = () => $localize`Services excluded from this offer include: Not to be used with any other discount, special, sale, or menu priced item or service.`
export const MAX_INTEGER = 2147483647
export const CA_COUNTRY = 'CA'
export const CAA_ASSOCIATION = 'CAA'
