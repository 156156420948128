<div class="status-line">
  <app-multiple-requests-button
    [ngClass]="{'multiple-requests-button-on-top': !(isStatusUpdateSupported$ | async)}"
    [requests]="requests$ | async"
    (selected)="requestSelected($event)"
  >
  </app-multiple-requests-button>
  <div class="normal-text bold status-message"
       role="heading"
       aria-level="1"
       *ngIf="isStatusUpdateSupported$ | async"
       [ngClass]="(displayTime$ | async) && (minutesRemaining$ | async) ?
       'status-message-eta' : 'status-message-no-eta'">
    {{ statusMessage$ | async }}
  </div>
</div>
