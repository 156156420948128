import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { CredentialEntryComponent } from '../credential-entry/credential-entry.component';
import { AAAStore } from '../../../store/root-reducer';


@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent extends CredentialEntryComponent {

  constructor(protected store$: Store<AAAStore>) {
    super(store$)
  }

}
