<ng-container *ngIf="(problemTooltip$ | async)">
    <div class="problem-tooltip" [@slideInAnimation] [attr.aria-expanded]="problemTooltip$ | async">
        <span class="problem-tooltip_title">
            {{ (problemTooltip$ | async).label | uppercase }}
        </span>
        <span class="problem-tooltip_message">
            {{ (problemTooltip$ | async).description }}
        </span>
        <button class="problem-tooltip_select-issue-btn"
          appCtaClickEvent="Tooltip Issue Selected"
          [issueType]="problemType.name"
          (click)="select()"
          *ngIf="problemType.enabled !== undefined ? problemType.enabled : true">
            <ng-container i18n>Start Request</ng-container>
            <i class="arrow-icon"></i>
        </button>
    </div>
</ng-container>
