import { Component } from '@angular/core'
import { getAppId } from '../../../../shared/utils/cookies'
import { OEMBranding } from '../../auth.types'
import { Observable } from 'rxjs'
import { select, Store } from '@ngrx/store'
import { selectIsOdometerConfirmation } from '../../auth.selectors'
import { AAAStore } from '../../../../store/root-reducer'

const HEADER_ENTER_CREDENTIALS_DRR = () => $localize`Please enter your AAA details below to get started.`
const HEADER_ENTER_CREDENTIALS_RAP = () => $localize`Please enter your details below to get started.`

@Component({
  selector: 'app-credentials-desktop-header',
  templateUrl: './desktop-header.component.html',
  styleUrls: ['./desktop-header.component.scss'],
})
export class DesktopHeaderComponent {

  constructor(private store$: Store<AAAStore>) {
  }

  HEADER_ENTER_CREDENTIALS_RAP_ODOMETER = () => $localize`Please enter or confirm your vehicle's odometer below to get started.`
  isOdometerConfirmation$: Observable<boolean> = this.store$.pipe(
    select(selectIsOdometerConfirmation)
  )

  applicationText: string = (() =>
    getAppId() in OEMBranding ? HEADER_ENTER_CREDENTIALS_RAP() : HEADER_ENTER_CREDENTIALS_DRR())()
}
