import { Component, ElementRef } from '@angular/core'
import { Observable } from 'rxjs'
import { select, Store } from '@ngrx/store'
import { AAAStore } from '../../../store/root-reducer'
import {
  selectExternalServiceUrl,
  selectIsCallStatusEnRoute,
  selectIsStatusUpdateSupported,
} from '../calls-statuses/call-status.selectors'
import { DraggerTarget } from '../../../shared/types'

@Component({
  selector: 'app-progress-map',
  templateUrl: './progress-map.component.html',
  styleUrls: ['./progress-map.component.scss'],
})
export class ProgressMapComponent implements DraggerTarget {
  externalServiceUrl$: Observable<string | null> = this.store$.pipe(
    select(selectExternalServiceUrl)
  )

  isCallStatusEnRoute$: Observable<boolean> = this.store$.pipe(
    select(selectIsCallStatusEnRoute)
  )

  isStatusUpdateSupported$: Observable<boolean> = this.store$.pipe(
    select(selectIsStatusUpdateSupported)
  )

  nativeElement: HTMLElement

  constructor(public element: ElementRef, private store$: Store<AAAStore>) {
    this.nativeElement = element.nativeElement
  }

}
