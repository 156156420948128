import {
  ROUTER_NAVIGATED,
  RouterNavigatedAction,
  ROUTER_REQUEST,
  RouterRequestAction,
} from '@ngrx/router-store'
import { createEffect, Actions, ofType } from '@ngrx/effects'
import { Inject, Injectable } from '@angular/core'
import { Store, select } from '@ngrx/store'
import {
  withLatestFrom,
  mergeMap,
  filter,
  map,
  catchError,
  switchMap,
} from 'rxjs/operators'
import { createGetStepByUrl, selectNextStep } from '../ui/ui.selectors'
import { AAAStore } from '../../store/root-reducer'
import { editStep, activeStep, doneStep } from '../ui/ui.actions'
import { getRouterActionQueryParam } from '../../shared/utils/query-params'
import { selectRouterStep } from '../../store/router.selectors'
import { createSelectIsCurrentStepValid } from './wizard.selectors'
import { from, of } from 'rxjs'
import { ErrorReportingService } from '../../shared/services/error-reporting.service'
import { getParamsFromEvents } from '../ui/ui.utils'
import { NAVIGATE_NEXT_STEP } from './wizard.actions'
import { Router } from '@angular/router'
import { RouteTypes } from '../main-router.module'
import { DRR_BASE_HREF } from '../../shared/shared.config'

@Injectable()
export class WizardEffects {
  beforeStepChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RouterRequestAction>(ROUTER_REQUEST),
      filter((action) => {
        const event = action.payload.event

        if (!event) {
          return false
        }

        return !/cancel=true/i.test(event.url)
      }),
      withLatestFrom(
        this.store$.pipe(select(createGetStepByUrl)),
        this.store$.pipe(select(selectRouterStep)),
        this.store$.pipe(select(createSelectIsCurrentStepValid))
      ),
      filter(([action, getStepByUrl, currentStepUrl]) => {
        const nextStepUrl = getParamsFromEvents(action, 'step')
        return (
          currentStepUrl &&
          getStepByUrl.memoized(currentStepUrl) !== undefined &&
          currentStepUrl !== nextStepUrl
        )
      }),
      map(([_, getStepByUrl, currentStepUrl, selectIsCurrentStepValid]) => {
        const currentStep = getStepByUrl.memoized(currentStepUrl)
        const isCurrentStepValid = selectIsCurrentStepValid(currentStepUrl)
        return isCurrentStepValid
          ? doneStep({ payload: currentStep })
          : activeStep({ payload: currentStep })
      })
    )
  )

  stepChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RouterNavigatedAction>(ROUTER_NAVIGATED),
      switchMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store$.pipe(select(createGetStepByUrl))
          ),
          filter(([payloadedAction, getStepByUrl]) => {
            const nextStepUrl = getRouterActionQueryParam(
              payloadedAction,
              'step'
            )
            const nextStep = getStepByUrl.memoized(nextStepUrl)
            return nextStep !== undefined
          }),
          mergeMap(
            ([
              payloadedAction,
              getStepByUrl,
            ]) => {
              const dispatchedActions = []
              const nextStepUrl = getRouterActionQueryParam(
                payloadedAction,
                'step'
              )
              const nextStep = getStepByUrl.memoized(nextStepUrl)

              dispatchedActions.push(
                editStep({
                  payload: nextStep,
                })
              )
              window.scroll({ top: 0, left: 0, behavior: 'smooth' })

              return dispatchedActions
            }
          ),
          catchError((error) =>
            from(this.errorReportingService.notifyError(error))
          )
        )
      )
    )
  )

  navigateNextStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NAVIGATE_NEXT_STEP),
      switchMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store$.pipe(select(selectNextStep)),
          ),
          map(([_, nextStep]) => this.router.navigate([this.drrBaseHref, RouteTypes.STEPS], {
              queryParams: { step: nextStep.url },
            }))
        )
      )
    ),
    { dispatch: false }
  )

  constructor(
    private store$: Store<AAAStore>,
    private actions$: Actions,
    private errorReportingService: ErrorReportingService,
    private router: Router,
    @Inject(DRR_BASE_HREF) private drrBaseHref: string
  ) {}
}
