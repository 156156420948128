import {Component, Input} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { AAAStore } from '../../../store/root-reducer';
import { selectAdvisories } from '../../advisories/advisories.selectors';
import { Advisory } from '../../advisories/advisories.types';
import { selectIsRapUser } from '../../auth/auth.selectors';
import {
  selectIsStatusUpdateSupported,
  selectExternalServiceUrl,
  selectIsCallStatusEnRoute,
  selectMessageByCallStatus,
  selectMessageByCallStatusTimeline
} from '../calls-statuses/call-status.selectors';
import { MessageStatus } from '../calls-statuses/call-status.types';

@Component({
  selector: 'app-on-our-way',
  templateUrl: './on-our-way.component.html',
  styleUrls: ['./on-our-way.component.scss']
})
export class OnOurWayComponent {
  @Input() draggerTarget: HTMLElement
  isRapUser$: Observable<boolean> = this.store$.pipe(select(selectIsRapUser))
  advisories$: Observable<Advisory[]> = this.store$.pipe(select(selectAdvisories))
  selectMessageByCallStatus$: Observable<MessageStatus> = this.store$.pipe(select(selectMessageByCallStatus))
  selectMessageByCallStatusTimeline$: Observable<MessageStatus[]> = this.store$.pipe(select(selectMessageByCallStatusTimeline))
  isAnExternalServiceAndEnRoute$: Observable<boolean> = this.store$.pipe(
    select(selectExternalServiceUrl),
    withLatestFrom(
      this.store$.pipe(select(selectIsCallStatusEnRoute))
    ),
    map(([serviceUrl, isCallStatusEnRoute]) => Boolean(serviceUrl) && isCallStatusEnRoute)
  )
  isStatusUpdateSupported$ = this.store$.select(selectIsStatusUpdateSupported)

  constructor(
    private store$: Store<AAAStore>
  ) { }

}
