import {AAALocation, LocationParams} from '../../modules/location/location.types'
import { concatAddress } from './concatAddress'
import { GoogleLocation } from '../../modules/location/location.types'
import { GOOGLE_ADDRESS_COMPONENT_TYPES } from '../../modules/location/google-geocode/types'
import { indexCollection } from './indexCollection'
import { LOCATION_TYPE } from '../../modules/location/location.actions'

const NO_ADDRESS_COMPONENTS_ERROR = 'No location.address_components found!'

interface AddressComponentSingleType {
  short_name: string
  long_name: string
  types: Array<string>
  mainType: string
}

const getDefaultStreetNumber = (address: string): string => {
  const parts = address.split(' ')

  return !isNaN(Number(parts[0])) ? parts[0] : ''
}

const getDefaultCity = (address: string, location: GoogleLocation): string => {
  const politicalComponents: Array<AddressComponentSingleType> =
    location.address_components
      .filter(
        (component) =>
          !component.types.find((type) =>
            [
              GOOGLE_ADDRESS_COMPONENT_TYPES.STATE,
              GOOGLE_ADDRESS_COMPONENT_TYPES.COUNTRY,
            ].includes(type as GOOGLE_ADDRESS_COMPONENT_TYPES)
          )
      )
      .filter((component) =>
        component.types.includes(GOOGLE_ADDRESS_COMPONENT_TYPES.POLITICAL)
      )
      .map((component) => ({
        ...component,
        mainType: component.types.filter(
          (type) => type !== GOOGLE_ADDRESS_COMPONENT_TYPES.POLITICAL
        )[0],
      }))
      .filter((component) =>
        address.match(new RegExp(component.short_name, 'i'))
      )

  return politicalComponents[0]?.short_name || ''
}

export const googleLocationToAAALocation = (
  {
    address,
    location,
    locationType,
    coords,
    landmark
  }: LocationParams): AAALocation => {
  if (!location.address_components) {
    throw new Error(NO_ADDRESS_COMPONENTS_ERROR)
  }
  const mainComponents: Array<AddressComponentSingleType> =
    location.address_components.map((component) => ({
      ...component,
      mainType: component.types[0],
    }))

  const indexedComponents = indexCollection<
    AddressComponentSingleType,
    'mainType'
  >(mainComponents, 'mainType')

  const streetNameComponent =
    indexedComponents[GOOGLE_ADDRESS_COMPONENT_TYPES.ROUTE] ||
    indexedComponents[GOOGLE_ADDRESS_COMPONENT_TYPES.INTERSECTION] ||
    indexedComponents[GOOGLE_ADDRESS_COMPONENT_TYPES.ESTABLISHMENT] ||
    indexedComponents[GOOGLE_ADDRESS_COMPONENT_TYPES.POINT_OF_INTEREST] ||
    indexedComponents[GOOGLE_ADDRESS_COMPONENT_TYPES.NATURAL_FEATURE] ||
    indexedComponents[GOOGLE_ADDRESS_COMPONENT_TYPES.PARK] ||
    indexedComponents[GOOGLE_ADDRESS_COMPONENT_TYPES.AIRPORT] ||
    indexedComponents[GOOGLE_ADDRESS_COMPONENT_TYPES.PREMISE]

  const streetName = streetNameComponent?.short_name || ''
  const streetNumber =
    indexedComponents[GOOGLE_ADDRESS_COMPONENT_TYPES.STREET_NUMBER]
      ?.short_name ||
    getDefaultStreetNumber(address) ||
    ''

  const originalCountry =
    indexedComponents[
      GOOGLE_ADDRESS_COMPONENT_TYPES.COUNTRY
    ]?.short_name?.toUpperCase() || ''
  const originalState =
    indexedComponents[GOOGLE_ADDRESS_COMPONENT_TYPES.STATE]?.short_name || ''
  const originalCity =
    indexedComponents[GOOGLE_ADDRESS_COMPONENT_TYPES.LOCALITY]?.short_name ||
    getDefaultCity(address, location) ||
    ''

  // workaround for US territories Puerto Rico & Virgin Islands (see AAA-2224 & AAA-2231)
  const state =
    originalCountry === 'PR' || originalCountry === 'VI'
      ? originalCountry
      : originalState
  // workaround for US territory Virgin Islands (see AAA-2231)
  const city = originalCountry === 'VI' ? originalState : originalCity

  const postalCode =
    indexedComponents[GOOGLE_ADDRESS_COMPONENT_TYPES.POSTAL_CODE]?.short_name ||
    ''

  return {
    address:
      concatAddress(
        { streetName, streetNumber, city, state, postalCode },
        null,
        true
      ) || '',
    streetName,
    streetNumber,
    city,
    state,
    postalCode,
    latitude: coords
      ? coords.lat
      : location.geometry?.location.lat() || undefined,
    longitude: coords
      ? coords.lng
      : location.geometry?.location.lng() || undefined,
    accuracy: location.geometry?.location.accuracy,
    ...(locationType ? { locationType } : {}),
    ...(landmark ? { landmark } : {}),
  }
}

export const __TEST__ = {
  NO_ADDRESS_COMPONENTS_ERROR,
  getDefaultStreetNumber,
  getDefaultCity,
}
