<svg
  width="32"
  height="32"
  viewBox="0 0 32 32"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M18.7041 26.9788H8.56628C7.88566 26.9788 7.23292 26.7084 6.75165 26.2271C6.27037 25.7459 6 25.0931 6 24.4125V9.56628C6 8.88566 6.27037 8.23292 6.75165 7.75165C7.23292 7.27038 7.88566 7 8.56628 7H23.4125C24.0866 7.00279 24.7324 7.27183 25.2091 7.74852C25.6858 8.22521 25.9548 8.87094 25.9576 9.54507V20.2132C25.9593 20.5742 25.8835 20.9315 25.7353 21.2608C25.5871 21.5901 25.37 21.8838 25.0986 22.122L20.369 26.3637C19.9063 26.7627 19.3151 26.9811 18.7041 26.9788V26.9788ZM8.56628 8.03924C8.1669 8.03924 7.78389 8.19789 7.50149 8.48028C7.2191 8.76268 7.06045 9.1457 7.06045 9.54507V24.3913C7.06045 24.7907 7.2191 25.1737 7.50149 25.4561C7.78389 25.7385 8.1669 25.8971 8.56628 25.8971H18.7041C19.0721 25.8969 19.427 25.7609 19.701 25.5154L24.4411 21.2736C24.5981 21.1314 24.7236 20.9579 24.8094 20.7643C24.8952 20.5707 24.9396 20.3613 24.9396 20.1495V9.54507C24.941 9.34782 24.9033 9.15225 24.8288 8.96961C24.7543 8.78698 24.6444 8.62089 24.5054 8.48092C24.3664 8.34095 24.2011 8.22987 24.019 8.15406C23.8369 8.07826 23.6416 8.03924 23.4443 8.03924H8.56628Z"
    fill="#FA1223"
  />
  <path
    d="M18.0331 23.2008H16.9727V19.9347C16.9755 19.3234 17.2203 18.7381 17.6535 18.3068C18.0867 17.8756 18.6731 17.6335 19.2844 17.6335H22.54V18.6939H19.2844C18.9544 18.6939 18.6377 18.8243 18.4033 19.0567C18.169 19.2891 18.0359 19.6046 18.0331 19.9347V23.2008Z"
    fill="#FA1223"
  />
</svg>
