type ConcatWithReturn = (string) => string

export const concatWith =
  (concatString: string, prefix: string): ConcatWithReturn =>
  (rootString: string): string => {
    if (!concatString) {
      return rootString
    }

    return rootString ? rootString + prefix + concatString : concatString
  }
