import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { select, Store } from '@ngrx/store'
import { setSpecialAssistance } from '../../../../location/location.actions'
import { selectSpecialAssistance } from '../../../../location/location.selectors'
import events from '../../../../tagging/events'
import { TaggingService } from '../../../../tagging/tagging.service'
import { DialogData, Step } from '../../../ui.types'
import { AbstractComponent } from '../../../../../shared/abstract.component'
import { AAAStore } from '../../../../../store/root-reducer'
import { selectEditingStep } from '../../../ui.selectors'
import { AdobeEventTypes } from '../../../../tagging/tagging.types'
import { AdobeEventService } from '../../../../tagging/adobe/event-adobe.service'

const HEARING_IMPAIRED = () => $localize`I'm hearing impaired`

@Component({
  selector: 'app-location-additional-info',
  templateUrl: './special-assistance.component.html',
  styleUrls: [
    '../../shared-dialog.component.scss',
    './special-assistance.component.scss',
  ],
})
export class SpecialAssistanceDialogComponent
  extends AbstractComponent
  implements OnInit {

  driverDirections$ = this.store$.pipe(select(selectSpecialAssistance))
  hearingImpairedDetails: string
  selectCurrentStep$ = this.store$.pipe(select(selectEditingStep))
  currentStep: null | Step

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private store$: Store<AAAStore>,
    private taggingService: TaggingService,
    private adobeEventService: AdobeEventService
  ) {
    super()
  }

  ngOnInit() {
    this.subscriptions.push(
    this.driverDirections$.subscribe((payload) => {
      this.hearingImpairedDetails = payload
    }),
    this.selectCurrentStep$.subscribe((step) => {
      this.currentStep = step
    })
    )
}

  driveDirectionsChange(isChecked: boolean) {
    this.hearingImpairedDetails = isChecked ? HEARING_IMPAIRED() : ''
  }

  handleSpecialAssistanceSubmit() {
    const payload = this.hearingImpairedDetails

    if (payload) {
      this.taggingService.setClickEvent(
        events.location.SPECIAL_ASSISTANCE_REQUIRED,
        this.currentStep.pageType
        )
      this.adobeEventService.sendEvent({
        eventName: AdobeEventTypes.CTA,
        eventValue: events.location.SPECIAL_ASSISTANCE_REQUIRED
      })
    }

    this.store$.dispatch(setSpecialAssistance({ payload }))
    return this.data.close()
  }

  onClose() {
    return this.data.close()
  }
}



