<svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="icon/cell" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g transform="translate(8.000000, 2.000000)">
            <rect id="Rectangle" stroke="#D81E05" fill="#FFFFFF" x="0.5" y="0.5" width="15" height="27" rx="3"></rect>
            <rect id="Rectangle" stroke="#D81E05" fill="#FFFFFF" x="2.5" y="4.5" width="11" height="21" rx="1"></rect>
            <g id="Group" transform="translate(4.500000, 2.000000)">
                <path d="M2,0.5 L5,0.5" id="Line-2" stroke="#D81E05" stroke-linecap="round"></path>
                <circle id="Oval" fill="#D81E05" cx="0.5" cy="0.5" r="1"></circle>
            </g>
        </g>
    </g>
</svg>