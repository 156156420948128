<div class="credential-entry" *ngIf="!(isRapTokenAccess$ | async)">
  <ng-container *ngIf="!(isSecure$ | async)">
    <app-credentials-mobile-header></app-credentials-mobile-header>
    <app-credentials-desktop-header></app-credentials-desktop-header>
  </ng-container>

  <ng-template #noVendors>
    <app-minimal-credentials [displayCaptcha]="(isCaptchaRequired$ | async)">
    </app-minimal-credentials>
  </ng-template>

  <ng-container *ngIf="isWhiteLabel; else noVendors">
    <app-white-label-credentials [mode]="appId">
    </app-white-label-credentials>
  </ng-container>

</div>
