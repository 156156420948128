<div class="light-divider"></div>
<div class="filters-container">
  <div class="filters-container_holder">
    <span i18n class="filters-container_title-label"> Filter: </span>

    <div class="filters-container_options">
      <aria-link-button
        i18n
        i18n-ariaLabel
        class="show-open-only-button"
        [ngClass]="{selected: (showOpenOnly$ | async) }"
        (clickLink)="handleFilter('OPEN')"
        ariaLabel="SHOW OPEN FACILITIES"
        [tabIndex]="434">OPEN</aria-link-button>

      <aria-link-button
        i18n
        i18n-ariaLabel
        class="show-all-button"
        [ngClass]="{selected: !(showOpenOnly$ | async) }"
        (clickLink)="handleFilter('ALL')"
        ariaLabel="SHOW ALL FACILITIES"
        [tabIndex]="435">ALL</aria-link-button>
    </div>
  </div>
  <div class="filters-container_holder">
    <span i18n class="filters-container_title-label"> Sort by: </span>

    <div class="filters-container_options">
      <aria-link-button i18n-ariaLabel
        class="sort-distance-button"
        [ngClass]="{selected: (selectSorting$ | async) === 'DISTANCE'}"
        (clickLink)="handleSorting('DISTANCE')"
        ariaLabel="DISTANCE FILTER"
        [tabIndex]="436">
        <i i18n-alt [class]="'fa-solid left-icon-button fa-sort-amount-' + selectOrder"
          *ngIf="(selectSorting$ | async) === 'DISTANCE'"
          alt="Sort distance"></i>
        <ng-container i18n>DISTANCE</ng-container>
      </aria-link-button>
      <aria-link-button i18n-ariaLabel
        class="sort-rating-button"
        *ngIf="selectAarHasRatings$ | async"
        [ngClass]="{selected: (selectSorting$ | async) === 'RATING'}"
        (clickLink)="handleSorting('RATING')"
        ariaLabel="RATING FILTER"
        [tabIndex]="437">
        <i i18n-alt [class]="'fa-solid left-icon-button fa-sort-amount-' + selectOrder"
          *ngIf="(selectSorting$ | async) === 'RATING'"
          alt="Sort rating"></i>
        <ng-container i18n>RATING</ng-container>
      </aria-link-button>
    </div>
  </div>
</div>
