import { Component } from '@angular/core';
import { openPromptDialog } from '../../../modules/ui/ui.actions';
import { PromptDialogTypes } from '../../../modules/ui/ui.types';
import { AAAStore } from '../../../store/root-reducer';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-closable-header',
  templateUrl: './closable-header.component.html',
  styleUrls: ['./closable-header.component.scss']
})
export class ClosableHeaderComponent {

  constructor(
    private store$: Store<AAAStore>
  ) { }

  onClose() {
    this.store$.dispatch(openPromptDialog({
      payload: {
        type: PromptDialogTypes.CLOSE_REQUEST_DIALOG
      }
    }))
  }
}
