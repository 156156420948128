import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { VendorConfigurationMode } from '../../modules/auth/mode-configuration.types';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectModeConfiguration } from '../../modules/auth/auth.selectors';
import { AAAStore } from '../../store/root-reducer';
import { openPromptDialog } from '../../modules/ui/ui.actions';
import { PromptDialogTypes } from '../../modules/ui/ui.types';
import { IUrlService } from './index.types'

const DRR_LINK = () => $localize`https://www.aaa.com/AAA/common/drrsmsterms/terms.html`
const ARIA_LABEL = () => $localize`Terms and conditions content`

@Injectable({
  providedIn: 'root'
})
export class TermsConditionsService implements IUrlService {

  rapConfig: VendorConfigurationMode
  modeConfiguration$: Observable<VendorConfigurationMode> = this.store$.pipe(
    select(selectModeConfiguration)
  )

  constructor(
    private store$: Store<AAAStore>,
    @Inject(LOCALE_ID) private locale: string
  ) {
    this.modeConfiguration$.subscribe((configData: VendorConfigurationMode) => this.rapConfig = configData)
  }

  getUrl() {
    const lang = this.locale.split('-')[0]
    return  this.rapConfig?.termsConditions
      ? this.rapConfig.termsConditions[lang] || this.rapConfig.termsConditions
      : DRR_LINK()
  }

  open() {
    this.store$.dispatch(
      openPromptDialog({
        payload: {
          type: PromptDialogTypes.IFRAME_DIALOG,
          panelClass: 'iframe-dialog',
          params: {
            ariaLabel: ARIA_LABEL(),
            url: this.getUrl(),
          },
        },
      })
    )
  }
}
