import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AgentIdentificationComponent } from './agent-identification/agent-identification.component'
import { SignInStarterComponent } from './sign-in/sign-in-starter.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'signin',
    pathMatch: 'full',
  },
  {
    path: 'signin',
    component: SignInStarterComponent,
  },
  {
    path: 'agent-login',
    component: AgentIdentificationComponent,
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
