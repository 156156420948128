<div class="status-line">
  <app-multiple-requests-button
    [requests]="requests$ | async"
    (selected)="requestSelected($event)"
  >
  </app-multiple-requests-button>
  <div class="normal-text bold status-message"
       role="heading"
       aria-level="1"
       [ngClass]="(displayTime$ | async) && (minutesRemaining$ | async) ?
       'status-message-eta' : 'status-message-no-eta'">
    {{ statusMessage$ | async }}
  </div>
  <app-eta-label></app-eta-label>
</div>
<div class="minutes-line">
  <div class="minutes-text there-soon normal-text bold">
    <div *ngIf="displayArrival$ | async; else arrived">
      We'll be there soon!
    </div>
    <ng-template #arrived>
      <div>We've arrived!</div>
    </ng-template>
  </div>
  <div class="minutes-border">
    <div class="chevron upper"></div>
    <div class="chevron lower"></div>
  </div>
  <div class="minutes-text minutes-remaining">
    <ng-container
      *ngIf="
        (displayTime$ | async) &&
          (minutesRemaining$ | async) as minutesRemaining;
        else minutesPlaceholder
      "
    >
      <span class="huge-text">{{
        minutesRemaining < 1 ? '...' : minutesRemaining
      }}</span>
      <span *ngIf="minutesRemaining >= 1" class="small-text">
        &nbsp;MIN<span *ngIf="minutesRemaining == 1">S</span>
      </span>
    </ng-container>
    <ng-template #minutesPlaceholder>
      <span class="huge-text">&nbsp;</span>
    </ng-template>
  </div>
</div>
<ng-container *ngIf="(showEtaInfo$ | async)">
  <div class="minutes-line">
    <div class="minutes-text eta-info">
      <span>This is only an estimate and real time ETA will be provided once your technician is enroute.</span>
    </div>
  </div>
</ng-container>
