import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { DialogData } from '../../ui.types'
import { Store } from '@ngrx/store'
import { AAAStore } from '../../../../store/root-reducer'
import { TaggingService } from '../../../tagging/tagging.service'
import { Observable } from 'rxjs'
import { AbstractComponent } from '../../../../shared/abstract.component'
import { BatteryQuotePayload } from '../../../quotes/quotes.types'
import {
  batteryQuoteNotify,
  batteryQuoteRequest,
  BATTERY_QUOTES,
} from '../../../quotes/quotes.action'
import { selectIsLoading } from '../../loading/loading.selectors'
import {
  selectHasBatteryQuotes,
  selectIsQuoteNotified,
  selectQuotePayload,
} from '../../../quotes/quotes.selectors'
import { flyInOutAnimationLeft } from '../../animations'
import { generateVehicleSlug } from '../../../../shared/utils'
import { filter } from 'rxjs/operators'
import { selectHasBreakdownLocationPostalCode } from '../../../location/location.selectors';
import events from '../../../tagging/events'
import { RapService } from '../../../rap/rap.service'
import { AdobeEventTypes } from '../../../tagging/tagging.types'
import { AdobeEventService } from '../../../tagging/adobe/event-adobe.service'

@Component({
  selector: 'app-show-battery-quotes-dialog',
  templateUrl: './show-battery-quotes-dialog.component.html',
  styleUrls: ['./show-battery-quotes-dialog.component.scss'],
  animations: [flyInOutAnimationLeft],
})
export class ShowBatteryQuotesDialogComponent
  extends AbstractComponent
  implements OnInit {
  batteryQuotesPayload$: Observable<BatteryQuotePayload> =
    this.store$.select(selectQuotePayload)
  hasBatteryQuotes$: Observable<boolean> = this.store$.select(
    selectHasBatteryQuotes
  )
  isLoading$: Observable<boolean> = this.store$.select(
    selectIsLoading(BATTERY_QUOTES.ACTION)
  )
  isBatteryQuoteNotified$: Observable<boolean> = this.store$.select(
    selectIsQuoteNotified
  )
  hasBreakdownLocationPostalCode$: Observable<boolean> = this.store$.select(
    selectHasBreakdownLocationPostalCode
  )

  payload: BatteryQuotePayload

  isRapUser = this.rapService.isRapUser()

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private store$: Store<AAAStore>,
    private taggingService: TaggingService,
    private rapService: RapService,
    private adobeEventService: AdobeEventService
  ) {
    super()
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.batteryQuotesPayload$.subscribe(
        (payload) => (this.payload = payload)
      ),
      this.hasBatteryQuotes$.pipe(filter(Boolean)).subscribe(() => {
        this.data.close()
      })
    )
    this.taggingService.setPageLoadEvent({
      pageType: events.dashboard.PAGE_TYPE,
      pageName: events.dashboard.BATTERY_QUOTES_PROMPT
    })
  }

  close() {
    this.taggingService.setClickEvent(
      events.dashboard.BATTERY_QUOTES_DECLINE_CLICK,
      events.dashboard.SERVICE_TRACKING_PAGE_TYPE
    )

    const vehicleSlug = generateVehicleSlug(
      {
        year: this.payload.imCarYear,
        make: this.payload.smCarMake,
        model: this.payload.smCarModel,
      },
      this.payload.imZipCode
    )
    if (Boolean(vehicleSlug)) {
      this.store$.dispatch(
        batteryQuoteNotify({
          payload: {
            [vehicleSlug]: true,
          },
        })
      )
    }

    this.data.close()
  }

  showBatteryQuotes() {
    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: events.dashboard.BATTERY_QUOTES_REQUEST
    })

    this.taggingService.setClickEvent(
      events.dashboard.BATTERY_QUOTES_REQUEST,
      events.dashboard.SERVICE_TRACKING_PAGE_TYPE
    )
    this.store$.dispatch(batteryQuoteRequest({ payload: this.payload }))
  }
}
