import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { getBrowserImageDRR, getBrowserImageRAP } from '../../location.utils';

@Component({
  selector: 'app-location-full-map',
  templateUrl: './breakdown-location-full-map.component.html',
  styleUrls: ['./breakdown-location-full-map.component.scss'],
})
export class BreakdownLocationFullMapComponent {
  @Input() isLoading = false
  @Input() hasDeniedGpsAccess = false
  @Input() isRapUser = false

  @Output() enterYourAddressClick = new EventEmitter()
  @Output() findMyLocationClick = new EventEmitter()

  constructor(private cdr: ChangeDetectorRef) { }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  handleFindMyLocation() {
    this.findMyLocationClick.emit()
  }

  handleEnterYourAddress() {
    this.enterYourAddressClick.emit()
  }

  getBrowserImage(): ReturnType<typeof getBrowserImageDRR | typeof getBrowserImageRAP> {
    return !this.isRapUser ? getBrowserImageDRR() : getBrowserImageRAP()
  }

}
