import { Component, Input } from '@angular/core'

const HEADER_SIZE = 350
const CUSTOM_MARGIN = 10
const MIN_HEIGHT = 25

@Component({
  selector: 'app-dragger',
  templateUrl: './dragger.component.html',
  styleUrls: ['./dragger.component.scss'],
})
export class DraggerComponent {
  @Input() target: HTMLElement
  @Input() inverted = false
  @Input() collapsed = false

  active = false
  start = 0
  startHeight = 0

  touchDown($event) {
    this.active = true
    this.start = $event.touches.item(0).screenY
    this.startHeight = this.target.offsetHeight
    $event.preventDefault()
    $event.stopPropagation()
  }

  touchMove($event) {
    if (!this.active) {
      return
    }

    $event.preventDefault()
    $event.stopPropagation()

    const y = $event.touches.item(0).screenY

    const difference = y - this.start
    const height = parseFloat(this.target.style.height.replace('px', '') || '0')
    if (!this.target.style.height || height > MIN_HEIGHT || difference > 0) {
      this.target.style.height = this.startHeight + difference + 'px'
    } else if (height <= MIN_HEIGHT) {
      // prevents the dragger from going below the minimum height when dragging up quickly
      this.target.style.height = `${MIN_HEIGHT}px`
    }
  }

  touchUp($event) {
    $event.preventDefault()
    $event.stopPropagation()
    this.active = false
  }

  ngAfterViewInit() {
    if(this.collapsed) {
      this.handleDraggerCollapse()
    }
  }

  handleDraggerCollapse() {
    const browserHeight = Number(window.innerHeight)
    const newHeight = browserHeight - HEADER_SIZE + CUSTOM_MARGIN

    this.target.style.height = `${newHeight}px`
  }
}
