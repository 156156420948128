<div class="split-panel" [ngClass]="{ vertical: showSecondaryOnMobile}">
  <div class="split-panel_primary" [ngClass]="{ vertical: showSecondaryOnMobile, 'no-padding': noPadding}">
    <ng-content select="[primary]"></ng-content>
  </div>
  <div #secondaryContainer class="split-panel_secondary" [ngClass]="{ vertical: showSecondaryOnMobile}">
    <ng-content select="[secondary]"></ng-content>
    <div *ngIf="isEmpty(secondaryContainer)" id="panel-default-secondary">
      <ng-container *ngIf="showMemberData$ | async">
        <app-entitlement-info></app-entitlement-info>
      </ng-container>
      <app-summary></app-summary>
      <!-- start ARR:POC -->
      <ng-container *ngIf="isAgent$ | async">
        <app-member-eligibility></app-member-eligibility>
      </ng-container>
      <!-- Comment service history for now. Check if we will have this at some point, otherwise, clean up the code related -->
<!--      <ng-container *ngIf="isAgent$ | async">-->
<!--        <div>-->
<!--          <h5 class="service-history-button" (click)="toggleOpenClass = !toggleOpenClass">Service History</h5>-->
<!--        </div>-->
<!--      </ng-container>-->
<!--      <app-service-history-panel *ngIf="isAgent$ | async" (isDisplayed)="handleDisplayServiceHistory($event)"-->
<!--                                 [display]="toggleOpenClass"></app-service-history-panel>-->
      <!--end ARR:POC -->
    </div>
  </div>
</div>
