import { ChangeDetectorRef, Component } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Store } from '@ngrx/store'
import { TaggingService } from '../../tagging/tagging.service'
import { AAAStore } from '../../../store/root-reducer'
import { Actions } from '@ngrx/effects'
import { Title } from '@angular/platform-browser';
import { RapService } from '../../rap/rap.service';
import { CredentialsManagementService } from '../credential-management/credential-management.service'
import { AdobeMemberValidationService } from '../../tagging/adobe/member-validation-adobe.service'
import { PrivacyPolicyService } from '../../../shared/services/privacy-policy.service';
import { TermsConditionsService } from '../../../shared/services/terms-conditions.service';
import { AdobeEventService } from '../../tagging/adobe/event-adobe.service'
import { ConfigService } from '../../config/config.service'
import { MinimalCredentialsComponent } from '../minimal-credentials/minimal-credentials.component';

@Component({
  selector: 'app-member-credentials',
  templateUrl: './member-credentials.component.html',
  styleUrls: ['./member-credentials.component.scss'],
})
export class MemberCredentialsComponent
  extends MinimalCredentialsComponent {

  constructor(
    protected actions$: Actions,
    protected store$: Store<AAAStore>,
    protected taggingService: TaggingService,
    protected adobeMemberValidationService: AdobeMemberValidationService,
    public dialog: MatDialog,
    protected changeDetector: ChangeDetectorRef,
    protected titleService: Title,
    protected rapService: RapService,
    protected credentialsManager: CredentialsManagementService,
    protected privacyPolicy: PrivacyPolicyService,
    protected termsConditionsService: TermsConditionsService,
    protected adobeEventService: AdobeEventService,
    protected configService: ConfigService,
  ) {
    super(actions$, store$, taggingService, adobeMemberValidationService, dialog, changeDetector, titleService, rapService, credentialsManager, privacyPolicy, termsConditionsService, adobeEventService, configService)
  }

}
