import { AfterViewInit, Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { OEMBranding } from '../../../auth/auth.types'
import events from '../../../tagging/events'
import { TaggingService } from '../../../tagging/tagging.service'
import { getCookie } from '../../../../shared/utils/cookies'
import { DialogData } from '../../ui.types'
import {AdobeEventService} from '../../../tagging/adobe/event-adobe.service'

@Component({
  selector: 'app-invalid-membership',
  templateUrl: './invalid-membership.component.html',
  styleUrls: ['../shared-dialog.component.scss'],
})
export class InvalidMembershipComponent implements AfterViewInit {
  pageType = events.shared.CALL_AAA_PAGE_TYPE

  isRapUser = (() => {
    const appId = getCookie('AAA_AppId');
    if (appId in OEMBranding) {
      return true
    }

    return false
  })()

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private taggingService: TaggingService
  ) {}

  ngAfterViewInit(): void {
    this.taggingService.setPageEvent(
      events.shared.CALL_AAA_PROMPT,
      events.shared.CALL_AAA_PAGE_TYPE
    )
    this.taggingService.setPageLoadEvent({
      pageName: events.shared.CALL_AAA_PROMPT
    })
  }

  ngOnInit() {
  }

  onClose() {
    this.data.close()
  }

  onCallAAAClick() {
    this.onClose()
  }
}
