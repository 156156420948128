import { Inject, Injectable } from '@angular/core'
import { of } from 'rxjs'
import { filter, mergeMap } from 'rxjs/operators'

import { XHRService } from '../../shared/services/xhr.service'
import {
  ServicesConfiguration,
  ServicesConfigurationResponse,
  ServicingClubConfig,
} from './servicing-club.types'
import { RequestOptions } from '../../shared/services/xhr.utils'
import { ConfigService } from '../config/config.service'

@Injectable({
  providedIn: 'root',
})
export class ServicingClubService {
  constructor(
    private configService: ConfigService,
    private xhr: XHRService) {}

  async getServicingClubConfigs(
    club: string,
    association,
    configs: ServicingClubConfig[] = Object.values(ServicingClubConfig),
  ) {
    if (!configs?.length) {
      throw new Error('At least one ServicingClubConfig is required')
    } else if (configs.every((config) => config in ServicingClubConfig)) {
      throw new Error('ServicingClubConfig not supported')
    }
    const requestOptions: RequestOptions = {
      withCredentials: false,
      overrideBaseURL: this.configService.getConfig().aaaConfigurationServicesUrl,
      useCustomAAAheaders: false,
    }
    try {
      const response = await this.xhr.request<ServicesConfigurationResponse>(
        {
          url: configs.join('.'),
          params: {
            club,
            association,
          },
          method: 'GET',
          headers: {
            Accept: 'application/json',
          },
        },
        requestOptions
      )
      return of(response)
        .pipe(
          filter((servicesConfig: ServicesConfigurationResponse) =>
            Boolean(servicesConfig?.services?.configuration)
          ),
          mergeMap(
            (servicesConfig: ServicesConfigurationResponse) =>
              servicesConfig.services.configuration
          ),
          filter(
            (serviceConfig: ServicesConfiguration) =>
              serviceConfig.settings.club === String(+club)
          )
        )
        .toPromise()
    } catch (error) {
      throw error
    }
  }
}
