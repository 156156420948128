import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AAAStore } from '../../../store/root-reducer';
import { selectAdvisories } from '../../advisories/advisories.selectors';
import { Advisory } from '../../advisories/advisories.types';
import { selectIsRapUser } from '../../auth/auth.selectors';

@Component({
  selector: 'app-finding-service-provider',
  templateUrl: './finding-service-provider.component.html',
  styleUrls: ['./finding-service-provider.component.scss']
})
export class FindingServiceProviderComponent {

  isRapUser$: Observable<boolean> = this.store$.pipe(select(selectIsRapUser))
  advisories$: Observable<Advisory[]> = this.store$.pipe(select(selectAdvisories))
  constructor(
    private store$: Store<AAAStore>
  ) { }

}
