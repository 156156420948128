import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import {
  sixDigitsZipCodeMask,
  zipCodeMask
} from '../../../modules/auth/minimal-credentials/minimal-credentails.constants';
import { TaggingService } from '../../../modules/tagging/tagging.service';
import auth from '../../../modules/tagging/events/auth';
import events from '../../../modules/tagging/events';

@Component({
  selector: 'app-input-zip-code',
  templateUrl: './input-zip-code.component.html',
  styleUrls: ['./input-zip-code.component.scss']
})
export class InputZipCodeComponent {

  @ViewChild('inputElement') input: ElementRef<HTMLInputElement>
  @Input() focusInputHelper: ElementRef<HTMLInputElement>
  @Input() controls
  @Input() id: string
  type = 'tel'
  mask = zipCodeMask

  constructor(private taggingService: TaggingService) {
  }

  handleFocus = () => {
    setTimeout(() => {
      this.input.nativeElement.focus()
      this.focusInputHelper.nativeElement.removeEventListener('focus', this.handleFocus)
    })
  }

  switchKeyboard() {
    this.type = this.type === 'tel' ? 'text' : 'tel'
    this.mask = this.type === 'text' ? sixDigitsZipCodeMask : zipCodeMask
    this.taggingService.setClickEvent(`${auth.CHANGE_TYPE_ZIP_CODE} ${this.type}`, events.auth.PAGE_TYPE)

    if (this.focusInputHelper) {
      this.focusInputHelper.nativeElement.addEventListener('focus', this.handleFocus)
      this.focusInputHelper.nativeElement.focus()
    }
  }
}
