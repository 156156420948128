import { Injectable } from '@angular/core'
import { AdobeAnalyticsParams } from '../tagging.types'
import { awaitHoistedFunction } from '../../../shared/utils/awaitHoistedFunction'

@Injectable({
  providedIn: 'root',
})
export class AdobeAnalyticsService {
  constructor() {}

  transmitAdobeAnalyticsEvents({ eventName, eventAttributes, directAttributes }: AdobeAnalyticsParams) {
    return awaitHoistedFunction<Object, Function>({
      ref: (window as any).adobeAnalytics_logEvent,
      target: window,
      path: ['adobeAnalytics_logEvent'],
      callback: () => (window as any).adobeAnalytics_logEvent(eventName, eventAttributes, directAttributes),
      error: new Error(
        `Unable to load Adobe Logging Commons library! Got: (${typeof (window as any).adobeAnalytics_logEvent})`
      )
    })
  }
}
