<footer role="contentinfo"
  primary
  [ngClass]="{'tooltip-expanded': ((problemTooltip$ | async) && isDesktop)}">
  <div class="terms-and-conditions">
    <aria-link-button (clickLink)="openTerms($event)"
      i18n
      elementId="terms-and-conditions"
      class="privacy-policy-issue__link">
      Terms and Conditions
    </aria-link-button>
  </div>
  <div class="privacy-policy-issue"
    *ngIf="isWebApp()">
    <aria-link-button (clickLink)="openPrivacyPolicy()"
      i18n
      elementId="privacy-policy"
      class="privacy-policy-issue__link">
      Privacy Policy
    </aria-link-button>
  </div>
  <div class="app-version">
    <ng-container *ngIf="appEnv && displayEnv">
      {{ appEnv }}
    </ng-container>
    <ng-container *ngIf="appVersion && displayVersion">
      v{{ appVersion }}
    </ng-container>
  </div>
  <ng-container *ngIf="isRapUser$ | async">
    <div i18n
      class="powered-by">
      Powered by RESPONSE
    </div>
  </ng-container>
</footer>
